import cx from "classnames";
import bricks from "public/images/backgrounds/bricks.jpg";
import { Helmet } from "react-helmet";
import { SmoothImage } from "components/Smooth";
import styles from "../AboutPage/styles.css";

export function DMCAPage() {
  return (
    <>
      <Helmet>
        <title>DMCA</title>
      </Helmet>
      <div className={cx(styles.component, styles.scroll)}>
        <div className={styles.scrollInner}>
          <div className={styles.inner}>
            <div className={styles.border}>
              <h1 className={styles.heading}>DMCA</h1>
              {/* <h1>DMCA</h1> */}
              <p className={styles.body}>
                <em>AWKSHARE</em> (the “Website“) is a free, non-profit platform for users to share ANDREW W.K. related
                media. While we are an archival site, we take claims of copyright infringement seriously. We will
                respond to notices of alleged copyright infringement that comply with applicable law. If you believe any
                materials accessible on or from this Website infringe your copyright, you may request removal of those
                materials (or access to them) from the Website by submitting written notification to our copyright agent
                designated below. In accordance with the Online Copyright Infringement Liability Limitation Act of the
                Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA“), the written notice (the “DMCA Notice“) must
                include substantially the following:
              </p>
              <ul>
                <li className={styles.listItem}>Your physical or electronic signature.</li>
                <li className={styles.listItem}>
                  Identification of the copyrighted work you believe to have been infringed or, if the claim involves
                  multiple works on the Website, a representative list of such works.
                </li>
                <li className={styles.listItem}>
                  Identification of the material you believe to be infringing in a sufficiently precise manner to allow
                  us to locate that material.
                </li>
                <li className={styles.listItem}>
                  Adequate information by which we can contact you (including your name, postal address, telephone
                  number, and, if available, email address).
                </li>
                <li className={styles.listItem}>
                  A statement that you have a good faith belief that use of the copyrighted material is not authorized
                  by the copyright owner, its agent, or the law.
                </li>
                <li className={styles.listItem}>A statement that the information in the written notice is accurate.</li>
                <li className={styles.listItem}>
                  A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright
                  owner.
                </li>
              </ul>
              <p className={styles.body}>
                Our email address to receive DMCA Notices is: <a href="mailto:dmca@awkshare.com">dmca@awkshare.com</a>
              </p>
              <p className={styles.body}>
                If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice
                may not be effective.
              </p>
              <p className={styles.body}>
                Please be aware that if you knowingly materially misrepresent that material or activity on the Website
                is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees)
                under Section 512(f) of the DMCA.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.art}>
          <SmoothImage className={styles.bg} url={bricks} />
        </div>
      </div>
    </>
  );
}
