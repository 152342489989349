import cx from "classnames";
import { Loader } from "components/Loader";
import { useCommentList } from "contexts/comments";
import { EntityType } from "shared/types";
import { Comment } from "./Comment";
import { CommentForm } from "./CommentForm";
import styles from "./styles.css";
import { TrackScroll } from "./TrackScroll";

interface CommentsProps {
  entityType: EntityType;
  entityID: string;
  grow?: boolean;
  hideDates?: boolean;
  inlineTrip?: boolean;
}

export function Comments(props: CommentsProps) {
  const { entityType, entityID, grow, hideDates, inlineTrip } = props;
  const [comments, viewers] = useCommentList(entityType, entityID);

  if (comments == null) {
    return (
      <div className={styles.component}>
        <div className={styles.header}>Comments</div>
        <div className={styles.loading}>
          <Loader />
        </div>
      </div>
    );
  }

  const commentEls: JSX.Element[] = [];

  for (let i = comments.length - 1; i >= 0; i--) {
    commentEls.push(<Comment key={comments[i].id} {...comments[i]} hideDates={hideDates} />);
  }

  return (
    <div className={cx(styles.component, grow && styles.grow)}>
      <div className={styles.header}>
        Comments <span className={styles.meta}>&nbsp;({comments.length})</span>
        {viewers != null && viewers !== 0 && (
          <div className={styles.viewers}>{`${viewers} viewer${viewers > 1 ? "s" : ""}`}</div>
        )}
      </div>
      <div className={styles.container}>
        {commentEls.length > 0 && <TrackScroll className={styles.comments}>{commentEls}</TrackScroll>}
        <CommentForm
          className={styles.commentForm}
          entityType={entityType}
          entityID={entityID}
          inlineTrip={inlineTrip}
        />
      </div>
    </div>
  );
}
