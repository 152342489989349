import cx from "classnames";
import { CSSProperties } from "react";
import styles from "./styles.css";

type LoadingDotsProps = {
  color?: string;
  isAnimating?: boolean;
  padding?: number;
  size?: number;
};

export function LoadingDots(props: LoadingDotsProps) {
  const { isAnimating = true, color, padding, size } = props;
  const style: CSSProperties = {};

  if (color) {
    style.backgroundColor = color;
  }

  if (padding) {
    style.marginRight = padding;
  }

  if (size) {
    style.width = size;
    style.height = size;

    if (typeof style.marginRight === "undefined") {
      style.marginRight = size / 3;
    }
  }

  const className = cx(styles.component, {
    [styles.isAnimated]: isAnimating,
  });

  return (
    <div className={className}>
      <div className={cx(styles.dot, styles.dotOne)} style={style} />
      <div className={cx(styles.dot, styles.dotTwo)} style={style} />
      <div className={cx(styles.dot, styles.dotThree)} style={style} />
    </div>
  );
}
