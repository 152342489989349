import { useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { Files } from "components/Files";
import { LeafsMode } from "components/LeafsList";
import { IndexPageHeader } from "./IndexPageHeader";
import styles from "./styles.css";

const pathnameToLeafsMode = new Map([
  ["/", LeafsMode.Recent],
  ["/hot", LeafsMode.Hot],
  ["/most-viewed", LeafsMode.MostViewed],
  ["/oldest", LeafsMode.Oldest],
]);

const pathnameToTitle = new Map([
  ["/", null],
  ["/hot", "Hot Files"],
  ["/most-viewed", "Most Viewed Files"],
  ["/oldest", "Oldest Files"],
]);

export function IndexPage() {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const title = pathnameToTitle.get(location.pathname);
  const leafsMode = pathnameToLeafsMode.get(location.pathname) || LeafsMode.Recent;

  return (
    <>
      <Helmet>{title && <title>{title}</title>}</Helmet>
      <div className={styles.component} ref={ref}>
        <Files HeaderComponent={IndexPageHeader} leafsClassName={styles.leafs} leafsMode={leafsMode} />
      </div>
    </>
  );
}
