import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconTime = forwardRef<SVGSVGElement, IconProps>(function IconTime(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 14h-7v-8h2v6h5v2z" />
    </svg>
  );
});
