import { Helmet } from "react-helmet";
import { Files } from "components/Files";
import { FileType } from "shared/types";
import { AppLayout } from "./AppLayout";

interface CreateFilePageProps {
  label: string;
  typeFilter: FileType | null;
}

function createFileElement(props: CreateFilePageProps) {
  const { label, typeFilter } = props;

  return (
    <AppLayout>
      <Helmet>
        <title>{label}</title>
      </Helmet>
      <Files {...props} typeFilter={typeFilter} />
    </AppLayout>
  );
}

export const filesPageElement = createFileElement({ label: "All Files", typeFilter: null });
export const imagesPageElement = createFileElement({ label: "Images", typeFilter: "IMAGE" });
export const videosPageElement = createFileElement({ label: "Videos", typeFilter: "VIDEO" });
export const audioPageElement = createFileElement({ label: "Audio", typeFilter: "AUDIO" });
export const miscPageElement = createFileElement({ label: "Misc.", typeFilter: "UNKNOWN" });
