import useSize from "@react-hook/size";
import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import appStyles from "components/App/styles.css";
import { useApi } from "contexts/api";
import { LeafOCR } from "shared/types";
import { FilePreviewImageHighlights } from "./FilePreviewImageHighlights";
import { FilePreviewImageOCR } from "./FilePreviewImageOCR";
import styles from "./styles.css";

interface FilePreviewImageProps {
  fileID: string;
  isHighlighting: boolean;
  ocr: boolean;
  ocrEnabled: boolean;
  onEndHighlighting: () => void;
  onOCRReady: () => void;
  url?: string | null;
}

export function FilePreviewImage(props: FilePreviewImageProps) {
  const { fileID, isHighlighting, ocr, ocrEnabled, onEndHighlighting, onOCRReady, url } = props;
  const api = useApi();
  const ref = useRef<HTMLImageElement>(null);
  const size = useSize(ref);
  const [ocrData, setOCRData] = useState<LeafOCR | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  const onLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const onClick = useCallback(() => {
    if (isHighlighting) {
      onEndHighlighting();
      return;
    }

    toggleFullScreen();
  }, [isHighlighting, onEndHighlighting, toggleFullScreen]);

  const getOCR = api?.leaf.getOCR;

  useEffect(() => {
    if (!ocr || !getOCR) {
      return;
    }

    let active = true;

    getOCR(fileID).then((ocr) => active && setOCRData(ocr));

    return () => {
      active = false;
    };
  }, [getOCR, fileID, ocr]);

  useEffect(() => {
    if (ocrData) {
      onOCRReady();
    }
  }, [ocrData, onOCRReady]);

  const expand = isFullScreen || isHighlighting;
  const el = (
    <div
      className={cx(styles.component, expand && styles.expand, isHighlighting && styles.isHighlighting)}
      onClick={onClick}
    >
      {ocrEnabled && ocrData && isLoaded && size[0] > 0 && size[1] > 0 && (
        <FilePreviewImageOCR
          imageWidth={ref.current?.naturalWidth || 0}
          imageHeight={ref.current?.naturalHeight || 0}
          width={size[0]}
          height={size[1]}
          boxes={ocrData.boxes}
        />
      )}
      {isHighlighting && isLoaded && (
        <>
          <div className={styles.highlightBg} />
          <FilePreviewImageHighlights
            imageWidth={ref.current?.naturalWidth || 0}
            imageHeight={ref.current?.naturalHeight || 0}
            width={size[0]}
            height={size[1]}
            imageRef={ref}
            isCreating={isHighlighting}
            onEndHighlighting={onEndHighlighting}
          />
        </>
      )}
      {url && <img className={styles.image} onLoad={onLoad} src={url} ref={ref} />}
    </div>
  );

  if (expand) {
    const portalElement = document.querySelector(`.${appStyles.portals}`);

    if (!portalElement) {
      throw new Error("Portal element not found");
    }

    return createPortal(el, portalElement);
  }

  return el;
}
