import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconComments = forwardRef<SVGSVGElement, IconProps>(function IconComments(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M20 15a1 1 0 11-2 0 1 1 0 012 0m-3 0a1 1 0 11-2 0 1 1 0 012 0m-3 0a1 1 0 11-2 0 1 1 0 012 0m5.4 5c-1 .2-2 .4-3.3.4-3.5 0-7.1-2-7.1-5.4 0-3.1 3.1-5.7 7-5.7 3.8 0 7 2.6 7 5.7 0 1.6-.6 2.5-1.5 3.5l.9 2.7-3-1.3zM6 16.5l-4.3 1.9 1.2-4A6.8 6.8 0 011 9.8C1 5.5 5.3 2 10.5 2c5 0 9.1 3.2 9.5 7.2a9.3 9.3 0 00-4-.9c-4.2 0-8 2.8-8 6.7 0 .7.1 1.4.4 2-1 0-1.5-.2-2.4-.4M24 15c0-2.2-1.2-4-3-5.2C21 4.7 16 1 10.5 1S0 4.7 0 9.8c0 1.7.6 3.5 1.8 4.9L0 20.2l6.1-2.6c1 .3 2 .4 2.9.5 1.3 2 3.9 3.3 7 3.3 1.1 0 2.2-.1 3.3-.4l4.7 2-1.4-4.2c1-1 1.4-2.4 1.4-3.8" />
    </svg>
  );
});
