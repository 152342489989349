import { useMemo } from "react";
import styles from "./styles.css";

const avatars: string[] = [
  require("public/images/avatars/0.png"),
  require("public/images/avatars/1.png"),
  require("public/images/avatars/2.png"),
  require("public/images/avatars/3.png"),
  require("public/images/avatars/4.jpg"),
  require("public/images/avatars/5.png"),
  require("public/images/avatars/6.png"),
].map((module) => module.default);

function avatar(input: string) {
  let hash = 0;

  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }

  return (hash >>> 0) % avatars.length;
}

export interface CommentAvatarProps {
  input: string;
}

export function CommentAvatar(props: CommentAvatarProps) {
  const { input } = props;
  const index = useMemo(() => avatar(input), [input]);

  return <div className={styles.component} style={{ backgroundImage: `url('${avatars[index]}')` }} />;
}
