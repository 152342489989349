import { useCallback } from "react";
import { Location, NavigateProps, useLocation, useNavigate } from "react-router";

interface Popover {
  stack: Location[];
}

export function usePopoverNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { popover } = location.state || {};
  const popoverState = popover ? (popover as Popover) : null;
  const currentPopover = popover?.stack.length ? location : null;

  const getLinkProps = useCallback(
    (path: NavigateProps["to"], state?: NavigateProps["state"]) => {
      return {
        to: path,
        state: {
          ...(location.state || {}),
          ...(state || {}),
          popover: {
            ...popoverState,
            stack: [...(popoverState?.stack || []), location],
          },
        },
      };
    },
    [location, popoverState],
  );

  const getBackLinkProps = useCallback(() => {
    if (!popoverState) {
      return null;
    }

    const { stack } = popoverState;

    if (!stack || stack.length === 0) {
      return null;
    }

    const stackCopy = [...stack];
    const lastLocation = stackCopy.pop();

    return {
      to: lastLocation.pathname,
      state: {
        ...(lastLocation.state || {}),
        popover: {
          stack: stackCopy,
        },
      },
    };
  }, [popoverState]);

  const getBackgroundLocation = useCallback(() => {
    if (!popoverState) {
      return null;
    }

    const { stack } = popoverState;

    return stack[0];
  }, [popoverState]);

  const navigateBack = useCallback(() => {
    const backProps = getBackLinkProps();

    if (!backProps) {
      return;
    }

    navigate(backProps.to, backProps.state);
  }, [getBackLinkProps, navigate]);

  const exitPopover = useCallback(() => {
    if (!popoverState) {
      return;
    }

    const { stack } = popoverState;

    if (!stack || stack.length === 0) {
      return;
    }

    const [lastLocation] = stack;

    navigate(lastLocation.pathname, {
      ...lastLocation.state,
      popover: null,
    });
  }, [navigate, popoverState]);

  return {
    currentPopover,
    exitPopover,
    getBackgroundLocation,
    getBackLinkProps,
    getLinkProps,
    navigateBack,
    popoverState,
  };
}
