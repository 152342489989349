import { useMemo } from "react";
import { Collection, FileType, fileTypeNames, fileTypeNamesPlural } from "shared/types";
import { Pill } from "../Pill";

export interface CollectionStatsProps {
  childFiletypes: Collection["childFileTypes"];
  className?: string;
  fileTypeWhitelist?: FileType[] | null;
}

export function CollectionStats(props: CollectionStatsProps) {
  const { className, childFiletypes, fileTypeWhitelist } = props;
  const str = useMemo(
    () =>
      fileTypeWhitelist
        ? fileTypeWhitelist
            .map((type) => {
              const count = childFiletypes && childFiletypes[type];

              if (!count) {
                return null;
              }

              return `${count} ${count > 1 ? fileTypeNamesPlural.get(type) : fileTypeNames.get(type)}`;
            }, [])
            .filter(Boolean)
            .join(", ")
        : childFiletypes &&
          Object.entries(childFiletypes)
            .map(
              ([type, count]: [FileType, number]) =>
                `${count} ${count > 1 ? fileTypeNamesPlural.get(type) : fileTypeNames.get(type)}`,
            )
            .join(", "),
    [fileTypeWhitelist, childFiletypes],
  );

  return <Pill className={className}>{str}</Pill>;
}
