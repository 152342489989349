import { CSSProperties, PropsWithChildren, forwardRef } from "react";
import styles from "./styles.css";

export type TimelineMarkerProps = PropsWithChildren<{
  x: number | string;
}>;

export const TimelineMarker = forwardRef<HTMLDivElement, TimelineMarkerProps>(function TimelineMarker(props, ref) {
  const { children, x } = props;

  return (
    <div className={styles.marker} ref={ref} style={{ "--x": `${x}px` } as CSSProperties}>
      <div className={styles.label}>{children}</div>
    </div>
  );
});
