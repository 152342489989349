import cx from "classnames";
import {
  CSSProperties,
  ChangeEvent,
  HTMLAttributes,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import { IconCollapseLine, IconExpandLine, IconPause, IconPlay } from "components/Icons";
import { useScrubber } from "components/Player";
import { RequestStoreKey, useRequestStore } from "contexts/requestStore";
import { useEvent } from "hooks/useEvent";
import { FileType } from "shared/types";
import { formatTimeCode } from "shared/utils";
import { FilePreviewVisualizer } from "../FilePreviewVisualizer";
import styles from "./styles.css";

interface FilePreviewPlayerProps
  extends Pick<HTMLAttributes<HTMLVideoElement>, "onCanPlayThrough" | "onPlay" | "onSeeked"> {
  allowSeek?: boolean;
  className?: string;
  fileID?: string | null;
  duration?: number | null;
  mime?: string;
  onPlayDenied?: (err?: unknown) => void;
  poster?: string;
  // muted?: boolean;
  type: FileType;
  url: string | null;
}

export type FilePreviewPlayerRef = {
  play: () => void;
  pause: () => void;
  seek: (currentTime: number) => void;
};

export const FilePreviewPlayer = forwardRef<FilePreviewPlayerRef, FilePreviewPlayerProps>(function FilePreviewPlayer(
  props,
  playerRef,
) {
  const {
    allowSeek = true,
    className,
    duration,
    fileID = null,
    onPlay: onPlayProp,
    onCanPlayThrough,
    onSeeked: onSeekedProp,
    onPlayDenied,
    poster,
    type,
    mime,
    // muted,
    url,
  } = props;
  const requestStore = useRequestStore();
  // const [hasLoaded, setHasLoaded] = useState(false);
  const location = useLocation();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const currentTime = useRef(0);
  const durationRef = useRef<number | null>(duration || null);
  const timeCodeAElementRef = useRef<HTMLDivElement | null>(null);
  const timeCodeBElementRef = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const progressElementRef = useRef<HTMLInputElement | null>(null);
  const requestedSeek = useRef(false);
  const [, forceUpdate] = useState(0);
  const volumeRef = useRef<number | null>(null);
  const volumeSliderRef = useRef<HTMLInputElement>(null);
  const [scrubberProps, scrubber] = useScrubber(videoElementRef, progressElementRef, durationRef, fileID, type);

  if (!volumeRef.current) {
    volumeRef.current = requestStore?.get(RequestStoreKey.Volume, 1) || 1;
  }

  useImperativeHandle(playerRef, () => ({
    play() {
      videoElementRef.current?.play().catch((err) => {
        onPlayDenied?.(err);
      });
    },

    pause() {
      videoElementRef.current?.pause();
    },

    seek(currentTime: number) {
      if (videoElementRef.current) {
        videoElementRef.current.currentTime = currentTime;
      }
    },
  }));

  useEffect(() => {
    setIsPlaying(false);
    currentTime.current = 0;
    durationRef.current = duration ?? null;
    progressElementRef.current?.style.setProperty("--progress", "0%");
  }, [duration, url]);

  const setVolume = useEvent((volume: number) => {
    // if (volume === 0) {
    //   videoElementRef.current.muted = true;
    // } else if (videoElementRef.current.muted) {
    //   videoElementRef.current.muted = false;
    // }

    requestStore?.set(RequestStoreKey.Volume, volume);
    volumeRef.current = volume;

    if (videoElementRef.current) {
      videoElementRef.current.volume = volume;
    }

    volumeSliderRef.current?.style.setProperty("--progress", `${volume * 100}%`);
  });

  const onLoadedMetadata = useEvent(async () => {
    const { current: videoElement } = videoElementRef;

    if (!videoElement) {
      return;
    }

    if (videoElement.duration !== Infinity) {
      durationRef.current = videoElement.duration;
    } else {
      durationRef.current = duration ?? null;
    }

    const seek = window.location.search.match(/(?:\?|&)t=(\d+)/);

    if (seek != null) {
      const seekN = Number(seek[1]);

      if (seekN <= videoElement.duration) {
        videoElement.currentTime = seekN;

        try {
          await videoElement.play();
        } catch (err) {
          videoElement.muted = true;

          try {
            await videoElement.play();
          } catch {
            // ignore
          }
        }
      }
    }

    // setHasLoaded(true);
  });

  const getProgress = useEvent((): [string, string] => {
    const seconds = Math.floor(currentTime.current);
    const duration = Math.floor(durationRef.current ?? 0);
    const progress = (seconds / duration) * 100;
    return [`${seconds}`, `${progress}%`];
  });

  const onTimeUpdate = useEvent(() => {
    if (requestedSeek.current) {
      return;
    }

    const { current: videoElement } = videoElementRef;
    const { current: progressElement } = progressElementRef;

    if (!videoElement || !progressElement) {
      return;
    }

    currentTime.current = videoElement.currentTime;
    const [seconds, progress] = getProgress();

    progressElement.value = seconds;
    progressElement.style.setProperty("--progress", progress);

    if (timeCodeAElementRef.current && timeCodeBElementRef.current && durationRef.current != null) {
      timeCodeAElementRef.current.innerText = formatTimeCode(currentTime.current);
      timeCodeBElementRef.current.innerText = formatTimeCode(currentTime.current - durationRef.current);
    }
  });

  const toggleState = useEvent(() => {
    const { current: videoElement } = videoElementRef;

    if (!videoElement) {
      return;
    }

    if (videoElement.paused) {
      videoElement.play().catch(() => {
        // ignore
      });
    } else {
      videoElement.pause();
    }

    progressElementRef.current?.focus();
  });

  const onPlay = useEvent((event: SyntheticEvent<HTMLVideoElement>) => {
    onPlayProp?.(event);
    setIsPlaying(true);
  });

  const onPause = useEvent(() => {
    setIsPlaying(false);
  });

  const onChangeProgress = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    const { current: videoElement } = videoElementRef;
    const { current: progressElement } = progressElementRef;
    const { current: timeCodeAElement } = timeCodeAElementRef;

    if (!videoElement || !progressElement || !timeCodeAElement) {
      return;
    }

    const [, progress] = getProgress();

    requestedSeek.current = true;
    videoElement.currentTime = event.target.valueAsNumber;
    currentTime.current = event.target.valueAsNumber;
    timeCodeAElement.innerText = formatTimeCode(currentTime.current);
    progressElement.style.setProperty("--progress", progress);
  });

  const startSeeking = useEvent(() => {
    requestedSeek.current = true;
  });

  const onSeeked = useEvent((event: SyntheticEvent<HTMLVideoElement>) => {
    onSeekedProp?.(event);
    requestedSeek.current = false;
  });

  const onChangeVolume = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    setVolume(event.target.valueAsNumber);
  });

  const toggleExpand = useEvent(() => {
    if (document.fullscreenElement === elementRef.current) {
      document.exitFullscreen().then(() => {
        forceUpdate((n) => n + 1);
      });
    } else {
      elementRef.current?.requestFullscreen().then(() => {
        forceUpdate((n) => n + 1);
      });
    }
  });

  const onFocusPlayer = useEvent(() => {
    // Re-focus to the progress bar so left/right keys work to seek
    if (document.activeElement === elementRef.current) {
      progressElementRef.current?.focus?.();
    }
  });

  // useEffect(() => {
  //   if (muted) {
  //     videoElementRef.current.muted = true;
  //   } else if (videoElementRef.current.muted) {
  //     videoElementRef.current.muted = false;
  //   }
  // }, [muted]);

  useEffect(() => {
    const { current: videoElement } = videoElementRef;

    if (!videoElement) {
      return;
    }

    videoElement.load();

    if (!location.state?.preventPlayerAutofocus && document.activeElement === document.body) {
      elementRef.current?.focus();
    }

    const onKeyUp = (event: KeyboardEvent) => {
      if (
        document.activeElement !== elementRef.current &&
        document.activeElement !== document.body &&
        !elementRef.current?.contains(document.activeElement)
      ) {
        return;
      }

      switch (event.key) {
        case " ": {
          event.preventDefault();
          event.stopPropagation();
          toggleState();
          return;
        }
      }
    };

    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [videoElementRef, url, toggleState, location.state?.preventPlayerAutofocus]);

  useEffect(() => {
    if (volumeRef.current != null) {
      setVolume(volumeRef.current);
    }
  }, [setVolume]);

  return (
    <div
      className={cx(styles.component, !isPlaying && styles.isPaused, className)}
      ref={elementRef}
      tabIndex={0}
      onFocus={onFocusPlayer}
    >
      <video
        className={cx(styles.video, type === "AUDIO" && styles.isAudio, poster && styles.hasPoster)}
        crossOrigin={type === "AUDIO" ? "anonymous" : undefined}
        key={url}
        onCanPlayThrough={onCanPlayThrough}
        onLoadedMetadata={onLoadedMetadata}
        onPause={onPause}
        onPlay={onPlay}
        onSeeked={onSeeked}
        onTimeUpdate={onTimeUpdate}
        poster={poster}
        ref={videoElementRef}
        preload="auto"
        // muted={muted}
        playsInline={true}
      >
        {url && <source type={mime} src={url} />}
      </video>
      {!poster && type === "AUDIO" ? (
        <>
          <FilePreviewVisualizer videoElementRef={videoElementRef} />
          <div
            className={cx(styles.gradient, allowSeek && styles.noPointer)}
            onClick={allowSeek ? undefined : toggleState}
          />
        </>
      ) : (
        <div className={styles.gradient} onClick={toggleState} />
      )}
      <div className={styles.controls}>
        <div className={styles.time}>
          <div className={styles.timeCode} ref={timeCodeAElementRef}>
            {formatTimeCode(currentTime.current)}
          </div>
          <div className={styles.progressContainer}>
            {scrubber}
            <input
              className={cx(styles.range, !allowSeek && styles.disabled)}
              disabled={!allowSeek}
              max={Math.floor(durationRef.current || 0)}
              min={0}
              ref={progressElementRef}
              onMouseDown={startSeeking}
              onChange={onChangeProgress}
              type="range"
              defaultValue={Math.floor(currentTime.current)}
              {...scrubberProps}
            />
          </div>
          {/* <div
              className={styles.progressInner}
              ref={progressElementRef}
              style={{ width: `${(currentTime.current / durationRef.current) * 100}%` }}
            /> */}
          {/* </div> */}
          <div className={styles.timeCode} ref={timeCodeBElementRef}>
            {formatTimeCode(currentTime.current - (durationRef.current || 0))}
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.actionCol}>
            <div className={styles.volumeContainer}>
              <input
                className={styles.range}
                defaultValue={volumeRef.current}
                max={1}
                min={0}
                onChange={onChangeVolume}
                ref={volumeSliderRef}
                step={0.1}
                style={{ "--progress": `${volumeRef.current * 100}%` } as CSSProperties}
                type="range"
              />
            </div>
          </div>
          <div className={cx(styles.actionCol, styles.center)}>
            <button className={styles.circleButton} onClick={toggleState}>
              {isPlaying ? <IconPause /> : <IconPlay />}
            </button>
          </div>
          <div className={cx(styles.actionCol, styles.right)}>
            {elementRef.current && document.fullscreenElement === elementRef.current ? (
              <button className={styles.button} onClick={toggleExpand}>
                <IconCollapseLine />
              </button>
            ) : (
              <button className={styles.button} onClick={toggleExpand}>
                <IconExpandLine />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
