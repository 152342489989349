import cx from "classnames";
import { PropsWithChildren } from "react";
import { useAuthFlow } from "./hooks";
import styles from "./styles.css";

export type AuthTriggerProps = PropsWithChildren;

export function AuthTrigger(props: AuthTriggerProps) {
  const { children } = props;
  const {
    state: { isAuthenticating },
    startAuthFlow,
  } = useAuthFlow();

  if (isAuthenticating) {
    return <div className={cx(styles.authTrigger, styles.authWorking)}>🪐</div>;
  }

  return (
    <button className={styles.authTrigger} onClick={startAuthFlow}>
      {children}
    </button>
  );
}
