import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconCollapseLine = forwardRef<SVGSVGElement, IconProps>(function IconCollapseLine(props, ref) {
  const { size = 16, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M7,11.65v3.86c0,0.28-0.22,0.5-0.5,0.5S6,15.79,6,15.51v-3.86C6,11.42,5.95,11.2,5.87,11l-4.84,4.82C0.91,15.94,0.76,16,0.6,16c-0.15,0-0.31-0.06-0.42-0.18c-0.23-0.24-0.23-0.62,0-0.85l4.86-4.84C4.84,10.05,4.63,10,4.41,10H0.5C0.23,10,0,9.78,0,9.5S0.23,9,0.5,9h3.91C5.84,9,7,10.19,7,11.65z M15.5,6h-3.89c-0.22,0-0.43-0.05-0.62-0.13l4.83-4.84c0.23-0.23,0.23-0.61,0-0.85s-0.61-0.23-0.85,0l-4.83,4.84C10.05,4.82,10,4.6,10,4.36V0.5C10,0.22,9.78,0,9.5,0S9,0.22,9,0.5v3.87C9,5.82,10.17,7,11.61,7h3.89C15.78,7,16,6.77,16,6.5S15.78,6,15.5,6z" />
    </svg>
  );
});
