import { PropsWithChildren } from "react";
import { ButtonSet } from "components/Button";
import styles from "./styles.css";

export type BarActionsProps = PropsWithChildren;

export function BarActions(props: BarActionsProps) {
  const { children } = props;

  return (
    <div className={styles.actions}>
      <ButtonSet>{children}</ButtonSet>
    </div>
  );
}
