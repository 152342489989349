import cx from "classnames";
import { FileIcon } from "components/FileIcon";
import { THEIA_HOST } from "shared/api";
import { File } from "shared/types";
import { normalizeMime } from "shared/utils";
import { FilePreviewArchive } from "./FilePreviewArchive";
import { FilePreviewImage } from "./FilePreviewImage";
import { FilePreviewPlayer } from "./FilePreviewPlayer";
import { FilePreviewText } from "./FilePreviewText";
import styles from "./styles.css";

interface FilePreviewProps {
  file: File;
  isHighlighting: boolean;
  ocrEnabled: boolean;
  onEndHighlighting: () => void;
  onOCRReady: () => void;
}

export function FilePreview(props: FilePreviewProps) {
  const { file, isHighlighting, ocrEnabled, onEndHighlighting, onOCRReady } = props;

  switch (true) {
    case file.mime && /^image\//.test(file.mime):
      return (
        <FilePreviewImage
          fileID={file.id}
          isHighlighting={isHighlighting}
          key={file.downloadURL}
          ocr={file.hasOCR ?? false}
          ocrEnabled={ocrEnabled}
          onEndHighlighting={onEndHighlighting}
          onOCRReady={onOCRReady}
          url={file.downloadURL}
        />
      );
    case file.mime && /^video\//.test(file.mime):
      return (
        <FilePreviewPlayer
          duration={file.duration}
          fileID={file.id}
          poster={`${THEIA_HOST}/leafs/${file.id}/theia/p720/jpeg`}
          mime={normalizeMime(file.mime || "")}
          type="VIDEO"
          key={file.streamURL || file.url || file.id}
          url={file.streamURL || file.url || null}
        />
      );
    case file.mime && /^audio\/(mp3|mp4|wav|ogg|flac|mpeg|webm)$/.test(file.mime):
      return (
        <FilePreviewPlayer
          duration={file.duration}
          poster={file.posterURL}
          mime={normalizeMime(file.mime || "")}
          type="AUDIO"
          key={file.streamURL || file.url || file.id}
          url={file.streamURL || file.url || null}
        />
      );
    case file.mime === "text/plain": {
      return <FilePreviewText file={file} />;
    }
    case file.mime === "application/pdf": {
      if (
        (
          window?.navigator?.mimeTypes as unknown as {
            [key: string]: { enabledPlugin: { length: number } };
          }
        )?.["application/pdf"]?.enabledPlugin?.length > 0
      ) {
        return (
          <object className={cx(styles.component, styles.pdfPreview)} data={file.url} type="application/pdf">
            <embed src={file.url} type="application/pdf" />
          </object>
        );
      }

      return (
        <div className={cx(styles.component, styles.fileIconPreview)}>
          <FileIcon file={file} />
        </div>
      );
    }
    case file.mime === "application/zip":
    case file.mime === "application/rar":
    case file.mime === "application/x-rar":
    case file.mime === "application/tar": {
      return <FilePreviewArchive file={file} />;
    }
    default:
      return (
        <div className={cx(styles.component, styles.fileIconPreview)}>
          <FileIcon file={file} />
        </div>
      );
  }
}
