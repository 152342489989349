import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconCards = forwardRef<SVGSVGElement, IconProps>(function IconCards(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M9 13a2 2 0 012 2v7a2 2 0 01-2 2H2a2 2 0 01-2-2v-7c0-1.1.9-2 2-2h7zm13 0a2 2 0 012 2v7a2 2 0 01-2 2h-7a2 2 0 01-2-2v-7c0-1.1.9-2 2-2h7zM9 0a2 2 0 012 2v7a2 2 0 01-2 2H2a2 2 0 01-2-2V2C0 .9.9 0 2 0h7zm13 0a2 2 0 012 2v7a2 2 0 01-2 2h-7a2 2 0 01-2-2V2c0-1.1.9-2 2-2h7z" />
    </svg>
  );
});
