import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useEvent } from "hooks/useEvent";
import type { Leaf } from "shared/types";
import { useUploaderContext } from "./context";
import { useForceUpdate } from "./useForceUpdate";

export function useUploaderCreator() {
  const uploader = useUploaderContext();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const [error, setError] = useState(null);
  const isMounted = useRef(true);

  const onChangeTitle = useEvent((event: ChangeEvent<HTMLInputElement>) =>
    uploader?.setTitle(event.currentTarget.value),
  );

  const onChangeDescription = useEvent((event: ChangeEvent<HTMLTextAreaElement>) =>
    uploader?.setDescription(event.currentTarget.value),
  );

  const start = useEvent(() => {
    setError(null);

    if (!uploader) {
      return;
    }

    const op = uploader.start();

    if (!op) {
      return;
    }

    op.then((res) => {
      if (isMounted.current) {
        navigate(`/files/${(res as Leaf).id}`);
      }
    }, setError);
  });

  const op = uploader?.getOp();

  useEffect(() => {
    if (op) {
      return op.onProgress(forceUpdate);
    }
  }, [forceUpdate, op]);

  return {
    ...uploader,
    error,
    start,
    props: {
      onChangeTitle,
      onChangeDescription,
    },
    description: uploader?.getDescription() ?? "",
    files: uploader?.getFiles() ?? [],
    isUploading: uploader?.isUploading() ?? false,
    progress: uploader?.getUploadProgress() ?? null,
    quota: uploader?.getQuota() ?? null,
    quotaExceeded: uploader?.isQuotaExceeded() ?? null,
    title: uploader?.getTitle() ?? "",
  };
}
