import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconPause = forwardRef<SVGSVGElement, IconProps>(function IconPause(props, ref) {
  const { size = 22, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M5 22c-1.37 0-2-.76-2-1.86V1.85C3 .75 3.63 0 5 0h2.5c1.35 0 1.98.73 1.98 1.85v18.29c0 1.12-.63 1.86-1.98 1.86H5zm9.51 0c-1.36 0-1.99-.74-1.99-1.86V1.85c0-1.12.63-1.85 2-1.85h2.5C18.38 0 19 .74 19 1.85v18.29c0 1.1-.62 1.86-1.98 1.86h-2.5z" />
    </svg>
  );
});
