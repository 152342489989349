import { useEffect, useRef } from "react";
import { useEvent } from "hooks/useEvent";
import styles from "./styles.css";

let THREE: typeof import("three");

const threeLoader = () =>
  import("three").then((value) => {
    THREE = value;
  });

interface CubeProps {
  dir?: 1 | -1;
  size?: number;
}

export function Cube(props: CubeProps) {
  const { size = 75, dir = 1 } = props;
  const ref = useRef(null);

  const createCube = useEvent(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, 1, 0.1, 2000);
    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
    });

    camera.position.z = 2;
    renderer.setSize(size, size);
    ref.current.appendChild(renderer.domElement);

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const edges = new THREE.EdgesGeometry(geometry);
    const material = new THREE.MeshBasicMaterial({ color: 0x00 });
    const cube = new THREE.Mesh(geometry, material);
    const line = new THREE.LineSegments(
      edges,
      new THREE.LineBasicMaterial({
        color: 0xffffff,
      }),
    );

    scene.add(cube, line);

    let active = true;
    let raf: number;

    const animate = function () {
      if (!active) return;
      raf = requestAnimationFrame(animate);
      cube.rotation.x += dir === 1 ? 0.01 : -0.01;
      cube.rotation.y += dir === 1 ? 0.01 : -0.01;
      line.rotation.x += dir === 1 ? 0.01 : -0.01;
      line.rotation.y += dir === 1 ? 0.01 : -0.01;
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      active = false;
      cancelAnimationFrame(raf);
      renderer.dispose();
      ref.current.removeChild(renderer.domElement);
    };
  });

  useEffect(() => {
    if (THREE) {
      return createCube();
    }

    let active = true;
    let removeCube: ReturnType<typeof createCube>;

    threeLoader().then(() => {
      if (active) {
        removeCube = createCube();
      }
    });

    return () => {
      active = false;

      if (removeCube) {
        removeCube();
      }
    };
  }, [createCube]);

  return <div className={styles.cube} ref={ref} />;
}
