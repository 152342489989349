import useSize from "@react-hook/size";
import cx from "classnames";
import { HTMLAttributes, MouseEvent, UIEvent, memo, useCallback, useEffect, useRef, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import styles from "./styles.css";

type TrackScrollProps = HTMLAttributes<HTMLDivElement>;

export const TrackScroll = memo(function TrackScroll(props: TrackScrollProps) {
  const { className, children, ...otherProps } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [, height] = useSize(ref);
  const atBottomRef = useRef(true);
  const [atBottom, setAtBottom] = useState(true);
  const pendingRender = useRef(false);

  useIsomorphicLayoutEffect(() => {
    pendingRender.current = true;
  }, [children]);

  useIsomorphicLayoutEffect(() => {
    if (!pendingRender.current) {
      return;
    }

    pendingRender.current = false;

    if (atBottomRef.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [children]);

  useEffect(() => {
    if (atBottomRef.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [height]);

  const onScroll = useCallback((event: UIEvent<HTMLDivElement>) => {
    const _atBottom =
      event.currentTarget.scrollTop === event.currentTarget.scrollHeight - event.currentTarget.offsetHeight;

    if (atBottomRef.current !== _atBottom) {
      atBottomRef.current = _atBottom;
      setAtBottom(_atBottom);
    }
  }, []);

  const scrollToBottom = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    ref.current.scrollTop = ref.current.scrollHeight;
    atBottomRef.current = true;
    setAtBottom(true);
  }, []);

  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, []);

  return (
    <div className={cx(styles.commentsContainer, className)} {...otherProps}>
      <div {...props} onScroll={onScroll} ref={ref}>
        {children}
      </div>
      <div className={cx(styles.moreMessages, !atBottom && styles.isActive)} onClick={scrollToBottom}>
        More comments below
      </div>
    </div>
  );
});
