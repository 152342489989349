import { PropsWithChildren, createContext, useContext } from "react";

interface ButtonSetContextValue {
  size: number;
  index: number;
}

export const ButtonSetContext = createContext<ButtonSetContextValue>({
  size: 0,
  index: 0,
});

type ButtonSetProviderProps = PropsWithChildren<{
  value: ButtonSetContextValue;
}>;

export function ButtonSetProvider(props: ButtonSetProviderProps) {
  return <ButtonSetContext.Provider {...props} />;
}

export function useButtonSetContext() {
  return useContext(ButtonSetContext);
}
