import { PropsWithChildren } from "react";
import { useMedia } from "react-use";

export type BreakpointProps = PropsWithChildren<{
  at: keyof typeof breakpoints;
  mode?: "show" | "hide";
}>;

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1235,
  xxl: 1536,
};

export function Breakpoint(props: BreakpointProps) {
  const { at, mode = "show", children } = props;
  const breakpointPx = breakpoints[at];
  const isBreakpoint = useMedia(`(min-width: ${breakpointPx}px)`, true);

  if (mode === "hide") {
    return isBreakpoint ? null : <>{children}</>;
  }

  return isBreakpoint ? <>{children}</> : null;
}
