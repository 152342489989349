import loadingIcon from "public/images/awk-icon.png";
import styles from "./styles.css";

interface LoaderProps {
  size?: number;
}

export function Loader(props: LoaderProps) {
  const { size = 64 } = props;

  return (
    <div className={styles.component}>
      <img className={styles.icon} src={loadingIcon} alt="" width={size} />
    </div>
  );
}
