import cx from "classnames";
import ottoMP4 from "public/images/god-is-partying/otto.mp4";
import ottoPoster from "public/images/god-is-partying/otto.png";
import ottoWebM from "public/images/god-is-partying/otto.webm";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.css";

export function OTTO() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const stillRef = useRef<HTMLImageElement>(null);
  const [videoReady, setVideoReady] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);

  const onCanPlayThrough = useCallback(() => {
    if (!videoReady && videoRef.current) {
      videoRef.current.currentTime = 0.1;
    }
  }, [videoReady]);

  const onSeeked = useCallback(() => {
    setVideoReady(true);
  }, []);

  const onClick = useCallback(() => {
    setHasClicked(true);

    if (videoReady && !hasClicked) {
      if (stillRef.current) {
        stillRef.current.style.opacity = "0";
      }

      videoRef.current?.play().catch(() => {
        // Ignore
      });
    }
  }, [videoReady, hasClicked]);

  useEffect(() => {
    // Seems to help iOS
    videoRef.current?.load();
  }, []);

  // const onMouseLeave = useCallback(() => {
  //   if (!videoRef.current.paused) {
  //     videoRef.current.pause();
  //   }
  // }, []);

  return (
    <div className={cx(styles.otto, videoReady && !hasClicked && styles.canClick)} onClick={onClick}>
      <video
        className={styles.video}
        onCanPlayThrough={onCanPlayThrough}
        onSeeked={onSeeked}
        ref={videoRef}
        playsInline={true}
        muted={true}
      >
        <source type="video/webm" src={ottoWebM} />
        <source type="video/mp4" src={ottoMP4} />
      </video>
      <img className={styles.still} ref={stillRef} src={ottoPoster} />
    </div>
  );
}
