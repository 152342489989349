import { MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonSet } from "components/Button";
import { TimelineItem } from "shared/types";
import { BoxedText } from "../BoxedText";
import type { TimelineEvent, TimelineState } from "../shared";
import { TimelineEventInfo } from "../TimelineEvent";
import styles from "./styles.css";
import { TimelineCreatorURLStep } from "./TimelineCreatorURLStep";

export type Step = [
  number,
  {
    back?: Step;
    url?: string;
  } | null,
];

export type PartialTimelineEvent = Omit<TimelineEvent, "id" | "referenceIDs"> & {
  id: TimelineEvent["id"] | null;
  referenceIDs: TimelineEvent["referenceIDs"] | null;
};

interface TimelineCreatorProps {
  onCancel: () => void;
  onCreated: (item: TimelineItem) => void;
  timelineStateRef: RefObject<TimelineState>;
}

export function TimelineCreator(props: TimelineCreatorProps) {
  const { onCancel, onCreated, timelineStateRef } = props;
  const timelineEventRef = useRef<PartialTimelineEvent | null>(null);
  const [[step, stepData], setStep] = useState<Step>([0, null]);
  const [browserOpen, setBrowserOpen] = useState(false);
  const closeBrowser = useCallback(() => setBrowserOpen(false), []);
  // const [, setN] = useState(0);
  const onChange = useCallback(
    (newEvent: TimelineItem) => {
      onCreated(newEvent);
    },
    [onCreated],
  );

  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      if (!isEditing && stepData?.back) {
        setStep(stepData.back);
      }
    },
    [stepData],
  );

  const cancelIfDirect = useCallback(
    (event: MouseEvent) => {
      if (event.target === event.currentTarget) {
        onCancel();
      }
    },
    [onCancel],
  );

  useEffect(() => {
    if (step === 0) {
      const { current: timelineState } = timelineStateRef;

      if (!timelineState) {
        return;
      }

      const date = new Date(timelineState.date);

      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      timelineEventRef.current = {
        date,
        id: null,
        title: "",
        description: "",
        imageLeafID: null,
        referenceIDs: null,
        references: [],
      };
    }
  }, [step, timelineStateRef]);

  switch (step) {
    case 0:
      return (
        <div className={styles.component} onClick={cancelIfDirect}>
          <BoxedText>
            <div className={styles.createSelect}>
              <ButtonSet>
                <Button size="medium" onClick={() => setStep([1, null])}>
                  Create from URL
                </Button>
                <Button size="medium" onClick={() => setStep([2, { back: [0, null] }])}>
                  Create from scratch
                </Button>
              </ButtonSet>
            </div>
          </BoxedText>
        </div>
      );
    case 1: {
      return (
        <div className={styles.component} onClick={cancelIfDirect}>
          <TimelineCreatorURLStep
            initialUrl={stepData?.url || null}
            setStep={setStep}
            timelineEventRef={timelineEventRef}
          />
        </div>
      );
    }
    case 2: {
      return (
        <div className={styles.component}>
          <BoxedText>
            <TimelineEventInfo
              browserOpen={browserOpen}
              closeBrowser={closeBrowser}
              isEditing={true}
              onChangeEvent={onChange}
              setBrowserOpen={setBrowserOpen}
              setIsEditing={setIsEditing}
              timelineEvent={timelineEventRef.current as TimelineEvent}
            />
          </BoxedText>
        </div>
      );
    }
    default:
      return null;
  }
}
