import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { Bar, BarTitle } from "components/Bar";
import { LeafsList, LeafsListProps } from "components/LeafsList";
import { Loader } from "components/Loader";
import { useApi } from "contexts/api";
import * as cache from "shared/cache";
import { Collection, File, Leaf } from "shared/types";
import { LeafsBrowserUpload } from "./LeafsBrowserUpload";
import styles from "./styles.css";

type OnSelectMultipleFiles = (leafOrLeafs: File | File[]) => void | Promise<unknown> | Promise<void>;
type OnSelectSingleFile = (leaf: File) => void | Promise<unknown> | Promise<void>;

enum Tab {
  Browse = 1,
  Upload = 2,
}

export type LeafsBrowserProps<AllowMultipleLocalFiles extends boolean = false> = {
  allowLocalFile?: boolean;
  allowMultipleLocalFiles?: AllowMultipleLocalFiles;
  localFileUploadParentID?: string;
  confirm?: string | null;
  localFileWhitelist?: string;
  persistLocalFileUpload?: boolean;
} & Pick<LeafsListProps, "fileTypeWhitelist" | "hideDisabled" | "showComments" | "showOCR"> &
  (AllowMultipleLocalFiles extends true
    ? {
        onSelect: OnSelectMultipleFiles;
      }
    : {
        onSelect: OnSelectSingleFile;
      });

export function LeafsBrowser<AllowMultipleLocalFiles extends boolean = false>(
  props: LeafsBrowserProps<AllowMultipleLocalFiles>,
) {
  const {
    allowLocalFile,
    allowMultipleLocalFiles,
    confirm,
    fileTypeWhitelist,
    hideDisabled,
    localFileUploadParentID,
    localFileWhitelist,
    onSelect,
    persistLocalFileUpload,
    showComments = false,
    showOCR = false,
  } = props;

  const api = useApi();
  const isMounted = useRef(false);
  const [tab, setTab] = useState<Tab>(Tab.Browse);
  const [files, setFiles] = useState<Leaf[]>(() => api.cache.get("views", cache.View.RecentLeafsDeprecated) as Leaf[]);
  const [collection, setCollection] = useState<Collection | null>(null);
  const [collectionFiles, setCollectionFiles] = useState<File[] | null>(null);
  const [confirming, setConfirming] = useState<File | ((confirmed: boolean) => void) | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const collectionRef = useRef<Collection | null>(null);
  const collectionFilesRef = useRef<File[] | null>(null);

  useEffect(() => {
    isMounted.current = true;

    api.leaf.listRecent({ ids: false }).then((leafs) => {
      api.cache.set("views", cache.View.RecentLeafsDeprecated, leafs as unknown as Leaf[]);

      if (isMounted.current) {
        setFiles(leafs as unknown as Leaf[]);
      }
    });

    return () => {
      isMounted.current = false;
    };
  }, [api.cache, api.leaf]);

  const unsetCollection = useCallback(() => {
    setCollectionFiles(null);
    setCollection(null);
  }, []);

  const confirmSelect = useCallback(() => {
    if (!confirming) {
      return;
    }

    if (typeof confirming === "function") {
      confirming(true);
      setConfirming(null);
      return;
    }

    const thenable = onSelect(confirming);

    if ((thenable as Promise<void>)?.then) {
      setIsLoading(true);

      (thenable as Promise<void>)?.then(
        () => {
          isMounted.current && setIsLoading(false);
        },
        () => {
          isMounted.current && setIsLoading(false);
        },
      );
    }
  }, [confirming, onSelect]);

  const cancelSelect = useCallback(() => {
    if (typeof confirming === "function") {
      confirming(false);
    }

    setConfirming(null);
  }, [confirming]);

  const onClickLeaf = useCallback(
    (leaf: Leaf) => {
      if (leaf.type === "FILE") {
        if (confirm) {
          setConfirming(leaf);
        } else {
          const thenable = onSelect(leaf);

          if ((thenable as Promise<void>)?.then) {
            setIsLoading(true);

            (thenable as Promise<void>)?.then(
              () => {
                isMounted.current && setIsLoading(false);
              },
              () => {
                isMounted.current && setIsLoading(false);
              },
            );
          }
        }
      } else {
        const collection = leaf as Collection;

        collectionRef.current = collection;
        collectionFilesRef.current = collection.children || null;

        setCollection(collectionRef.current);
        setCollectionFiles(collectionFilesRef.current);

        api.collection.get(collection.id).then(
          (collection) => {
            if (collectionRef.current?.id === collection.id) {
              setCollectionFiles(collection.children);
            }
          },
          () => {
            if (collectionRef.current?.id === collection.id && !collection.children) {
              setCollection(null);
            }
          },
        );
      }
    },
    [api.collection, confirm, onSelect],
  );

  const confirmUpload = useCallback(() => {
    const promise = new Promise<boolean>((resolve) => {
      setConfirming(() => resolve);
    });

    return promise;
  }, []);

  const onUploaded = useCallback(
    (files: File[]) => {
      let thenable;

      if (allowMultipleLocalFiles) {
        thenable = (onSelect as OnSelectMultipleFiles)(files);
      } else {
        thenable = onSelect(files[0]);
      }

      if ((thenable as Promise<void>)?.then) {
        setIsLoading(true);

        (thenable as Promise<void>)?.then(
          () => {
            isMounted.current && setIsLoading(false);
          },
          () => {
            isMounted.current && setIsLoading(false);
          },
        );
      }
    },
    [allowMultipleLocalFiles, onSelect],
  );

  const setBrowseTab = useCallback(() => setTab(Tab.Browse), []);
  const setUploadTab = useCallback(() => setTab(Tab.Upload), []);

  return (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <Loader />
        </div>
      )}
      {confirming && (
        <div className={styles.confirming}>
          <div className={styles.reason}>{confirm}</div>
          <div className={styles.buttons}>
            <button className={styles.button} onClick={cancelSelect}>
              Cancel
            </button>
            <button className={cx(styles.button, styles.confirm)} onClick={confirmSelect}>
              Confirm
            </button>
          </div>
        </div>
      )}
      <div className={cx(styles.component, (confirming || isLoading) && styles.isConfirming)}>
        {allowLocalFile && (
          <div className={styles.tabs}>
            <div className={cx(styles.tab, tab === Tab.Browse && styles.activeTab)} onClick={setBrowseTab}>
              Select Existing File
            </div>
            <div className={cx(styles.tab, tab === Tab.Upload && styles.activeTab)} onClick={setUploadTab}>
              Upload New File
            </div>
          </div>
        )}
        {tab === Tab.Browse ? (
          <>
            {collection && (
              <>
                <Bar back={unsetCollection}>
                  <BarTitle>{collection.name}</BarTitle>
                </Bar>
                {collection.description && (
                  <div className={styles.description}>
                    <div className={styles.inner}>{collection.description}</div>
                  </div>
                )}
              </>
            )}
            {!files || (collection && !collectionFiles) ? (
              <Loader />
            ) : (
              <LeafsList
                containerSize="small"
                fileTypeWhitelist={fileTypeWhitelist}
                hideDisabled={hideDisabled}
                leafs={collectionFiles || files}
                onClickLeaf={onClickLeaf}
                saveSort={false}
                showComments={showComments}
                showOCR={showOCR}
              />
            )}
          </>
        ) : (
          <LeafsBrowserUpload
            accept={localFileWhitelist}
            allowMultiple={allowMultipleLocalFiles}
            parentID={localFileUploadParentID}
            confirm={confirm ? confirmUpload : undefined}
            onUploaded={onUploaded}
            persistUploads={persistLocalFileUpload}
          />
        )}
        {/* {allowLocalFile && (
          <div className={styles.upload}>
            <Button>
              + Upload New File
            </Button>
          </div>
        )} */}
      </div>
    </>
  );
}
