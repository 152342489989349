import { useEffect, useState } from "react";
import styles from "./styles.css";

const ReleaseDate = Date.parse("09/10/2021");

const Unit = {
  Day: 60 * 1000 * 60 * 24,
  Hour: 60 * 1000 * 60,
  Minute: 60 * 1000,
  Second: 1000,
};

function calculateCountdown() {
  const now = Date.now();

  if (ReleaseDate < now) {
    return null;
  }

  const diff = ReleaseDate - now;
  const days = Math.floor(diff / Unit.Day);
  const hours = Math.floor((diff - days * Unit.Day) / Unit.Hour);
  const minutes = Math.floor((diff - days * Unit.Day - hours * Unit.Hour) / Unit.Minute);
  const seconds = Math.floor((diff - days * Unit.Day - hours * Unit.Hour - minutes * Unit.Minute) / Unit.Second);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

function unit(unit: number) {
  const padded = `${unit}`.padStart(2, "0");

  if (unit === 55 || unit === 39 || unit === 93) {
    return <em>{padded}</em>;
  }

  return padded;
}

export function Countdown() {
  const [countdown, setCountdown] = useState(calculateCountdown);

  useEffect(() => {
    let timeout: number;

    function update() {
      const countdown = calculateCountdown();

      setCountdown(countdown);

      if (countdown != null) {
        if (timeout != null) {
          timeout = window.setTimeout(update, 1000);
        }
      } else {
        timeout = null;
      }
    }

    timeout = window.setTimeout(update, 1000);

    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, []);

  if (countdown == null) {
    return null;
  }

  return (
    <div className={styles.countdown}>
      <div className={styles.part}>
        {unit(countdown.days)} <span className={styles.unit}>days</span>
      </div>
      <div className={styles.part}>
        {unit(countdown.hours)} <span className={styles.unit}>hours</span>
      </div>
      <div className={styles.part}>
        {unit(countdown.minutes)} <span className={styles.unit}>minutes</span>
      </div>
      <div className={styles.part}>
        {unit(countdown.seconds)} <span className={styles.unit}>seconds</span>
      </div>
    </div>
  );
}
