import cx from "classnames";
import { useEffect, useMemo, useState } from "react";
import { LeafOCR } from "shared/types";
import { FilePreviewImageOCRBoxes } from "./FilePreviewImageOCRBoxes";
import styles from "./styles.css";

interface FilePreviewImageOCRProps {
  imageWidth: number;
  imageHeight: number;
  width: number;
  height: number;
  boxes: LeafOCR["boxes"];
}

export function FilePreviewImageOCR(props: FilePreviewImageOCRProps) {
  const { width, height, imageWidth, imageHeight, boxes } = props;
  const [forceShow, setForceShow] = useState(false);
  const ratio = useMemo<[number, number]>(() => {
    return [width / imageWidth, height / imageHeight];
  }, [width, height, imageWidth, imageHeight]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setForceShow(true);
      }
    };

    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setForceShow(false);
      }
    };

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  return (
    <div className={cx(styles.component, forceShow && styles.showBoxes)} style={{ width, height }}>
      <FilePreviewImageOCRBoxes boxes={boxes} ratio={ratio} />
    </div>
  );
}
