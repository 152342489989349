import { TimelineItem, TimelineItemTag } from "shared/types";

export const DefaultZoom = 31536000 * 1000 * 4; // (((((1000 * 60) * 60) * 24) * 7) * 4) * 12;
// const PxPerMs = document.body.offsetWidth / DefaultZoom;

export const timelineTagToString = new Map<TimelineItemTag, string>([
  ["ALBUM", "Album Release"],
  ["SINGLE", "Single Release"],
  ["EP", "EP Release"],
  ["INTERVIEW", "Interview"],
  ["ADVICE", "Advice Article"],
  ["LIVE_SHOW", "Live Show"],
  ["MUSIC_VIDEO", "Music Video"],
]);

export const timelineTagToColor = new Map<TimelineItemTag, string>([
  ["ALBUM", "linear-gradient(to bottom, #7d2424, #ce6a4c)"],
  ["SINGLE", "linear-gradient(to bottom, #18186f, #8f4aff)"],
  ["INTERVIEW", "linear-gradient(to bottom, #1f1e1e, #535354)"],
  ["ADVICE", "linear-gradient(to bottom, #961775, #c7893b)"],
  ["LIVE_SHOW", "linear-gradient(to bottom, #0e522d, #37b759)"],
  ["MUSIC_VIDEO", "linear-gradient(to bottom, #24437d, #4cacce)"],
  ["EP", "linear-gradient(to bottom, #427d24, #4c58ce)"],
]);

export const timelineTagToGradient = new Map<TimelineItemTag, string>([
  ["ALBUM", "linear-gradient(to bottom, #7d2424, #ce6a4c)"],
  ["SINGLE", "linear-gradient(to bottom, #18186f, #8f4aff)"],
  ["INTERVIEW", "linear-gradient(to bottom, #1f1e1e, #535354)"],
  ["ADVICE", "linear-gradient(to bottom, #961775, #c7893b)"],
  ["LIVE_SHOW", "linear-gradient(to bottom, #0e522d, #37b759)"],
  ["MUSIC_VIDEO", "linear-gradient(to bottom, #24437d, #4cacce)"],
  ["EP", "linear-gradient(to bottom, #427d24, #4c58ce)"],
]);

export type TimelineEvent = Omit<TimelineItem, "date"> & {
  date: Date;
};

export type TimelineState = {
  containerWidth: number;
  date: number;
  events: TimelineEvent[];
  eventsWindow: [number | null, number | null, number | null];
  markers: [number, string][];
  maxDate: number;
  minDate: number;
  pxPerMs: number;
  viewingEvent: Set<string>;
  width: number;
  zoom: number;
};

export function getEventsWindow(
  events: TimelineEvent[],
  zoom: number,
  toDate: number,
): [number | null, number | null, number | null] {
  const min = toDate - zoom;

  let activeEventIndex: number | null = null;
  let toIndex: number | null = null;
  let prevIndex: number | null = null;
  let i;

  const maxDate = toDate + zoom;

  for (i = events.length - 1; i >= 0; i--) {
    if (!events[i]) {
      continue;
    }

    const date = events[i].date.getTime();

    if (toIndex == null && date <= maxDate && date >= min) {
      toIndex = i;
    }

    if (activeEventIndex == null && date <= toDate) {
      activeEventIndex = i;
    }

    if (date < min) {
      break;
    }

    prevIndex = i;
  }

  return [prevIndex, toIndex, activeEventIndex];
}

export function getTimelineWidth(events: TimelineEvent[], pxPerMs: number, zoom: number, minDate: number) {
  const min = minDate;
  const max = events[events.length - 1].date.getTime() + zoom / 2;
  return (max - min) * pxPerMs;
}

export function calculateEventX(state: TimelineState, date: number) {
  return (state.date - date) * state.pxPerMs * -1;
}

export const parseEvent = (item: TimelineItem): TimelineEvent => ({
  ...item,
  date: new Date(item.date),
});

export function getTimeLineStyle(state: TimelineState) {
  const { date, minDate, width, pxPerMs } = state;

  let x = (date - minDate) * pxPerMs;

  if (x < state.containerWidth / 2) {
    x = state.containerWidth / 2 - x;
  } else if (x >= width - state.containerWidth) {
    const d = x - (width - state.containerWidth);
    x = d * -1;
  } else {
    x = 0;
  }

  return {
    transform: `translate3d(${x}px, -50%, 0)`,
    width: "100vw",
  };
}

export function sortEvents(a: TimelineEvent, b: TimelineEvent) {
  const aTime = a.date.getTime();
  const bTime = b.date.getTime();
  return aTime - bTime;
}

export function getMarkersForDate(
  currentDate: TimelineState["date"],
  minDateMs: number,
  maxDateMs: number,
  zoom: TimelineState["zoom"],
) {
  const minDate = new Date(Math.max(minDateMs, currentDate - zoom));
  const maxDate = new Date(Math.min(currentDate + zoom, maxDateMs));
  const maxYear = maxDate.getFullYear();
  const markers: [number, string][] = [];

  minDate.setDate(1);
  minDate.setMonth(0);
  minDate.setHours(0);
  minDate.setMinutes(0);
  minDate.setSeconds(0);
  minDate.setMilliseconds(0);

  let date;

  while (!date || date.getFullYear() < maxYear) {
    if (!date) {
      date = new Date(minDate);
    } else {
      date.setFullYear(date.getFullYear() + 1);
    }

    markers.push([date.getTime(), `${date.getFullYear()}`]);
  }

  return markers;
}
