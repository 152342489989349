import { DragEvent, useRef, useState } from "react";
import { useEvent } from "hooks/useEvent";
import { useUploaderContext } from "./context";
import { getAllFileEntries } from "./utils";

export function useUploaderDND() {
  const uploader = useUploaderContext();
  const dragEntered = useRef(0);
  const [dropping, setDropping] = useState<number>(null);
  const onDragOver = useEvent((event: DragEvent) => event.preventDefault());
  const onDragEnter = useEvent((event: DragEvent) => {
    event.preventDefault();
    dragEntered.current++;
    setDropping(event.dataTransfer.items.length);
  });

  const onDragLeave = useEvent((event: DragEvent) => {
    event.preventDefault();

    dragEntered.current--;

    if (dragEntered.current === 0) {
      setDropping(null);
    }
  });

  const onDrop = useEvent((event: DragEvent) => {
    event.preventDefault();

    getAllFileEntries(event.dataTransfer.items).then((res) => {
      if (res.initialName && uploader.getTitle() === "") {
        uploader.setTitle(res.initialName);
      }

      uploader.addFiles(res.files);
    });

    setDropping(null);
  });

  return [
    dropping,
    {
      onDragOver,
      onDragEnter,
      onDragLeave,
      onDrop,
    },
  ] as const;
}
