import cx from "classnames";
import { ComponentType, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IconAscending, IconCards, IconChart, IconDescending, IconHot, IconList, IconTime } from "components/Icons";
import { Loader } from "components/Loader";
import { RequestStoreKey, useRequestStore } from "contexts/requestStore";
import { useEvent } from "hooks/useEvent";
import { Sort, getAscending, getSort, sortLeafsInPlace } from "shared/leafSort";
import { File, FileType, Leaf } from "shared/types";
import { LeafsListCard } from "./LeafsListCard";
import { LeafsListRow } from "./LeafsListRow";
import styles from "./styles.css";

export interface LeafsListProps {
  leafs: Leaf[] | null;
  isAscending?: boolean;
  HeaderComponent?: ComponentType<PropsWithChildren>;
  cardWidth?: number | string;
  containerSize?: "small" | "regular";
  fileTypeWhitelist?: FileType[] | null;
  fixedHeader?: boolean;
  hideDisabled?: boolean;
  isLoading?: boolean;
  leafsClassName?: string;
  leafsMode?: LeafsMode;
  onClickLeaf?(leaf: Leaf): void;
  saveSort?: boolean;
  saveView?: boolean;
  showComments?: boolean;
  showDates?: boolean;
  showViewCounts?: boolean;
  showOCR?: boolean;
  sort?: Sort | false;
  viewMode?: ViewMode;
  width?: string | number;
}

export enum ViewMode {
  Rows = 1,
  Cards = 2,
}

export enum LeafsMode {
  Hot = "hot",
  Recent = "recent",
  MostViewed = "most-viewed",
  Oldest = "oldest",
  Manual = "manual",
}

const FILE_ROUTE_REGEX = /^\/files\/[a-z0-9-]{5}/;

export function LeafsList(props: LeafsListProps) {
  const {
    isAscending: manualAscending,
    containerSize,
    fileTypeWhitelist,
    fixedHeader,
    hideDisabled,
    isLoading = false,
    leafs,
    leafsMode = LeafsMode.Manual,
    leafsClassName,
    onClickLeaf,
    HeaderComponent = "div",
    saveSort = true,
    saveView = true,
    showComments = true,
    showDates = true,
    showViewCounts = true,
    showOCR = true,
    sort: manualSort,
    viewMode,
  } = props;

  const requestStore = useRequestStore();
  const location = useLocation();
  const [sort, setSort] = useState<Sort | false>(() => (manualSort != null ? manualSort : getSort(requestStore)));
  const [localViewMode, setLocalViewMode] = useState(
    () => viewMode || requestStore?.get(RequestStoreKey.LeafListView, ViewMode.Cards),
  );

  const [ascending, setAscending] = useState(() =>
    manualAscending != null ? manualAscending : getAscending(requestStore),
  );

  const LeafItemComponent = localViewMode === ViewMode.Cards ? LeafsListCard : LeafsListRow;

  const onClickName = useEvent(() => {
    if (manualSort == null) {
      if (sort === Sort.Name) {
        setAscending(!ascending);
        return;
      }

      setSort(Sort.Name);
      setAscending(true);
    }
  });

  const onClickDate = useEvent(() => {
    if (manualSort == null) {
      if (sort === Sort.Date) {
        setAscending(!ascending);
        return;
      }

      setSort(Sort.Date);
      setAscending(true);
    }
  });

  const onClickViews = useEvent(() => {
    if (manualSort == null) {
      if (sort === Sort.Views) {
        setAscending(!ascending);
        return;
      }

      setSort(Sort.Views);
      setAscending(false);
    }
  });

  const toggleViewMode = useEvent(() => {
    setLocalViewMode(localViewMode === ViewMode.Cards ? ViewMode.Rows : ViewMode.Cards);
  });

  const sortedLeafs = useMemo<Leaf[] | null>(() => {
    if (!leafs || sort === false) {
      return leafs;
    }

    return sortLeafsInPlace([...leafs], sort, ascending);
  }, [leafs, sort, ascending]);

  useEffect(() => {
    if (manualSort != null) {
      setSort(manualSort);
    }
  }, [manualSort]);

  useEffect(() => {
    if (manualAscending != null) {
      setAscending(manualAscending);
    }
  }, [manualAscending]);

  useEffect(() => {
    if (requestStore && localViewMode != null && saveView) {
      requestStore.set(RequestStoreKey.LeafListView, localViewMode);
    }
  }, [saveView, requestStore, localViewMode]);

  useEffect(() => {
    if (viewMode) {
      setLocalViewMode(viewMode);
    }
  }, [viewMode]);

  useEffect(() => {
    if (requestStore && saveSort !== false) {
      requestStore.set(RequestStoreKey.LeafListSortType, sort);
      requestStore.set(RequestStoreKey.LeafListSortAscending, ascending);
    }
  }, [sort, ascending, requestStore, saveSort]);

  return (
    <>
      <div className={cx(styles.tableHeaderOuter, fixedHeader && styles.fixedHeader)}>
        <HeaderComponent>
          <div className={styles.tableHeaderInner}>
            <div className={cx(styles.tableHeader, !manualSort && styles.sortable)}>
              <div
                className={cx(styles.iconCell, !viewMode && styles.canToggle)}
                onClick={viewMode ? undefined : toggleViewMode}
              >
                <div className={styles.icon}>
                  {viewMode ? (
                    <div className={styles.icon} />
                  ) : localViewMode === ViewMode.Cards ? (
                    <IconCards size={17} />
                  ) : (
                    <IconList size={17} />
                  )}
                </div>
              </div>
              {leafsMode !== LeafsMode.Manual ? (
                <div className={styles.tabs}>
                  <NavLink className={({ isActive }) => cx(styles.tab, isActive && styles.isActive)} to="/" end={true}>
                    Recent <IconTime className={styles.icon} size={16} />
                  </NavLink>
                  <NavLink className={({ isActive }) => cx(styles.tab, isActive && styles.isActive)} to="/hot">
                    Hot <IconHot className={styles.icon} size={16} />
                  </NavLink>
                  <NavLink className={({ isActive }) => cx(styles.tab, isActive && styles.isActive)} to="/most-viewed">
                    Most Viewed <IconChart className={styles.icon} size={16} />
                  </NavLink>
                  <NavLink className={({ isActive }) => cx(styles.tab, isActive && styles.isActive)} to="/oldest">
                    Oldest
                  </NavLink>
                </div>
              ) : (
                <>
                  <div className={cx(styles.name, sort === Sort.Name && styles.isActive)} onClick={onClickName}>
                    Name{" "}
                    {sort === Sort.Name && (ascending ? <IconAscending size={17} /> : <IconDescending size={17} />)}
                  </div>
                  {showViewCounts && (
                    <div className={cx(styles.views, sort === Sort.Views && styles.isActive)} onClick={onClickViews}>
                      <span>Views </span>
                      {sort === Sort.Views && (ascending ? <IconAscending size={17} /> : <IconDescending size={17} />)}
                    </div>
                  )}
                  {showDates && (
                    <div className={cx(styles.date, sort === Sort.Date && styles.isActive)} onClick={onClickDate}>
                      <span>Date </span>
                      {sort === Sort.Date && (ascending ? <IconAscending size={17} /> : <IconDescending size={17} />)}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </HeaderComponent>
      </div>
      {!isLoading && sortedLeafs ? (
        <div
          className={cx(
            styles.leafs,
            leafsClassName,
            localViewMode === ViewMode.Cards && styles.hasCards,
            fixedHeader && styles.addHeaderPadding,
            containerSize === "small" && styles.smallContainer,
          )}
        >
          {sortedLeafs.map((leaf) => {
            const disabled =
              (fileTypeWhitelist &&
                ((leaf.type === "FILE" && !fileTypeWhitelist.includes((leaf as File).fileType)) ||
                  (leaf.type === "COLLECTION" &&
                    leaf.childFileTypes &&
                    !Object.keys(leaf.childFileTypes).some((type: FileType) => fileTypeWhitelist.includes(type))))) ||
              false;

            if (hideDisabled && disabled) {
              return null;
            }

            return (
              <LeafItemComponent
                disabled={disabled}
                fileTypeWhitelist={fileTypeWhitelist}
                key={leaf.id}
                leaf={leaf}
                onPress={onClickLeaf}
                showComments={showComments}
                showDate={showDates}
                showOCR={showOCR}
                showViewCounts={showViewCounts}
                toState={
                  !onClickLeaf &&
                  leaf.id &&
                  location.pathname !== "/files" && {
                    from: FILE_ROUTE_REGEX.test(location.pathname)
                      ? location.state?.from
                      : location.state?.from || location.pathname,
                    fileTypeWhitelist,
                  }
                }
                to={(!onClickLeaf || leaf.id) && `/files/${leaf.id}`}
              />
            );
          })}
        </div>
      ) : (
        <div className={styles.loading}>
          <Loader />
        </div>
      )}
    </>
  );
}
