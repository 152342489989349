export type JSONPrimitive = string | number | boolean | null;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray;
export type JSONObject = {
  [member: string]: JSONValue;
};

export type JSONArray = JSONValue[];

export type CDNAssetType = "HLS" | "MP4";
export type CDNType = "VIMEO";
export type EntityType = "LEAF" | "PARTY" | "TIMELINE" | "TIMELINE_ITEM";

export type CDNAsset = {
  cdn: CDNType;
  type: CDNAssetType;
  url: string;
  label?: string;
  size?: number;
  width?: number;
  height?: number;
  mime?: string;
};

export type Comment = {
  id: string;
  body: string;
  createdAt: string;
  entityID: string;
  entityType: EntityType;
  name: string;
  tripCode: string;
};

export type LeafType = "FILE" | "COLLECTION";

export type Leaf<T extends LeafType = LeafType> = {
  commentsCount?: number;
  createdAt: string;
  description?: string;
  hasOCR?: boolean;
  id: string;
  name: string;
  type: T;
  viewsCount: number;
  updatedAt?: string;
} & FileOrCollectionProps<T>;

export type LeafOCR = {
  text: string;
  boxes: [number, number, number, number, number, number, number, number, string][];
};

export type BaseFile = {
  cdnAssets?: CDNAsset[];
  parentID?: string;
  downloadURL?: string;
  duration?: number;
  fileName?: string;
  fileType: FileType;
  width?: number;
  height?: number;
  mime?: string;
  posterURL?: string;
  size?: number;
  streamURL?: string;
  type: "FILE";
  url?: string;
};

export type BaseCollection = {
  children?: File[];
  childFileTypes?: {
    [key in FileType]?: number;
  };
  parentID?: string;
  published?: boolean;
  posterURL?: string;
  posterFileID?: string;
  posterAutoID?: string;
  type: "COLLECTION";
};

export type File = BaseFile & Leaf;
export type Collection = BaseCollection & Leaf;
export type FileOrCollection = File | Collection;

type FileOrCollectionProps<T extends LeafType> = T extends "COLLECTION"
  ? BaseCollection
  : T extends "FILE"
  ? BaseFile
  : unknown;

export type PartyMode = "LOOPING" | "QUEUE";

export type Party = {
  coverFileID?: string | null;
  coverImageURL?: string | null;
  comments?: boolean | null;
  date: number;
  fileIDs: string[];
  id: string;
  mode?: PartyMode | null;
  name: string;
  token?: string | null;
};

export type FileType = "IMAGE" | "VIDEO" | "AUDIO" | "UNKNOWN";

export type Archive = {
  id: string;
  ready: boolean;
  progress: number;
  url?: string;
};

export const fileTypeNames = new Map<FileType, string>([
  ["IMAGE", "Image"],
  ["VIDEO", "Video"],
  ["AUDIO", "Audio"],
  ["UNKNOWN", "Misc."],
]);

export const fileTypeNamesPlural = new Map<FileType, string>([
  ["IMAGE", "Images"],
  ["VIDEO", "Videos"],
  ["AUDIO", "Audios"],
  ["UNKNOWN", "Misc."],
]);

export type TimelineItemReferenceType = "LEAF" | "YOUTUBE" | "VIMEO" | "URL";
export type TimelineItemChangeType = "ADD_REFERENCE" | "REMOVE_REFERENCE" | "CHANGE_PROPERTIES";

export type TimelineItemReferenceLeafRole = "VIDEO_BACKUP" | "FAVICON_BACKUP" | "THUMBNAIL_BACKUP" | "PDF_CAPTURE";

export type TimelineItemReference<T extends TimelineItemReferenceType = TimelineItemReferenceType> = {
  leafIDs?: string[];
  leafs?: {
    leaf: Leaf;
    role: TimelineItemReferenceLeafRole;
  }[];
  createdAt?: string;
  data: TimelineItemReferenceDataForType<T>;
  id?: string;
  type: T;
  archiveURL?: string;
};

export type TimelineItemTag = "ALBUM" | "SINGLE" | "INTERVIEW" | "ADVICE" | "LIVE_SHOW" | "MUSIC_VIDEO" | "EP";

export type TimelineItem = {
  id: string;
  title: string;
  description: string;
  date: string;
  imageLeafID?: string | null;
  coverImageURL?: string | null;
  referenceIDs: string[];
  references?: TimelineItemReference[];
  tags?: TimelineItemTag[];
};

export type UnpersistedTimelineItem = Omit<TimelineItem, "id" | "referenceIDs">;

export type TimelineItemLeafReferenceData = {
  description: string;
  date: string;
  title: string;
  leafID: string;
};

export type TimelineItemYouTubeReferenceData = {
  date: string;
  description: string;
  tags: string[];
  thumbnailURL: string;
  thumbnails: {
    url: string;
    width: number;
    height: number;
  };
  title: string;
  videoID: string;
};

export type TimelineItemVimeoReferenceData = {
  date: string;
  description: string;
  thumbnailURL: string;
  title: string;
  videoID: string;
  author: {
    name: string;
    url: string;
  };
};

export type TimelineItemURLReferenceData = {
  url: string;
  type: "html";
  title: string;
  thumbnailURL: string;
  images: {
    url: string;
    width: number;
    height: number;
    size: number;
    caption: string;
  }[];
  media: {
    type: "video";
    url: string;
    html: string;
    width: number;
    height: number;
  }[];
  description: string;
  lead: string;
  date: string;
  content: string;
  provider: string;
  providerIconURL: string;
  authors: {
    name: string;
    url: string;
  }[];
};

export type TimelineItemReferenceDataForType<T extends TimelineItemReferenceType> = T extends "LEAF"
  ? TimelineItemLeafReferenceData
  : T extends "YOUTUBE"
  ? TimelineItemYouTubeReferenceData
  : T extends "VIMEO"
  ? TimelineItemVimeoReferenceData
  : T extends "URL"
  ? TimelineItemURLReferenceData
  : unknown;

export type TimelineReferenceFileRole = "PDF_CAPTURE" | "VIDEO_BACKUP" | "THUMBNAIL_BACKUP";

type EntityForSearchResult<T extends string> = T extends "LEAF" ? Leaf : Record<never, never>;

export type SearchResult<T extends string = string> = {
  id: string;
  type: T;
  entity: EntityForSearchResult<T>;
};
