import { ReactElement, UIEvent, cloneElement, createContext, useContext, useMemo, useRef, useState } from "react";
import { useEvent } from "./useEvent";

export type ScrollListener = (event: UIEvent<HTMLElement>) => void;

export type ScrollContainerContext = {
  element: HTMLElement | null;
  scrollListeners: ScrollListener[];
};

const ScrollContainerContext = createContext<ScrollContainerContext>(null);

export function useScrollContainerProvider() {
  const [element, ref] = useState<HTMLElement | null>(null);
  const scrollListeners = useRef<ScrollListener[]>([]);
  const onScroll = useEvent((event) => {
    scrollListeners.current.forEach((listener) => listener(event));
  });

  const contextValue = useMemo(
    () => ({
      element,
      scrollListeners: scrollListeners.current,
    }),
    [element],
  );

  return function renderWithScrollProviderContext(children: ReactElement) {
    return (
      <ScrollContainerContext.Provider value={contextValue}>
        {cloneElement(children, { ref, onScroll })}
      </ScrollContainerContext.Provider>
    );
  };
}

export function useScrollContainer() {
  return useContext(ScrollContainerContext);
}
