import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconDescending = forwardRef<SVGSVGElement, IconProps>(function IconDescending(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-12h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z" />
    </svg>
  );
});
