import cx from "classnames";
import oneByOne from "public/images/1x1.png";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Select, { GroupBase, StylesConfig } from "react-select";
import appStyles from "components/App/styles.css";
import { Button, ButtonSet } from "components/Button";
import { IconPen, IconPencil, IconTrashBin } from "components/Icons";
import { LeafsBrowserOverlay } from "components/LeafsBrowser";
import { SmoothImage } from "components/Smooth";
import { useApi } from "contexts/api";
import { useEvent } from "hooks/useEvent";
import { THEIA_HOST } from "shared/api";
import { FileType, JSONObject, Leaf, TimelineItem, TimelineItemTag } from "shared/types";
import { TimelineEvent, timelineTagToGradient, timelineTagToString } from "../../shared";
import styles from "./styles.css";

interface SelectOption {
  value: TimelineItemTag;
  label: string;
}

const BROWSER_FILE_WHITELIST = ["IMAGE"] satisfies FileType[];
const TAG_OPTIONS = [...timelineTagToString].map(([k, v]) => ({
  value: k,
  label: v,
}));

const selectStyles: StylesConfig<SelectOption, true, GroupBase<SelectOption>> = {
  container(provided) {
    return {
      ...provided,
      paddingTop: 15,
    };
  },
  placeholder(provided) {
    return {
      ...provided,
      fontSize: 15,
      fontWeight: 400,
    };
  },
  control(provided, state) {
    return {
      ...provided,
      backgroundColor: "transparent",
      // border: '1px solid rgba(255, 255, 255, 0.1)',
      borderRadius: 6,
      borderColor: state.isFocused ? "rgba(255, 255, 255, 0.25)" : "rgba(255, 255, 255, 0.1)",
      boxShadow: "none",

      ":hover": {
        borderColor: state.isFocused ? "rgba(255, 255, 255, 0.25)" : "rgba(255, 255, 255, 0.1)",
      },
    };
  },
  indicatorSeparator(provided) {
    return {
      ...provided,
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    };
  },
  valueContainer(provided) {
    return {
      ...provided,
      maxWidth: 350,
      padding: 12,
    };
  },
  multiValue(provided, state) {
    return {
      ...provided,
      margin: 5,
      background: timelineTagToGradient.get(state.data.value) || "rgba(255, 255, 255, 0.1)",
      border: 0,
      borderRadius: 4,
    };
  },
  multiValueLabel(provided) {
    return {
      ...provided,
      padding: 8,
      paddingLeft: 8,
      borderRadius: 0,
      fontSize: 14,
      fontWeight: 600,
      textShadow: "0 1px 3px rgba(0, 0, 0, 0.5)",
      color: "white",
    };
  },
  multiValueRemove(provided) {
    return {
      ...provided,
      transition: "background-color 250ms ease",
      padding: 5,
      paddingLeft: 5,
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
      },

      svg: {
        width: 18,
        height: 18,
      },
    };
  },
  menu(provided) {
    return {
      ...provided,
      marginTop: -5,
      padding: 15,
      borderRadius: "0 0 6px 6px",
      backgroundColor: "black",
      boxShadow: "0 6px 18px 2px rgba(0, 0, 0, 0.5)",
      border: "1px solid rgba(255, 255, 255, 0.25)",
      borderTopColor: "rgba(255, 255, 255, 0.1)",
      textAlign: "left",
    };
  },
  option(provided, state) {
    return {
      ...provided,
      transition: "opacity 250ms ease",
      marginBottom: 14,
      padding: 8,
      fontSize: 14,
      fontWeight: 600,
      textShadow: "0 1px 3px rgba(0, 0, 0, 0.5)",
      background: timelineTagToGradient.get(state.data.value),
      borderRadius: 4,
      opacity: state.isFocused ? 1 : 0.25,
      cursor: "pointer",

      "&:last-child": {
        marginBottom: 0,
      },
    };
  },
};

function getDateAndTimeForDate(date: Date) {
  if (!date) {
    return ["", ""];
  }

  const dateStr = [
    date.toLocaleString("en-GB", { year: "numeric" }),
    date.toLocaleString("en-GB", { month: "2-digit" }),
    date.toLocaleString("en-GB", { day: "2-digit" }),
  ].join("-");

  const timeStr = date.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }); // cant use `timeStyle` in safari

  return [dateStr, timeStr];
}

interface TimelineEventInfoProps {
  browserOpen: boolean;
  closeBrowser(x: number, y: number): void;
  timelineEvent: TimelineEvent;
  isEditing: boolean;
  onChangeEvent(event: TimelineItem): void;
  className?: string;
  setBrowserOpen?(open: boolean): void;
  setIsEditing?(isEditing: boolean): void;
}

export function TimelineEventInfo(props: TimelineEventInfoProps) {
  const {
    browserOpen,
    className,
    closeBrowser,
    isEditing,
    onChangeEvent,
    setBrowserOpen,
    setIsEditing,
    timelineEvent,
  } = props;

  const api = useApi();
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState<string>(timelineEvent.title);
  const [coverID, setCoverID] = useState<string | null>(timelineEvent.imageLeafID || null);
  const [description, setDescription] = useState<string>(timelineEvent.description);
  const [date, setDate] = useState<string>(() => getDateAndTimeForDate(timelineEvent.date)[0]);
  const [time, setTime] = useState<string>(() => getDateAndTimeForDate(timelineEvent.date)[1]);
  const [tags, setTags] = useState<readonly SelectOption[]>(() =>
    timelineEvent.tags
      ? timelineEvent.tags.map<SelectOption>((tag: TimelineItemTag) => ({
          label: timelineTagToString.get(tag)!,
          value: tag,
        }))
      : [],
  );

  const valuesRef = useRef<{
    title: string;
    description: string;
    date: string;
    time: string;
  } | null>();

  const clearCover = useEvent(() => setCoverID(null));

  const resetEditingState = useEvent(() => {
    setTitle(timelineEvent.title);
    setDescription(timelineEvent.description);
    setDate(date);
    setTime(time);

    if (setIsEditing) {
      setIsEditing(true);
    }

    valuesRef.current = {
      title: timelineEvent.title,
      description: timelineEvent.description,
      date,
      time,
    };
  });

  const startEditing = useEvent(() => {
    resetEditingState();
    setIsEditing?.(true);
  });

  const openAddBrowser = useEvent((event: MouseEvent) => {
    event.preventDefault();
    setBrowserOpen?.(true);
  });

  const onSelectFile = useEvent((file: Leaf) => {
    if (timelineEvent.id) {
      return api.timeline
        .updateItem(timelineEvent.id, {
          imageLeafID: file.id,
        })
        .then((res) => {
          if (res) {
            onChangeEvent(res);
          }

          setBrowserOpen?.(false);
        });
    } else {
      setCoverID(file.id);
      setBrowserOpen?.(false);
    }
  });

  const onChangeTitle = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);

    if (valuesRef.current) {
      valuesRef.current.title = event.target.value;
    }
  });

  const onChangeDescription = useEvent((event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);

    if (valuesRef.current) {
      valuesRef.current.description = event.target.value;
    }
  });

  const onChangeDate = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);

    if (valuesRef.current) {
      valuesRef.current.date = event.target.value;
    }
  });

  const onChangeTime = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);

    if (valuesRef.current) {
      valuesRef.current.time = event.target.value;
    }
  });

  const cancelEditing = useEvent(() => {
    setIsEditing?.(false);
    setIsSaving(false);
    setTitle("");
    setDescription("");
    valuesRef.current = null;
  });

  const saveEditing = useEvent(() => {
    if (!isSaving) {
      const changes: JSONObject = {};
      const { current: values } = valuesRef;

      if (!values) {
        return;
      }

      if (values.title !== timelineEvent.title || !timelineEvent.id) {
        changes.title = values.title;

        if (!(changes.title as string).trim()) {
          return;
        }
      }

      if (values.description !== timelineEvent.description || !timelineEvent.id) {
        changes.description = values.description;
      }

      if (!values.date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return;
      }

      if (values.time === "") {
        values.time = "00:00";
      } else if (!values.time.match(/^\d{2}:\d{2}$/)) {
        return;
      }

      const dateTs = Date.parse(`${values.date} ${values.time}`);

      if (Number.isNaN(dateTs)) {
        return;
      }

      if (!timelineEvent.date || dateTs !== timelineEvent.date.getTime() || !timelineEvent.id) {
        changes.date = new Date(dateTs).toISOString();
      }

      const newTags = tags.map((tag) => tag.value);

      if (
        (newTags && !timelineEvent.tags) ||
        newTags.length !== timelineEvent.tags?.length ||
        (timelineEvent.tags && !timelineEvent.tags.every((tag, i) => newTags[i] === tag))
      ) {
        changes.tags = newTags;
      }

      if (Object.keys(changes).length) {
        setIsSaving(true);

        const promise = timelineEvent.id
          ? api.timeline.updateItem(timelineEvent.id, changes)
          : api.timeline.createItem({
              ...changes,
              referenceIDs: timelineEvent.referenceIDs,
              imageLeafID: coverID,
            });

        promise
          .then(onChangeEvent)
          .then(() => {
            cancelEditing();
          })
          .catch(() => {
            setIsSaving(false);
          });
      } else {
        cancelEditing();
      }
    }
  });

  const invalidTitle = title?.trim?.() === "";
  const openEditorButtons = setIsEditing && (
    <ButtonSet>
      {!timelineEvent.imageLeafID && (
        <Button className={styles.btn} onClick={openAddBrowser}>
          Set a Cover
        </Button>
      )}
      <Button className={cx(styles.btn, styles.edit)} onClick={startEditing}>
        <IconPen className={styles.icon} size={16} />
      </Button>
    </ButtonSet>
  );

  useEffect(() => {
    if (isEditing && !valuesRef.current) {
      resetEditingState();
    }
  }, [isEditing, resetEditingState]);

  return (
    <>
      {browserOpen && (
        <LeafsBrowserOverlay
          confirm={coverID ? "Are you sure you want to replace this event’s cover?" : undefined}
          fileTypeWhitelist={BROWSER_FILE_WHITELIST}
          localFileUploadParentID="timeline-assets"
          localFileWhitelist="image/"
          onClose={closeBrowser}
          onSelect={onSelectFile}
          persistLocalFileUpload={false}
          allowLocalFile={true}
          hideDisabled={true}
        />
      )}
      {isEditing ? (
        <>
          <div className={styles.wrapped}>
            {!timelineEvent.id &&
              (coverID ? (
                <div className={styles.imagePreview}>
                  <img className={styles.sizer} src={oneByOne} />
                  <div className={styles.actions}>
                    <ButtonSet>
                      <Button className={styles.btn} onClick={openAddBrowser}>
                        <IconPencil className={styles.icon} size={12} />
                      </Button>
                      <Button className={styles.btn} onClick={clearCover}>
                        <IconTrashBin className={styles.icon} size={12} />
                      </Button>
                    </ButtonSet>
                  </div>
                  {coverID && <SmoothImage opacity={1} url={`${THEIA_HOST}/leafs/${coverID}/theia/card/jpeg`} />}
                </div>
              ) : (
                <div className={styles.imagePreview}>
                  <img className={styles.sizer} src={oneByOne} />
                  <div className={styles.centerButton}>
                    <Button onClick={openAddBrowser}>Set a cover</Button>
                  </div>
                </div>
              ))}
            <div className={cx(styles.eventInfo, className)}>
              <input
                className={cx(styles.input, styles.title, invalidTitle && styles.invalid)}
                disabled={isSaving}
                onChange={onChangeTitle}
                placeholder="Event title"
                type="text"
                value={title}
                autoFocus={true}
              />
              <textarea
                className={cx(styles.input, styles.description)}
                disabled={isSaving}
                onChange={onChangeDescription}
                placeholder="Event description"
                rows={4}
                value={description}
              />
              <Select<SelectOption, true>
                isDisabled={isSaving}
                options={TAG_OPTIONS}
                menuPortalTarget={document.querySelector<HTMLElement>(`.${appStyles.portals}`)}
                placeholder="Select any tags..."
                styles={selectStyles}
                value={tags}
                onChange={setTags}
                isMulti={true}
              />
              <div className={styles.rowLabel}>Date &amp; Time (your timezone)</div>
              <div className={styles.row}>
                <input
                  className={styles.input}
                  type="date"
                  name="date"
                  onChange={onChangeDate}
                  pattern="\d{4}-\d{2}-\d{2}"
                  placeholder="yyyy-mm-dd"
                  value={date}
                />
                <input
                  className={styles.input}
                  type="time"
                  name="time"
                  onChange={onChangeTime}
                  pattern="\d{2}:\d{2}"
                  placeholder="--:--"
                  value={time}
                />
              </div>
              {/* <div className={styles.footer}>
                {date.toLocaleString()}
              </div> */}
            </div>
          </div>
          <div className={styles.buttons}>
            <ButtonSet>
              {setIsEditing && !isSaving && (
                <Button disabled={isSaving} onClick={cancelEditing}>
                  {timelineEvent.id ? "Cancel Changes" : "Back"}
                </Button>
              )}
              <Button className={styles.save} disabled={isSaving || invalidTitle} onClick={saveEditing}>
                {isSaving
                  ? timelineEvent.id
                    ? "Saving..."
                    : "Adding..."
                  : timelineEvent.id
                  ? "Save Changes"
                  : "Add Event"}
              </Button>
            </ButtonSet>
          </div>
        </>
      ) : (
        <div className={styles.wrapped}>
          {timelineEvent.imageLeafID && (
            <a
              className={styles.imagePreview}
              href={`/files/${timelineEvent.imageLeafID}`}
              target="_blank"
              rel="noreferrer"
            >
              <img className={styles.sizer} src={oneByOne} />
              {setIsEditing && (
                <div className={styles.actions}>
                  <Button className={styles.btn} onClick={openAddBrowser}>
                    <IconPencil className={styles.icon} size={12} />
                  </Button>
                </div>
              )}
              <SmoothImage
                className={styles.image}
                opacity={1}
                url={[
                  {
                    type: "image/webp",
                    url: `${THEIA_HOST}/leafs/${timelineEvent.imageLeafID}/theia/card/webp`,
                  },
                  {
                    type: "image/jpeg",
                    url: `${THEIA_HOST}/leafs/${timelineEvent.imageLeafID}/theia/card/jpeg`,
                  },
                ]}
              />
            </a>
          )}
          <div className={styles.eventInfo}>
            {timelineEvent.tags && timelineEvent.tags.length > 0 ? (
              <>
                <div className={styles.top}>
                  <div className={styles.tags}>
                    {timelineEvent.tags.map((tag) => (
                      <div
                        className={styles.tag}
                        key={tag}
                        style={{
                          backgroundImage: timelineTagToGradient.get(tag),
                        }}
                      >
                        {timelineTagToString.get(tag)}
                      </div>
                    ))}
                  </div>
                  {openEditorButtons}
                </div>
                <div className={styles.title}>{timelineEvent.title}</div>
              </>
            ) : (
              <div className={styles.top}>
                <div className={styles.title}>{timelineEvent.title}</div>
                {openEditorButtons}
              </div>
            )}
            <div className={styles.description}>{timelineEvent.description}</div>
            <div className={styles.footer}>{timelineEvent.date.toLocaleString()}</div>
          </div>
        </div>
      )}
    </>
  );
}
