import { Reducer, useEffect, useReducer } from "react";
import { RequestStore, RequestStoreKey } from "contexts/requestStore";
import { Api } from "shared/api";
import { JSONObject } from "shared/types";
import { AuthState, AuthStateReducerAction } from "./types";

const authReducer: Reducer<AuthState, AuthStateReducerAction> = (state, action) => {
  switch (action.type) {
    case "StartAuthFlow": {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case "EndAuthFlow": {
      return {
        ...state,
        isAuthenticating: false,
      };
    }
    case "SetTokensAndUser": {
      return {
        ...state,
        isAuthenticating: false,
        authType: action.data.authType,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
        expiresAt: action.data.expiresAt,
        user: action.data.user,
      };
    }
    default:
      return state;
  }
};

export function useAuthStateContextValue(requestStore: RequestStore, api: Api) {
  function getInitialAuthState() {
    const initialAuthState: AuthState = {
      authType: null,
      accessToken: null,
      isAuthenticating: false,
      refreshToken: null,
      expiresAt: null,
      user: null,
    };

    const stored = requestStore.get<JSONObject>(RequestStoreKey.Auth);

    if (stored) {
      const { authType, accessToken, refreshToken, expiresAt, user } = stored;

      Object.assign(initialAuthState, {
        authType,
        accessToken,
        refreshToken,
        expiresAt,
        user,
      });
    }

    return initialAuthState;
  }

  const [state, reducer] = useReducer(authReducer, null, getInitialAuthState);

  useEffect(() => {
    api.applyAuthState(state);
  }, [api, state.accessToken, state.refreshToken, state.expiresAt, state.user]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state, reducer] as const;
}
