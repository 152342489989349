import cx from "classnames";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Button, ButtonSet } from "components/Button";
import { Loader } from "components/Loader";
import { useApi } from "contexts/api";
import { useEvent } from "hooks/useEvent";
import { TimelineItemReference } from "shared/types";
import { TimelineEventReference } from "../TimelineEventReference";
import styles from "./styles.css";

const URL_REGEX = /(?:https?:\/\/)?[^.]+\.[a-z.]+(\/.*)?$/;

interface TimelineEventReferencesProps {
  isEditable: boolean;
  onAdd(ref: TimelineItemReference, url: string): Promise<void>;
  references: TimelineItemReference[] | null;
  setIsDirty(isDirty: boolean): void;
  isLoading?: boolean;
}

export function TimelineEventReferences(props: TimelineEventReferencesProps) {
  const { isEditable, isLoading, onAdd, references, setIsDirty } = props;
  const api = useApi();
  const [url, setUrl] = useState("");
  const [parsed, setParsed] = useState<TimelineItemReference | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isParsing, setIsParsing] = useState(false);
  const disabled = !url || isParsing || !URL_REGEX.test(url);
  const onChangeUrl = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  });

  useEffect(() => {
    setIsDirty(!!parsed);
  }, [parsed, setIsDirty]);

  const onSubmit = useEvent((event: FormEvent) => {
    event.preventDefault();
    setIsParsing(true);
    api.timeline.parseReference(url).then(
      (res) => {
        setParsed(res);
        setIsParsing(false);
      },
      () => {
        setIsParsing(false);
      },
    );
  });

  const confirmReference = useEvent(() => {
    if (!parsed || !url) {
      return;
    }

    setIsCreating(true);
    onAdd(parsed, url).then(
      () => {
        setUrl("");
        setIsCreating(false);
        setParsed(null);
      },
      () => {
        setIsCreating(false);
      },
    );
  });

  const cancel = useEvent(() => {
    setIsCreating(false);
    setParsed(null);
  });

  return (
    <div className={styles.references}>
      {isLoading ? (
        <Loader size={24} />
      ) : (
        references &&
        references.length > 0 && (
          <div className={styles.list}>
            {references.map((reference) => (
              <TimelineEventReference key={reference.id} reference={reference} />
            ))}
          </div>
        )
      )}
      {parsed ? (
        <div className={styles.confirm}>
          <TimelineEventReference className={styles.reference} reference={parsed} />
          <ButtonSet>
            <Button className={styles.btn} onClick={cancel}>
              Cancel
            </Button>
            <Button
              className={styles.btn}
              color="green"
              disabled={disabled}
              isWorking={isCreating}
              onClick={confirmReference}
            >
              {isCreating ? "Loading..." : "Confirm"}
            </Button>
          </ButtonSet>
        </div>
      ) : (
        isEditable && (
          <form className={styles.add} onSubmit={onSubmit}>
            <input
              className={cx(styles.url, isParsing && styles.disabled)}
              disabled={isParsing}
              onChange={onChangeUrl}
              placeholder="Enter a URL here..."
              type="text"
              value={url}
            />
            <Button className={styles.btn} color="green" disabled={disabled} isWorking={isParsing}>
              {isParsing ? "Loading..." : "Add Reference"}
            </Button>
          </form>
        )
      )}
    </div>
  );
}
