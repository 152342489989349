let envInStorage: string | null = null;

try {
  envInStorage = window?.localStorage?.getItem?.("env");
} catch (err) {
  // ignore
}

const env: "development" | "production" =
  ((envInStorage === "development" || envInStorage === "production") && envInStorage) ||
  window.ENV ||
  process.env.NODE_ENV ||
  ("development" as const);

export default env;
