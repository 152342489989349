export function formatTimeCode(time: number): string {
  if (time < 0) {
    return `-${formatTimeCode(time * -1)}`;
  }

  const rounded = Math.round(time);
  const hours = Math.floor(rounded / 3600);
  const minutes = Math.floor(rounded / 60) % 60;
  const seconds = rounded % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [" Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + "" + sizes[i];
}

export function normalizeMime(mime: string) {
  return mime === "video/webm" || mime.startsWith("audio/") ? mime : "video/mp4";
}

export const isMobile = () => {
  const { userAgent } = window.navigator;

  return (
    /\b(iPhone|iP[ao]d)/.test(userAgent) ||
    /\b(iP[ao]d)/.test(userAgent) ||
    /Android/i.test(userAgent) ||
    /Mobile/i.test(userAgent)
  );
};

export function openWindowInCenter(url: string, title: string, w: number, h: number) {
  const mobile = isMobile();
  const screenX = window.screenX != null ? window.screenX : window.screenLeft;
  const screenY = window.screenY != null ? window.screenY : window.screenTop;
  const outerWidth = window.outerWidth != null ? window.outerWidth : document.documentElement.clientWidth;
  const outerHeight = window.outerHeight != null ? window.outerHeight : document.documentElement.clientHeight - 22;
  const targetWidth = mobile ? null : w;
  const targetHeight = mobile ? null : h;
  const v = screenX < 0 ? window.screen.width + screenX : screenX;
  const left = Number(v + (outerWidth - targetWidth) / 2);
  const right = Number(screenY + (outerHeight - targetHeight) / 2.5);
  const features = [];

  if (targetWidth !== null) {
    features.push(`width=${targetWidth}`);
  }

  if (targetHeight !== null) {
    features.push(`height=${targetHeight}`);
  }

  features.push(`left=${left}`);
  features.push(`top=${right}`);
  features.push("scrollbars=1");

  const newWindow = window.open(url, title, features.join(","));

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
