import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconPlay = forwardRef<SVGSVGElement, IconProps>(function IconPlay(props, ref) {
  const { size = 22, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M21.23 12.33L5.2 21.3c-.99.55-2.2-.17-2.2-1.32V2.01A1.49 1.49 0 0 1 5.2.69l16.03 8.98a1.53 1.53 0 0 1 0 2.66" />
    </svg>
  );
});
