import { useEffect } from "react";
import { Loader } from "components/Loader";
import { useApi } from "contexts/api";
import { DiscordAuthComplete, DiscordAuthFailed } from "./hooks";
import styles from "./styles.css";

export function DiscordAuthHandler() {
  const api = useApi();

  useEffect(() => {
    if (!window.opener) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state");

    if (!code || !state) {
      window.opener.postMessage(
        {
          type: DiscordAuthFailed,
          data: "Missing code or state",
          state,
        },
        window.location.origin,
      );
      return;
    }

    api.auth.finalizeDiscordAuth(code).then(
      (res) => {
        if (res.accessToken) {
          window.opener.postMessage(
            {
              type: DiscordAuthComplete,
              data: res,
              state,
            },
            window.location.origin,
          );
        } else {
          window.opener.postMessage(
            {
              type: DiscordAuthFailed,
              data: res,
              state,
            },
            window.location.origin,
          );
        }
      },
      (err) => {
        window.opener.postMessage(
          {
            type: DiscordAuthFailed,
            data: err,
            state,
          },
          window.location.origin,
        );
      },
    );
  }, [api.auth]);

  return (
    <div className={styles.discordAuthHandler}>
      <Loader />
    </div>
  );
}
