import { Dispatch, PropsWithChildren, createContext } from "react";
import { AuthState, AuthStateReducerAction } from "./types";

export const AuthStateContext = createContext<AuthState>(null);
export const AuthDispatchContext = createContext<Dispatch<AuthStateReducerAction>>(null);

type AuthStateProviderProps = PropsWithChildren<{
  dispatcher: Dispatch<AuthStateReducerAction>;
  state: AuthState;
}>;

export function AuthProvider(props: AuthStateProviderProps) {
  const { state, dispatcher, ...otherProps } = props;

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatcher} {...otherProps} />
    </AuthStateContext.Provider>
  );
}
