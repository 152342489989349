import cx from "classnames";
import { MouseEvent, PropsWithChildren, useState } from "react";
import { IconCrossCircle } from "components/Icons";
import { RequestStore, RequestStoreKey, useRequestStore } from "contexts/requestStore";
import { useEvent } from "hooks/useEvent";
import { GodIsPartyingBanner } from "./GodIsPartyingBanner";
import styles from "./styles.css";

enum Banner {
  GodIsPartying = 1,
}

export type BannerProps = PropsWithChildren<{
  className: string;
}>;

function setHasBanner(store: RequestStore, banner: Banner, hasBanner: boolean) {
  const existing = store.get(RequestStoreKey.Banners, []);

  store.set(RequestStoreKey.Banners, hasBanner ? existing.filter((item) => item !== banner) : [...existing, banner]);
}

export function Banners() {
  const requestStore = useRequestStore();
  const [localHasBanner, setLocalHasBanner] = useState<boolean>(
    false, // hasBanner(requestStore, Banner.GodIsPartying)
  );
  const [isHoveringDismiss, setIsHoveringDismiss] = useState(false);
  const onMouseEnterDismiss = useEvent(() => {
    setIsHoveringDismiss(true);
  });

  const onMouseLeaveDismiss = useEvent(() => {
    setIsHoveringDismiss(false);
  });

  const onClickDismiss = useEvent((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setLocalHasBanner(false);
    setHasBanner(requestStore, Banner.GodIsPartying, false);
  });

  if (!localHasBanner) {
    return null;
  }

  return (
    <GodIsPartyingBanner className={cx(styles.banner, isHoveringDismiss && styles.isHoveringDismiss)}>
      <div
        className={styles.dismiss}
        onMouseEnter={onMouseEnterDismiss}
        onMouseLeave={onMouseLeaveDismiss}
        onClick={onClickDismiss}
      >
        <IconCrossCircle />
      </div>
      <div className={styles.dismissOverlay} />
    </GodIsPartyingBanner>
  );
}
