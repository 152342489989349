import cx from "classnames";
import gradient from "random-gradient";
import { useMemo } from "react";
import styles from "./styles.css";

export interface CollectionPreviewProps {
  id: string;
  className?: string;
  hasGradient?: boolean;
}

export function CollectionPreview(props: CollectionPreviewProps) {
  const { className, hasGradient = true, id } = props;
  const style = useMemo(
    () =>
      hasGradient && id
        ? {
            backgroundImage: gradient(id, "diagonal"),
          }
        : undefined,
    [hasGradient, id],
  );

  return (
    <div className={cx(styles.component, className)} style={style}>
      {/* <div className={styles.count}>{str}</div> */}
      {hasGradient && <div className={styles.gradient} />}
    </div>
  );
}
