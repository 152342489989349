import { memo } from "react";
import { LeafOCR } from "shared/types";
import styles from "./styles.css";

interface FilePreviewImageOCRBoxesProps {
  boxes: LeafOCR["boxes"];
  ratio: [number, number];
}

function BaseFilePreviewImageOCRBoxes(props: FilePreviewImageOCRBoxesProps) {
  const { boxes, ratio } = props;

  return (
    <>
      {boxes.map(
        (box, i) =>
          i > 0 && (
            <div
              className={styles.box}
              key={i}
              data-text={box[8]}
              style={{
                transform: `translate3d(${box[0] * ratio[0]}px, ${box[1] * ratio[1]}px, 0)`,
                width: (box[4] - box[0]) * ratio[0],
                height: (box[5] - box[1]) * ratio[1],
                zIndex: i,
              }}
            >
              <div className={styles.tip}>{box[8]}</div>
            </div>
          ),
      )}
    </>
  );
}

export const FilePreviewImageOCRBoxes = memo(BaseFilePreviewImageOCRBoxes);
