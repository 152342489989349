import cx from "classnames";
import { ChangeEvent, DragEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FileWithID, ShareFileItem, getAllFileEntries, uploadFiles } from "components/Share";
import { useApi } from "contexts/api";
import { useEvent } from "hooks/useEvent";
import { File as AppFile } from "shared/types";
import { formatBytes } from "shared/utils";
import styles from "./styles.css";

// TODO: REMOVE GLOBAL STATE
// TODO: SWITCH TO NEW UPLOADER CONTEXT API

let FILE_ID = 0;
let _sharedFiles: FileWithID[] = [];
let _sharedUpload: ReturnType<typeof uploadFiles> | null = null;

const addFileID = (file: File): FileWithID => {
  (file as FileWithID).id = ++FILE_ID;
  return file;
};

interface LeafsBrowserUploadProps {
  accept?: string;
  allowMultiple?: boolean;
  parentID?: string;
  confirm?: () => Promise<boolean>;
  onUploaded: (files: AppFile[]) => void;
  persistUploads?: boolean;
}

export function LeafsBrowserUpload(props: LeafsBrowserUploadProps) {
  const { accept, allowMultiple, parentID, confirm, onUploaded, persistUploads } = props;
  const api = useApi();
  const [quota, setQuota] = useState<number | null>(null);
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState(_sharedFiles);
  const onChangeTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => setTitle(event.currentTarget.value), []);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dropping, setDropping] = useState<DataTransferItemList | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(!!_sharedUpload);
  const [uploadProgress, setUploadProgress] = useState(_sharedUpload ? _sharedUpload.progress : 0);
  const [error, setError] = useState(null);
  const dragEntered = useRef(0);
  const progressRef = useRef(null);
  const onDragOver = useEvent((event: DragEvent) => event.preventDefault());
  const quotaExceeded = useMemo(() => quota && files.reduce((out, file) => out + file.size, 0) > quota, [quota, files]);

  const onDragEnter = useEvent((event: DragEvent) => {
    event.preventDefault();
    dragEntered.current++;
    setDropping(event.dataTransfer.items);
  });

  const onDragLeave = useEvent((event: DragEvent) => {
    event.preventDefault();

    dragEntered.current--;

    if (dragEntered.current === 0) {
      setDropping(null);
    }
  });

  const onDrop = useEvent((event: DragEvent) => {
    event.preventDefault();

    getAllFileEntries(event.dataTransfer.items, accept).then((res) => {
      const newSharedFiles = [...files, ...res.files.map(addFileID)];

      if (newSharedFiles.length === 1 || allowMultiple) {
        _sharedFiles = newSharedFiles;
        setFiles(_sharedFiles);
      }
    });

    setDropping(null);
  });

  const openFileBrowser = useEvent(() => {
    fileInputRef.current?.click();
  });

  const onChangeFileInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFiles = Array.from(event.currentTarget.files || []).map(addFileID);

      _sharedFiles = [...files, ...newFiles];
      setFiles(_sharedFiles);
      event.currentTarget.value = "";
    },
    [files],
  );

  const removeFile = useEvent((id: number) => {
    _sharedFiles = files.filter((file) => file.id !== id);
    setFiles(_sharedFiles);
  });

  const removeUploadProgress = useRef<(() => void) | null>(null);

  const startUpload = useEvent(async () => {
    setError(null);

    if (confirm) {
      const allowed = await confirm();

      if (!allowed) {
        return;
      }
    }

    setIsUploading(true);

    if (!_sharedUpload) {
      _sharedUpload = uploadFiles(api, files, title, null, parentID, persistUploads);

      removeUploadProgress.current = _sharedUpload.onProgress(setUploadProgress);

      _sharedUpload.then(
        (files) => {
          removeUploadProgress.current = null;
          _sharedUpload = null;
          _sharedFiles = [];
          onUploaded(Array.isArray(files) ? files : [files]);
        },
        (err) => {
          removeUploadProgress.current = null;
          _sharedUpload = null;
          setIsUploading(false);
          setUploadProgress(0);
          setError(err);
        },
      );
    }
  });

  useEffect(() => {
    if (_sharedUpload) {
      removeUploadProgress.current = _sharedUpload.onProgress(setUploadProgress);
    }

    api.quota.get().then(setQuota);

    return () => {
      if (removeUploadProgress.current) {
        removeUploadProgress.current();
        removeUploadProgress.current = null;
      }
    };
  }, [api.quota]);

  return (
    <div
      className={cx(
        styles.component,
        files.length === 0 && styles.isPending,
        files.length === 0 && dropping && styles.isDropping,
      )}
    >
      {!isUploading && (
        <input
          className={styles.fileInput}
          onChange={onChangeFileInput}
          ref={fileInputRef}
          type="file"
          accept={accept ? `${accept}*` : undefined}
          multiple={allowMultiple}
        />
      )}
      {files.length > 1 && (
        <div className={styles.row}>
          <label className={styles.label} htmlFor="title">
            Title
          </label>
          <input
            className={styles.input}
            disabled={isUploading}
            name="title"
            type="text"
            onChange={onChangeTitle}
            placeholder={`Type a name for this ${
              files.length === 1 ? "file" : "collection of files"
            } here (optional)...`}
            value={title}
            autoFocus={true}
          />
        </div>
      )}
      <div
        className={cx(styles.filesRow, dropping && styles.isDropping)}
        onClick={files.length === 0 ? openFileBrowser : undefined}
        onDragEnter={isUploading ? undefined : onDragEnter}
        onDragOver={isUploading ? undefined : onDragOver}
        onDragLeave={isUploading ? undefined : onDragLeave}
        onDrop={isUploading ? undefined : onDrop}
      >
        {files.length > 0 ? (
          <div className={cx(styles.row, styles.files, dropping && styles.isDropping)}>
            {allowMultiple && (
              <div className={styles.title}>
                File{files.length > 1 ? "s" : ""}
                {!isUploading && (
                  <button className={styles.action} onClick={openFileBrowser}>
                    + Add more files
                  </button>
                )}
              </div>
            )}
            <div className={styles.items}>
              {dropping && (
                <div className={styles.dropping}>
                  Drop {dropping.length} file{dropping.length > 1 ? "s" : ""} here
                </div>
              )}
              {files.map((file) => (
                <ShareFileItem key={file.id} file={file} removeFile={removeFile} disabled={isUploading} />
              ))}
            </div>
          </div>
        ) : (
          <div className={cx(styles.prompt, dropping && styles.isDropping)}>
            <div className={styles.title}>
              {dropping
                ? dropping.length === 1 || allowMultiple
                  ? `Drop ${dropping.length} file${dropping.length > 1 ? "s" : ""} here to upload`
                  : "You cannot drop multiple files"
                : "Click here or drop a file"}
            </div>
            <div className={styles.terms}>
              Only Andrew W.K. related media will be archived, anything unrelated will be promptly removed.
            </div>
            <div className={styles.quota}>
              ({quota ? `${formatBytes(quota)} quota remaining` : "Fetching quota..."})
            </div>
          </div>
        )}
      </div>
      {error && <div className={styles.error}>Oops! An error occurred during upload. Please try again later.</div>}
      {files.length > 0 && (
        <div className={cx(styles.row, styles.submit)}>
          {isUploading ? (
            <div className={styles.progress} ref={progressRef}>
              <div className={styles.text}>
                {uploadProgress === 100 ? "Processing..." : `${uploadProgress}% uploaded`}
              </div>
              <div className={styles.progressInner} style={{ width: `${uploadProgress}%` }} />
            </div>
          ) : (
            <div className={styles.warn}>Remember — all uploads are public!</div>
          )}
          <button
            className={cx(styles.upload, (isUploading || quotaExceeded) && styles.isUploading)}
            onClick={startUpload}
          >
            {isUploading ? "Uploading..." : quotaExceeded ? "Upload exceeds quota" : "Upload"}
          </button>
        </div>
      )}
    </div>
  );
}
