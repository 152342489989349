import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconSearch = forwardRef<SVGSVGElement, IconProps>(function IconSearch(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} ref={ref} fill="none" viewBox="0 0 24 24" {...otherProps}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
      />
    </svg>
  );
});
