import { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { GodIsPartying } from "components/GodIsPartying";
import { ScrubberProvider, createScrubber } from "components/Player";
import { Scrubber } from "components/Player/hooks/useScrubber/createScrubber";
import { UploaderProvider, useUploaderContextValue } from "components/Share";
import { ApiContext } from "contexts/api";
import { Comments, CommentsContext, createComments } from "contexts/comments";
import { Env, EnvContext, createEnv } from "contexts/env";
import { Events, EventsContext, createEvents } from "contexts/events";
import { RequestStore, RequestStoreContext, RequestStoreState, createRequestStore } from "contexts/requestStore";
import { useScrollContainerProvider } from "hooks/useScrollContainer";
import createAPI, { Api } from "shared/api";
import { Cache, CacheStruct, createCache } from "shared/cache";
import { AppInner } from "../AppInner";
import { AuthProvider, DiscordAuthHandler, useAuthStateContextValue } from "../Auth";
import styles from "./styles.css";

interface AppProps {
  env?: Env;
  cache?: CacheStruct;
  requestStore?: RequestStoreState;
}

export function App(props: AppProps) {
  const { cache: cacheProp, env: envProp, requestStore: requestStoreProp } = props;
  const env = useRef<Env | null>(null);
  const cache = useRef<Cache | null>(null);
  const api = useRef<Api | null>(null);
  const scrubber = useRef<Scrubber | null>(null);
  const events = useRef<Events | null>(null);
  const comments = useRef<Comments | null>(null);
  const requestStore = useRef<RequestStore | null>(null);
  const withScrollProvider = useScrollContainerProvider();

  if (!env.current) {
    env.current = envProp || createEnv();
  }

  if (!cache.current) {
    cache.current = createCache(cacheProp);
  }

  if (!requestStore.current) {
    requestStore.current = createRequestStore(env.current, requestStoreProp);
  }

  if (!api.current) {
    api.current = createAPI({
      cache: cache.current,
      requestStore: requestStore.current,
    });
  }

  if (!scrubber.current) {
    scrubber.current = createScrubber();
  }

  if (!events.current) {
    events.current = createEvents();
  }

  if (!comments.current) {
    comments.current = createComments({
      api: api.current,
      events: events.current,
    });
  }

  const [auth, dispatchAuth] = useAuthStateContextValue(requestStore.current, api.current);
  const [uploaderElement, uploaderAPI] = useUploaderContextValue({
    api: api.current,
  });

  return (
    <EnvContext.Provider value={env.current}>
      <RequestStoreContext.Provider value={requestStore.current}>
        <ApiContext.Provider value={api.current}>
          <AuthProvider state={auth} dispatcher={dispatchAuth}>
            <ScrubberProvider scrubber={scrubber.current}>
              <EventsContext.Provider value={events.current}>
                <CommentsContext.Provider value={comments.current}>
                  <UploaderProvider value={uploaderAPI}>
                    {withScrollProvider(
                      <div className={styles.component}>
                        {env.current.origin === "https://godispartying.com" ? (
                          <GodIsPartying />
                        ) : (
                          <Routes>
                            <Route path="/discord/redirect" element={<DiscordAuthHandler />} />
                            <Route path="/god-is-partying" element={<GodIsPartying />} />
                            <Route path="/*" element={<AppInner />} />
                          </Routes>
                        )}
                        <div className={styles.portals} />
                        {uploaderElement}
                      </div>,
                    )}
                  </UploaderProvider>
                </CommentsContext.Provider>
              </EventsContext.Provider>
            </ScrubberProvider>
          </AuthProvider>
        </ApiContext.Provider>
      </RequestStoreContext.Provider>
    </EnvContext.Provider>
  );
}
