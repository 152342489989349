import { useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonIcon, ButtonLabel } from "components/Button";
import { IconDownload } from "components/Icons";
import { useApi } from "contexts/api";
import { Archive } from "shared/types";
import styles from "./styles.css";

interface BulkDownloadProps {
  parentID: string;
}

export function BulkDownload(props: BulkDownloadProps) {
  const { parentID } = props;
  const api = useApi();
  const isWorking = useRef<boolean | string>(false);
  const workTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [progress, setProgress] = useState<boolean | number>(false);
  const download = useCallback(() => {
    if (isWorking.current) {
      return;
    }

    setProgress(true);

    const checkProgress = () => {
      api.archive.get(isWorking.current as string).then(handleState, () => {
        isWorking.current = false;
        setProgress(false);
      });
    };

    const handleState = (archive: Archive) => {
      if (!archive.id) {
        isWorking.current = false;
        setProgress(false);
        return;
      }

      if (archive.ready) {
        isWorking.current = false;
        window.open(archive.url);
        setProgress(false);
        return;
      }

      setProgress(archive.progress);

      isWorking.current = archive.id;
      workTimer.current = setTimeout(checkProgress, 2000);
    };

    api.collection.archive(parentID).then(
      (res) => {
        if (res) {
          handleState(res);
          return;
        }

        isWorking.current = false;
        setProgress(false);
      },
      () => {
        isWorking.current = false;
        setProgress(false);
      },
    );
  }, [api.archive, api.collection, parentID]);

  useEffect(() => {
    return () => {
      if (workTimer.current) {
        clearTimeout(workTimer.current);
      }
    };
  }, []);

  return (
    <Button onClick={progress === false ? download : undefined} isWorking={progress !== false}>
      {progress === false ? (
        <>
          <ButtonLabel>
            Download<span className={styles.hideSmall}>&nbsp;ZIP</span>&nbsp;
          </ButtonLabel>
          <ButtonIcon>
            <IconDownload size={17} />
          </ButtonIcon>
        </>
      ) : (
        <>
          <span>Zipping... </span>
          <span>{`${typeof progress === "number" ? progress : 0}%`}</span>
        </>
      )}
    </Button>
  );
}
