import cx from "classnames";
import { IconExternal } from "components/Icons";
import { File } from "shared/types";
import styles from "./styles.css";

export type PartyViewerTrackState = "QUEUED" | "PLAYING";

export interface PartyViewerTrackProps {
  currentTime?: number;
  file: File;
  start?: number;
  state: PartyViewerTrackState;
}

export function PartyViewerTrack(props: PartyViewerTrackProps) {
  const { file, start, state } = props;

  return (
    <div className={cx(styles.file, state === "PLAYING" && styles.playing)}>
      <div className={styles.status}>
        {start == null || file.duration == null
          ? null
          : state === "QUEUED"
          ? new Date(start).toLocaleTimeString()
          : new Date(Math.ceil(file.duration - (Date.now() - start) / 1000) * 1000).toISOString().slice(11, 19)}
      </div>
      <div className={styles.name}>{file.name}</div>
      <a className={styles.link} href={`/files/${file.id}`} target="_blank" rel="noreferrer">
        <IconExternal />
      </a>
    </div>
  );
}
