import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconPen = forwardRef<SVGSVGElement, IconProps>(function IconPen(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M11.333 8.701l-7.837 4.262s-1.339 5.254-3.497 8.604l2.419 2.433c3.314-2.133 8.604-3.511 8.604-3.511l4.263-7.837-3.952-3.951zm-1.664 10.091c-1.241.365-3.64 1.131-5.915 2.207l1.806-1.806c.348-.349.8-.569 1.288-.63.647-.081 1.113-.63 1.113-1.263 0-.703-.569-1.275-1.275-1.275-.637 0-1.183.471-1.263 1.113-.06.487-.281.94-.629 1.288l-1.779 1.779c1.072-2.274 1.825-4.652 2.184-5.891l5.778-3.143 1.838 1.837-3.146 5.784zm2.345-12.252s2.148-3.969 3.475-6.54l8.511 8.511c-2.582 1.321-6.556 3.459-6.556 3.459l-5.43-5.43z" />
    </svg>
  );
});
