import { useMemo } from "react";
import { LeafsList, LeafsListProps, LeafsMode } from "components/LeafsList";
import { Sort } from "shared/leafSort";
import { FileType } from "shared/types";
import { useLeafs } from "./hooks";

export interface FilesProps extends Pick<LeafsListProps, "HeaderComponent" | "leafsClassName"> {
  fixedHeader?: boolean;
  leafsMode?: LeafsMode;
  typeFilter?: FileType | null;
}

export function Files(props: FilesProps) {
  const { leafsClassName, HeaderComponent, leafsMode = LeafsMode.Manual, fixedHeader, typeFilter } = props;
  const leafs = useLeafs({ leafsMode, typeFilter });
  const fileTypeWhitelist = useMemo(() => (typeFilter ? [typeFilter] : null), [typeFilter]);
  const [sort, isAscending] = useMemo<[Sort | false | undefined, boolean | undefined]>(() => {
    switch (leafsMode) {
      default:
        return [false, undefined];
      case LeafsMode.Manual: {
        return [undefined, undefined];
      }
      case LeafsMode.Oldest: {
        return [Sort.Date, true];
      }
    }
  }, [leafsMode]);

  return (
    <LeafsList
      leafsClassName={leafsClassName}
      fileTypeWhitelist={fileTypeWhitelist}
      fixedHeader={fixedHeader}
      HeaderComponent={HeaderComponent}
      isAscending={isAscending}
      isLoading={!leafs && leafsMode === LeafsMode.Manual}
      leafs={leafs}
      leafsMode={leafsMode}
      saveSort={leafsMode === LeafsMode.Manual}
      sort={sort}
    />
  );
}
