import cx from "classnames";
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.css";

export type SmoothImageProps = PropsWithChildren<{
  alt?: string;
  className?: string;
  loadedClassName?: string;
  loadedColorClassName?: string;
  offset?: string | number;
  opacity?: number;
  color?: string;
  url:
    | string
    | {
        url: string;
        type: string;
      }[];
}>;

export function SmoothImage(props: SmoothImageProps) {
  const {
    alt,
    className,
    children: fallback,
    color,
    loadedClassName,
    loadedColorClassName,
    offset,
    opacity: baseOpacity = 0.25,
    url,
  } = props;
  const ref = useRef<HTMLImageElement>(null);
  const colorRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<true | null>(null);
  const hasLoaded = useRef(false);
  const onLoaded = useCallback(() => {
    // loaded.add(url);
    hasLoaded.current = true;

    // This is a little gross but we're intentionally side stepping React to try
    // and update the UI a little nicer when a bunch of images load in at once.
    requestAnimationFrame(() => {
      if (!ref.current) {
        return;
      }

      if (loadedClassName) {
        ref.current.classList.add(loadedClassName);
      }

      if (ref.current.tagName === "PICTURE") {
        ref.current.childNodes.forEach((node: HTMLElement) => {
          node.style.opacity = `${baseOpacity}`;
        });
      } else {
        ref.current.style.opacity = `${baseOpacity}`;
      }

      if (colorRef.current) {
        colorRef.current.style.opacity = "0";
      }
    });
  }, [baseOpacity, colorRef, loadedClassName, ref]);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  useEffect(() => {
    if (
      (!hasLoaded.current && ref.current?.naturalHeight) ||
      (ref.current && Array.from(ref.current?.childNodes).some((item) => "naturalHeight" in item && item.naturalHeight))
    ) {
      // Image has already loaded.
      onLoaded();
    }
  }, [onLoaded]);

  useEffect(() => {
    setError(null);
  }, [url]);

  const opacity = hasLoaded.current && baseOpacity;

  return (
    <>
      {error ? (
        fallback
      ) : typeof url === "string" ? (
        <img
          alt={alt}
          key={url}
          className={cx(styles.component, className, hasLoaded.current && loadedClassName)}
          onError={onError}
          onLoad={onLoaded}
          ref={ref}
          role={alt ? undefined : "presentation"}
          src={url}
          style={
            offset != null
              ? {
                  opacity: `${opacity}`,
                  objectPosition: `0 ${typeof offset === "string" ? offset : `${offset}px`}`,
                }
              : undefined
          }
          loading="lazy"
        />
      ) : (
        <picture className={styles.picture} onLoad={onLoaded} ref={ref}>
          {url.map(({ type, url }) =>
            type === "image/webp" ? (
              <source
                key={url}
                className={cx(styles.component, className, hasLoaded.current && loadedClassName)}
                style={
                  offset != null
                    ? {
                        opacity: `${opacity}`,
                        objectPosition: `0 ${typeof offset === "string" ? offset : `${offset}px`}`,
                      }
                    : undefined
                }
                role={alt ? undefined : "presentation"}
                type={type}
                srcSet={url}
              />
            ) : (
              <img
                key={url}
                className={cx(styles.component, className, hasLoaded.current && loadedClassName)}
                style={
                  offset != null
                    ? {
                        opacity: `${opacity}`,
                        objectPosition: `0 ${typeof offset === "string" ? offset : `${offset}px`}`,
                      }
                    : undefined
                }
                role={alt ? undefined : "presentation"}
                src={url}
                loading="lazy"
              />
            ),
          )}
        </picture>
      )}
      {color && (
        <div
          className={cx(styles.color, loadedColorClassName)}
          ref={colorRef}
          style={{ backgroundColor: color, opacity: hasLoaded.current ? 0 : 1 }}
        />
      )}
    </>
  );
}
