const IGNORE_NAMES = [".DS_Store", "Thumbs.db", "desktop.ini"];

type Entry = FileSystemEntry | FileSystemFileEntry | FileSystemDirectoryEntry;

async function readAllDirectoryEntries(directoryReader: FileSystemDirectoryReader) {
  const entries = [];

  let readEntries = await readEntriesPromise(directoryReader);

  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }

  return entries;
}

// Wrap readEntries in a promise to make working with readEntries easier
async function readEntriesPromise(directoryReader: FileSystemDirectoryReader): Promise<Entry[]> {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
}

export async function getAllFileEntries(dataTransferItemList: DataTransferItemList, typeFilter?: string) {
  const fileEntries: FileSystemFileEntry[] = [];
  const queue: Entry[] = [];

  let initialName;

  for (let i = 0; i < dataTransferItemList.length; i++) {
    if (!typeFilter || !dataTransferItemList[i].type || dataTransferItemList[i].type.startsWith(typeFilter)) {
      queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
  }

  if (queue.length === 1 && !queue[0].isFile) {
    initialName = queue[0].name;
  }

  while (queue.length > 0) {
    const entry = queue.shift();

    if (entry.isFile && !IGNORE_NAMES.includes(entry.name)) {
      fileEntries.push(entry as FileSystemFileEntry);
    } else if (entry.isDirectory && "createReader" in entry) {
      const reader = entry.createReader();
      queue.push(...(await readAllDirectoryEntries(reader)));
    }
  }

  return {
    files: await mapReadEntries(fileEntries),
    initialName,
  };
}

const mapReadEntries = (entries: FileSystemFileEntry[]) =>
  Promise.all(
    entries.map(
      (entry) =>
        new Promise<File>((resolve, reject) => {
          entry.file(resolve, reject);
        }),
    ),
  );
