import cx from "classnames";
import TimeAgo from "react-timeago"; // cSpell:ignore timeago
import { Comment as CommentType } from "shared/types";
import { CommentAvatar } from "../CommentAvatar";
import styles from "./styles.css";

export interface CommentProps extends CommentType {
  hideDates?: boolean;
}

export function Comment(props: CommentProps) {
  const { body, hideDates, createdAt, name, tripCode } = props;

  // if (hideDates) {
  //   return (
  //     <div className={cx(styles.comment, styles.smallComment)}>
  //       <Avatar input={tripcode || name} />
  //       <div className={styles.right}>
  //         <div className={styles.user}>
  //           <div className={styles.name}>
  //             {name}
  //           </div>
  //           {tripcode && (
  //             <div className={styles.tripcode}>
  //               {tripcode}
  //             </div>
  //           )}
  //         </div>
  //         <div className={styles.body}>
  //           {body}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className={cx(styles.component, hideDates && styles.smallComment)}>
      <div className={styles.top}>
        <CommentAvatar input={tripCode || name} />
        <div className={styles.right}>
          <div className={styles.user}>
            <div className={styles.name}>{name}</div>
            {tripCode && <div className={styles.tripCode}>{tripCode}</div>}
          </div>
          {!hideDates && (
            <div className={styles.date}>
              Posted <TimeAgo date={createdAt} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.body}>{body}</div>
    </div>
  );
}
