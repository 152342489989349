import { useCallback } from "react";
import { useValueAsRef } from "./useValueAsRef";

// TRACK: https://github.com/reactjs/rfcs/pull/220
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEvent<T extends (...args: any[]) => any>(fn: T) {
  const ref = useValueAsRef(fn);

  return useCallback(
    (...args: Parameters<T>) => {
      return ref.current(...args);
    },
    [ref],
  ) as T;
}
