import { PropsWithChildren, Reducer, createContext, useContext, useEffect, useReducer, useRef } from "react";
import { useEvent } from "hooks/useEvent";
import type { Api } from "shared/api";
import styles from "./styles.css";
import { UploadOp, uploadFiles } from "./utils";

export type FileWithID = File & { id?: number };

type Uploader = ReturnType<typeof useUploaderContextValue>[1];
interface UploaderState {
  isUploading: boolean;
  description: string | null;
  files: FileWithID[];
  quota: number | null;
  title: string | null;
}

interface AddFilesAction {
  type: "addFiles";
  payload: FileWithID[];
}

interface RemoveFileAction {
  type: "removeFile";
  payload: number;
}

interface SetQuotaAction {
  type: "setQuota";
  payload: number;
}

interface SetTitleAction {
  type: "setTitle";
  payload: string;
}

interface SetDescriptionAction {
  type: "setDescription";
  payload: string;
}

interface ResetAction {
  type: "reset";
}

interface SetIsUploadingAction {
  type: "setIsUploading";
  payload: boolean;
}

type UploaderAction =
  | AddFilesAction
  | RemoveFileAction
  | ResetAction
  | SetQuotaAction
  | SetTitleAction
  | SetDescriptionAction
  | SetIsUploadingAction;

const initialState: UploaderState = {
  isUploading: false,
  description: "",
  files: [],
  quota: null,
  title: "",
};

const reducer: Reducer<UploaderState, UploaderAction> = (state, action) => {
  switch (action.type) {
    case "addFiles": {
      return { ...state, files: [...state.files, ...action.payload] };
    }
    case "removeFile": {
      return { ...state, files: state.files.filter((file) => file.id !== action.payload) };
    }
    case "setQuota":
      return { ...state, quota: action.payload };
    case "setTitle":
      return { ...state, title: action.payload };
    case "setDescription":
      return { ...state, description: action.payload };
    case "setIsUploading":
      return { ...state, isUploading: action.payload };
    case "reset":
      return {
        ...initialState,
        quota: state.quota,
      };
    default:
      return state;
  }
};

export const UploaderContext = createContext<Uploader | null>(null);

export function useUploaderContextValue({ api }: { api: Api }) {
  const uploaderElementRef = useRef<HTMLInputElement | null>(null);
  const fileIDRef = useRef(0);
  const uploadOpRef = useRef<UploadOp | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const addFileID = useEvent((file: FileWithID) => {
    file.id = ++fileIDRef.current;
    return file;
  });

  const addFiles = useEvent((files: File[]) =>
    dispatch({
      type: "addFiles",
      payload: files.map(addFileID),
    }),
  );

  const removeFile = useEvent((id: number) =>
    dispatch({
      type: "removeFile",
      payload: id,
    }),
  );

  const resetState = useEvent(() =>
    dispatch({
      type: "reset",
    }),
  );

  const openFileBrowser = useEvent(() => {
    uploaderElementRef.current?.click();
  });

  const setTitle = useEvent((title: string) =>
    dispatch({
      type: "setTitle",
      payload: title,
    }),
  );

  const setDescription = useEvent((description: string) => {
    dispatch({
      type: "setDescription",
      payload: description,
    });
  });

  const start = useEvent(() => {
    if (uploadOpRef.current) {
      return;
    }

    uploadOpRef.current = uploadFiles(
      api,
      state.files,
      state.title,
      state.description,
      undefined,
      !/(\?|&)persist=0($|&)/.test(window.location.search),
    );

    dispatch({
      type: "setIsUploading",
      payload: true,
    });

    uploadOpRef.current.then(
      () => {
        resetState();
        uploadOpRef.current = null;
      },
      () => {
        dispatch({
          type: "setIsUploading",
          payload: false,
        });

        uploadOpRef.current = null;
      },
    );

    return uploadOpRef.current;
  });

  useEffect(() => {
    api.quota.get().then(
      (quota) =>
        quota != null &&
        dispatch({
          type: "setQuota",
          payload: quota,
        }),
    );
  }, [api.quota]);

  const element = (
    <input
      type="file"
      multiple={true}
      ref={uploaderElementRef}
      className={styles.input}
      tabIndex={-1}
      onChange={(event) => {
        const newFiles = Array.from(event.currentTarget.files || []).map(addFileID);
        addFiles(newFiles);
        event.currentTarget.value = "";
      }}
    />
  );

  return [
    element,
    {
      addFiles,
      resetState,
      removeFile,
      getTitle: () => state.title,
      getDescription: () => state.description,
      setTitle,
      setDescription,
      start,
      openFileBrowser,
      getFileInput: () => uploaderElementRef.current,
      getOp: () => uploadOpRef.current,
      getQuota: () => state.quota,
      getFiles: () => state.files,
      getUploadProgress: () => uploadOpRef.current?.progress || 0,
      isUploading: () => state.isUploading,
      isQuotaExceeded: () => {
        return Boolean(state.quota && state.files.reduce((out, file) => out + file.size, 0) > state.quota);
      },
      state,
    },
  ] as const;
}

export function useUploaderContext() {
  return useContext(UploaderContext);
}

type UploaderProviderProps = PropsWithChildren<{
  value: Uploader;
}>;

export function UploaderProvider(props: UploaderProviderProps) {
  return <UploaderContext.Provider {...props} />;
}
