import { ChangeEvent, MutableRefObject, useState } from "react";
import { Button } from "components/Button";
import { useApi } from "contexts/api";
import { useEvent } from "hooks/useEvent";
import type { Api } from "shared/api";
import { TimelineItemLeafReferenceData } from "shared/types";
import { BoxedText } from "../BoxedText";
import styles from "./styles.css";
import type { PartialTimelineEvent, Step } from "./TimelineCreator";

const URL_REGEX = /(?:https?:\/\/)?[^.]+\.[a-z.]+(\/.*)?$/;

async function waitForThumbnailBackup(api: Api, refID: string) {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const ref = await api.timeline.getReference(refID);

    if (ref?.leafs) {
      const file = ref.leafs.find((leaf) => leaf?.role === "THUMBNAIL_BACKUP");

      if (file) {
        return file.leaf.id;
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 2000));
  }
}

interface TimelineCreatorURLStepProps {
  timelineEventRef: MutableRefObject<PartialTimelineEvent | null>;
  initialUrl: string | null;
  setStep: (step: Step) => void;
}

export function TimelineCreatorURLStep(props: TimelineCreatorURLStepProps) {
  const { timelineEventRef, initialUrl, setStep } = props;
  const api = useApi();
  const [url, setUrl] = useState(initialUrl || "");
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(false);
  const goBack = useEvent(() => setStep([0, null]));
  const submit = useEvent(() => {
    setIsLoading(true);
    api.timeline.createReference(url).then(
      async (res) => {
        if (!res) {
          setIsLoading(false);
          return;
        }

        const thumb = "thumbnailURL" in res.data && res.data.thumbnailURL;

        let imageLeafID: string | null = null;

        if (thumb) {
          if (res.type === "LEAF") {
            imageLeafID = (res.data as TimelineItemLeafReferenceData).leafID;
          } else {
            const file = res.leafs?.find((leaf) => leaf?.role === "THUMBNAIL_BACKUP");

            if (file) {
              imageLeafID = file.leaf.id;
            } else if (res.id) {
              imageLeafID = await waitForThumbnailBackup(api, res.id);
            }
          }
        }

        if (timelineEventRef.current) {
          Object.assign(timelineEventRef.current, {
            date: new Date(res.data.date),
            description: res.data.description,
            imageLeafID,
            referenceIDs: [res.id],
            tags: res.data.title.match(/interview/i) ? ["INTERVIEW"] : [],
            title: res.data.title,
          });
        }

        setStep([
          2,
          {
            back: [1, { url }],
          },
        ]);
      },
      () => {
        setIsLoading(false);
      },
    );
  });

  const onChangeUrl = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  });

  const disabled = isLoading || !url.trim() || !url.match(URL_REGEX);

  return (
    <BoxedText borderClassName={styles.border} className={styles.urlStep}>
      <div className={styles.field}>
        <label className={styles.label} htmlFor="url">
          URL
        </label>
        <input
          className={styles.input}
          placeholder="Enter a URL..."
          id="url"
          type="text"
          onChange={onChangeUrl}
          value={url}
          autoFocus={true}
        />
      </div>
      <div className={styles.buttons}>
        <Button disabled={isLoading} onClick={goBack}>
          Back
        </Button>
        <Button onClick={submit} disabled={disabled} isWorking={isLoading}>
          {isLoading ? "Loading..." : "Continue"}
        </Button>
      </div>
    </BoxedText>
  );
}
