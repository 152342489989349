import { PropsWithChildren } from "react";
import styles from "./styles.css";

type ButtonIconProps = PropsWithChildren;

export function ButtonIcon(props: ButtonIconProps) {
  const { children } = props;

  return <span className={styles.icon}>{children}</span>;
}
