import cx from "classnames";
import { HTMLAttributes, ReactElement, ReactNode } from "react";
import { SmoothImage } from "components/Smooth";
import { SmoothImageProps } from "components/Smooth/SmoothImage";
import styles from "./styles.css";

export interface CardProps extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  actions?: {
    label: string;
    className?: string;
    onClick?(): void;
    suffix?: ReactElement;
    url?: string;
  }[];
  backgroundImageProps?: SmoothImageProps;
  title?: ReactNode;
  actionsPosition?: "bottom" | "top";
}

export function Card(props: CardProps) {
  const {
    actions,
    actionsPosition = "bottom",
    backgroundImageProps,
    children,
    className,
    title,
    ...otherProps
  } = props;

  const actionsElement = actions && (
    <>
      <div className={styles.cardActions}>
        {actions.map((action, idx) => {
          const Component = action.url ? "a" : "button";

          return (
            <div className={styles.cardActionWrapper} key={idx}>
              <Component
                className={cx(styles.cardAction, action.className)}
                onClick={action.onClick}
                {...(action.url
                  ? {
                      href: action.url,
                      rel: "noopener noreferrer",
                      target: "_blank",
                    }
                  : null)}
              >
                {action.label}
              </Component>
              {action.suffix && <div className={styles.cardActionSuffix}>{action.suffix}</div>}
            </div>
          );
        })}
      </div>
    </>
  );

  return (
    <div className={cx(styles.component, className)} {...otherProps}>
      <div className={styles.cardInner}>
        {actionsPosition === "top" && actionsElement}
        {title && <div className={styles.cardTitle}>{title}</div>}
        {children && <div className={styles.cardBody}>{children}</div>}
        {actionsPosition === "bottom" && actionsElement}
      </div>
      {backgroundImageProps && <SmoothImage className={styles.cardBg} opacity={0.35} {...backgroundImageProps} />}
    </div>
  );
}
