import cx from "classnames";
import { CSSProperties, MouseEvent, RefObject, useEffect, useRef, useState } from "react";
import { useEvent } from "hooks/useEvent";
import { FileType } from "shared/types";
import { formatTimeCode } from "shared/utils";
import { useScrubberContext } from "./context";
import { MappedCell } from "./createScrubber";
import styles from "./styles.css";

type Props = {
  onMouseEnter(event: MouseEvent<HTMLInputElement>): unknown;
  onMouseMove(event: MouseEvent<HTMLInputElement>): unknown;
  onMouseLeave(event: MouseEvent<HTMLInputElement>): unknown;
};

export function useScrubber(
  videoRef: RefObject<HTMLVideoElement>,
  progressRef: RefObject<HTMLInputElement>,
  durationRef: RefObject<number>,
  fileID: string | null,
  type: FileType,
) {
  const ref = useRef<HTMLDivElement | null>(null);
  const styleRef = useRef<CSSProperties | null>(null);
  const scrubber = useScrubberContext();
  const [isActive, setActive] = useState(false);
  const isActiveRef = useRef(false);
  const scrubTimeRef = useRef<number | null>(null);
  const scrubListenRef = useRef<(() => void) | null>(null);
  const thumbRef = useRef<HTMLDivElement | null>(null);
  const thumbStyleRef = useRef<CSSProperties | null>(null);
  const timeDisplayRef = useRef<HTMLDivElement | null>(null);
  const cellRef = useRef<MappedCell | null>(null);
  const getDuration = useEvent(() =>
    !videoRef.current?.duration || videoRef.current.duration === Infinity
      ? durationRef.current
      : videoRef.current.duration,
  );

  function draw(posX: number) {
    if (!isActiveRef.current) {
      isActiveRef.current = true;
      setActive(true);
    }

    if (!progressRef.current || !fileID) {
      return;
    }

    const duration = getDuration();

    if (!duration) {
      return;
    }

    const rect = progressRef.current.getBoundingClientRect();
    const left = Math.min(Math.max(0, (posX - rect.left) / rect.width), 100);
    const time = Math.round(duration * left);

    let { current: style } = styleRef;

    if (!style) {
      style = {};
      styleRef.current = style;
    }

    const { current: element } = ref;

    if (!element) {
      return;
    }

    const x = posX - rect.left + 10 - 160 / 2;
    const y = -30;

    style.transform = `translate3d(${x}px, ${y}px, 0)`;

    element.style.transform = style.transform;

    if (scrubTimeRef.current !== time) {
      if (scrubListenRef.current) {
        scrubListenRef.current();
      }

      scrubTimeRef.current = time;

      if (timeDisplayRef.current) {
        timeDisplayRef.current.innerText = formatTimeCode(time);
      }

      scrubListenRef.current =
        scrubber.getCellAndWait(fileID, duration, time, (cell, waiting) => {
          cellRef.current = cell;

          const width = 320 / 2;
          const height = cell[0].naturalHeight / 2;
          const { current: thumb } = thumbRef;

          if (!thumb) {
            return;
          }

          let { current: thumbStyle } = thumbStyleRef;

          if (!thumbStyle) {
            thumbStyle = {};
            thumbStyleRef.current = thumbStyle;
          }

          thumb.style.width = thumbStyle.width = `${width}px`;
          thumb.style.height = thumbStyle.height = `${height}px`;
          thumb.style.backgroundImage = thumbStyle.backgroundImage = `url('${cellRef.current[0].src}')`;
          thumb.style.backgroundPosition = thumbStyle.backgroundPosition = `${cellRef.current[1] / 2}px 0`;
          thumb.style.backgroundSize = thumbStyle.backgroundSize = `auto ${height}px`;

          if (!waiting) {
            scrubListenRef.current = null;
          }
        }) || null;
    }
  }

  const onMouseMove = useEvent((event: MouseEvent<HTMLInputElement>) => {
    if (!fileID || type !== "VIDEO" || !videoRef.current?.videoHeight) {
      return;
    }

    draw(event.clientX);
  });

  useEffect(() => {
    if (!fileID || type !== "VIDEO") {
      return;
    }

    const duration = getDuration();

    if (!duration) {
      return;
    }

    scrubber.prefetch(fileID, duration);

    return () => {
      if (scrubListenRef.current) {
        scrubListenRef.current();
        scrubListenRef.current = null;
      }

      scrubListenRef.current = null;
    };
  }, [getDuration, fileID, scrubber, type]);

  const onMouseEnter = useEvent((event: MouseEvent<HTMLInputElement>) => {
    if (!fileID || type !== "VIDEO" || !videoRef.current?.videoHeight) {
      return;
    }

    draw(event.clientX);
  });

  const onMouseLeave = useEvent(() => {
    isActiveRef.current = false;
    setActive(false);
  });

  const props: Props = {
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
  };

  const element = (
    <div className={cx(styles.scrubber, isActive && styles.isActive)} style={{ ...styleRef.current }} ref={ref}>
      <div className={styles.thumb} ref={thumbRef} style={{ ...thumbStyleRef.current }} />
      <div className={styles.time} ref={timeDisplayRef}>
        {scrubTimeRef.current && formatTimeCode(scrubTimeRef.current)}
      </div>
    </div>
  );

  return [props, element] as const;
}
