import cx from "classnames";
import { useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { IconLogo } from "components/Icons";
import { Breakpoint } from "components/ui";
import { useEvent } from "hooks/useEvent";
import { Search } from "./Search";
import styles from "./styles.css";

export function Header() {
  // const user = useAuthUser();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  // const { element: scrollContainer } = useScrollContainer();
  // const scrollPercentRef = useRef("0");
  const { state } = location;
  const fileTypeWhitelist = typeof state?.fileTypeWhitelist === "string" ? state.fileTypeWhitelist : null;
  const isRoot = () => ["/", "/hot", "/recent", "/most-viewed", "/oldest"].includes(location.pathname);
  const navLinkClassName = useEvent((props: { isActive: boolean }) => {
    const { isActive } = props;

    return cx(styles.tab, {
      [styles.tabActive]: isActive,
    });
  });

  useEffect(() => {
    window._gauges?.push?.(["track"]);
  }, [location]);

  // useEffect(() => {
  //   if (!scrollContainer) {
  //     return;
  //   }

  //   const onScroll = () => {
  //     const value = Math.min(1, scrollContainer.scrollTop / headerHeight);
  //     const fixedValue = value.toFixed(2);

  //     if (fixedValue !== scrollPercentRef.current) {
  //       scrollPercentRef.current = fixedValue;
  //       ref.current?.style.setProperty("--scroll-percent", fixedValue);
  //     }
  //   };

  //   scrollContainer.addEventListener("scroll", onScroll);

  //   return () => {
  //     scrollContainer.removeEventListener("scroll", onScroll);
  //   };
  // }, [scrollContainer]);

  return (
    <div
      className={styles.component}
      // style={{ "--scroll-percent": `${scrollPercentRef.current}` } as CSSProperties}
      ref={ref}
    >
      <div className={styles.logoWrapper}>
        <Link to="/">
          <IconLogo className={styles.logo} name="AWKSHARE" />
        </Link>
        {/* <div className={styles.right}>
          <div className={styles.col}>
            {user ? (
              <div className={styles.user}>
                Never stop partying, <span className={styles.username}>{user.username}</span>!
              </div>
            ) : (
              <h1 className={styles.intro}>An anonymous dropbox for sharing Andrew W.K. related media</h1>
            )}
          </div>
          {user && <AuthAvatar className={styles.avatar} size={23} />}
        </div> */}
      </div>
      <div className={styles.right}>
        <Breakpoint at="lg" mode="show">
          <div className={styles.middle}>
            <Search /> <div className={styles.splitter} />
          </div>
        </Breakpoint>
        <div className={styles.tabs}>
          <Link
            className={cx(
              navLinkClassName({
                isActive: isRoot(),
              }),
            )}
            to="/"
          >
            Home
          </Link>
          <NavLink className={navLinkClassName} to="/files" end={true}>
            All Files
          </NavLink>
          <Breakpoint at="xl" mode="show">
            <NavLink
              className={(props) =>
                navLinkClassName({
                  ...props,
                  isActive: props.isActive || fileTypeWhitelist === "VIDEO",
                })
              }
              to="/videos"
            >
              Videos
            </NavLink>
            <NavLink
              className={(props) =>
                navLinkClassName({
                  ...props,
                  isActive: props.isActive || fileTypeWhitelist === "IMAGE",
                })
              }
              to="/images"
            >
              Images
            </NavLink>
            <NavLink
              className={(props) =>
                navLinkClassName({
                  ...props,
                  isActive: props.isActive || fileTypeWhitelist === "AUDIO",
                })
              }
              to="/audio"
            >
              Audio
            </NavLink>
            <NavLink
              className={(props) =>
                navLinkClassName({
                  ...props,
                  isActive: props.isActive || fileTypeWhitelist === "UNKNOWN",
                })
              }
              to="/misc"
            >
              Misc.
            </NavLink>
          </Breakpoint>
          <Breakpoint at="md" mode="show">
            <NavLink className={navLinkClassName} to="/timeline">
              AWK Timeline
            </NavLink>
          </Breakpoint>
          <Breakpoint at="xxl" mode="show">
            <NavLink className={navLinkClassName} to="/party/create">
              + Create a Party
            </NavLink>
          </Breakpoint>
        </div>
      </div>
    </div>
  );
}
