import { THEIA_HOST } from "shared/api";

export type Scrubber = ReturnType<typeof createScrubber>;

type ObjectUriIndex = number;
type ImageOffset = number;
type ObjectUri = HTMLImageElement;
type Cell = [0 | 1, number, ObjectUriIndex, ImageOffset];
type FileState = {
  id: string;
  duration: number;
  cellListeners: Map<number, (() => void)[]>;
  groupDownloads: Map<number, Promise<void>>;
  rangeDownloads: Map<number, Promise<void>>;
  objectUris: ObjectUri[];
  ranges: Cell[];
};

export type MappedCell = [ObjectUri, ImageOffset];

export function createScrubber() {
  const state = {
    files: new Map<string, FileState>(),
  };

  function getStateForFile(fileID: string, duration: number) {
    let file = state.files.get(fileID);

    if (!file) {
      file = {
        id: fileID,
        duration,
        cellListeners: new Map(),
        groupDownloads: new Map(),
        rangeDownloads: new Map(),
        objectUris: [],
        ranges: [],
      };

      state.files.set(fileID, file);
    }

    return file;
  }

  function onTouchSecond(state: FileState, second: number) {
    const listeners = state.cellListeners.get(second);

    if (listeners) {
      listeners.forEach((fn) => fn());
    }
  }

  function downloadGroup(state: FileState, group: number) {
    const existingDownload = state.groupDownloads.get(group);

    if (existingDownload) {
      return existingDownload;
    }

    const op = fetch(`${THEIA_HOST}/leafs/${state.id}/theia/video-grid/jpeg?group=${group}`)
      .then((res) => res.blob())
      .then((res) => {
        const url = URL.createObjectURL(res);
        const img = document.createElement("img");
        return new Promise<HTMLImageElement>((resolve, reject) => {
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
      })
      .then((uri) => {
        state.objectUris.push(uri);

        const uriIndex = state.objectUris.length - 1;
        const rangeDuration = state.duration / 8;

        let groupIndex = -1;

        for (let i = 0; i < Math.ceil(state.duration); i++) {
          if (i % rangeDuration < 1) {
            groupIndex++;
          }

          if (state.ranges[i] == null) {
            // console.log('init', i, 'to a grouped frame');
            state.ranges[i] = [0, group, uriIndex, groupIndex * 320];
            onTouchSecond(state, i);
            continue;
          }

          if (state.ranges[i][0] === 0 && state.ranges[i][1] < group) {
            // console.log('update', i, 'to a grouped frame');
            state.ranges[i][0] = 0;
            state.ranges[i][1] = group;
            state.ranges[i][2] = uriIndex;
            state.ranges[i][3] = groupIndex * 320;
            onTouchSecond(state, i);
          }
        }
      })
      .then(() => {
        state.groupDownloads.set(group, Promise.resolve());
      });

    state.groupDownloads.set(group, op);

    return op;
  }

  function downloadRange(state: FileState, range: number) {
    const existingDownload = state.rangeDownloads.get(range);

    if (existingDownload) {
      return existingDownload;
    }

    // console.log('download range', range);

    const op = fetch(`${THEIA_HOST}/leafs/${state.id}/theia/video-grid/jpeg?range=${range}`)
      .then((res) => res.blob())
      .then((res) => {
        const url = URL.createObjectURL(res);
        const img = document.createElement("img");
        return new Promise<HTMLImageElement>((resolve, reject) => {
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
      })
      .then((uri) => {
        state.objectUris.push(uri);

        const uriIndex = state.objectUris.length - 1;
        const secondsFrom = range * 8;
        const secondsTo = Math.min(Math.ceil(state.duration), range * 8 + 8);

        let seconds = secondsFrom;
        let i = -1;

        while (seconds < secondsTo) {
          i++;
          state.ranges[seconds][0] = 1;
          state.ranges[seconds][1] = range;
          state.ranges[seconds][2] = uriIndex;
          state.ranges[seconds][3] = i * 320;
          onTouchSecond(state, seconds);
          seconds++;
        }
      })
      .then(() => {
        state.rangeDownloads.set(range, Promise.resolve());
      })
      .catch((err) => {
        console.error("error downloading range", range, err);
      });

    state.rangeDownloads.set(range, op);

    return op;
  }

  function getCellForTimeFromState(state: FileState, time: number, fetchNext?: boolean): Cell {
    const range = Math.floor(time / 8);
    const dl = downloadGroup(state, 0)
      .then(() => downloadGroup(state, 1))
      .then(() => downloadRange(state, range));

    if (fetchNext !== false && range + 1 < Math.floor(state.duration / 8)) {
      dl.then(() => downloadRange(state, range + 1));
    }

    return state.ranges[time];
  }

  function mapCell(state: FileState, cell: Cell): MappedCell {
    const url = state.objectUris[cell[2]];

    return [url, cell[3] * -1];
  }

  function getMappedCellForTime(fileID: string, duration: number, time: number): MappedCell | null {
    const state = getStateForFile(fileID, duration);
    const cell = getCellForTimeFromState(state, time);

    if (!cell) {
      return null;
    }

    return mapCell(state, cell);
  }

  function prefetch(fileID: string, duration: number): void {
    const state = getStateForFile(fileID, duration);
    downloadGroup(state, 0);
  }

  function getCellAndWait(
    fileID: string,
    duration: number,
    time: number,
    fn: (cell: MappedCell, waiting: boolean) => void,
  ) {
    let active = true;

    const state = getStateForFile(fileID, duration);
    const cell = getCellForTimeFromState(state, time);

    if (cell) {
      if (cell[0] === 1) {
        fn(mapCell(state, cell), false);
        return;
      }

      fn(mapCell(state, cell), true);
    }

    let listeners = state.cellListeners.get(time);

    if (!listeners) {
      listeners = [];
      state.cellListeners.set(time, listeners);
    }

    let _fn: (() => void) | null = null;

    const unsubscribe = () => {
      if (active) {
        active = false;

        if (!listeners || !_fn) {
          return;
        }

        listeners.splice(listeners.indexOf(_fn));

        if (listeners.length === 0) {
          state.cellListeners.delete(time);
        }
      }
    };

    _fn = () => {
      const cell = getCellForTimeFromState(state, time);

      if (cell[0] === 1) {
        fn(mapCell(state, cell), false);
        unsubscribe();
        return;
      }

      fn(mapCell(state, cell), true);
    };

    listeners.push(_fn);

    return unsubscribe;
  }

  return {
    getMappedCellForTime,
    getCellAndWait,
    prefetch,
    state,
  };
}
