import cx from "classnames";
import { ChangeEvent, FormEvent, KeyboardEvent, useCallback, useRef, useState } from "react";
import encryptTripcode from "tripcode";
import { LoadingDots } from "components/LoadingDots";
import { useComments } from "contexts/comments";
import { EntityType } from "shared/types";
import { CommentAvatar } from "../CommentAvatar";
import styles from "./styles.css";

// TODO: migrate to request store

const getFromLocalStorage = (key: string) => () => {
  try {
    return window.localStorage.getItem(key) || "";
  } catch (err) {
    return "";
  }
};

type CommentFormProps = {
  entityType: EntityType;
  entityID: string;
  inlineTrip?: boolean;
  className?: string;
};

export function CommentForm(props: CommentFormProps) {
  const { className, entityID, entityType, inlineTrip } = props;
  const comments = useComments();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [name, setName] = useState(getFromLocalStorage("name"));
  const [tripCode, setTripCode] = useState(getFromLocalStorage("tripcode"));
  const [comment, setComment] = useState("");
  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => setName(event.currentTarget.value), []);
  const onChangeTripCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setTripCode(event.currentTarget.value.replace(/\s/g, "")),
    [],
  );

  const onChangeComment = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.currentTarget.value),
    [],
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLTextAreaElement>) => {
      event.preventDefault();

      if (!name.trim() || !comment.trim()) {
        return;
      }

      if (!isPosting && comments) {
        setIsPosting(true);

        comments.create(entityType, entityID, name, comment, tripCode).then(
          () => {
            setComment("");
            setIsPosting(false);

            textareaRef.current?.focus();
          },
          () => {
            // TODO: More error handling.
            setIsPosting(false);
          },
        );
      }
    },
    [comments, comment, entityID, entityType, isPosting, name, tripCode],
  );

  const blurTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const onFocus = useCallback(() => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }

    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    blurTimeout.current = setTimeout(() => setIsFocused(false), 50);
  }, []);

  const onBlurName = useCallback(() => {
    onBlur();
    window.localStorage.setItem("name", name);
  }, [onBlur, name]);

  const onBlurTripCode = useCallback(() => {
    onBlur();
    window.localStorage.setItem("tripcode", tripCode);
  }, [tripCode, onBlur]);

  const onKeyDownComment = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && event.metaKey) {
        onSubmit(event);
      }
    },
    [onSubmit],
  );

  const isDirty = comment.trim() !== "";
  const submitDisabled = !name.trim() || !isDirty || isPosting;

  return (
    <form className={cx(styles.component, className)} onSubmit={onSubmit}>
      <CommentAvatar input={tripCode ? encryptTripcode(tripCode) : name} />
      <div className={styles.right}>
        {(isDirty || isFocused) && (
          <div className={cx(styles.user, inlineTrip && styles.inlineTrip)}>
            <input
              className={styles.input}
              type="text"
              name="name"
              onFocus={onFocus}
              onBlur={onBlurName}
              onChange={onChangeName}
              value={name}
              placeholder="Type a name..."
              disabled={isPosting}
              maxLength={50}
            />
            <div className={styles.trip}>
              <input
                className={styles.input}
                type="text"
                name="tripcode"
                onBlur={onBlurTripCode}
                onFocus={onFocus}
                onChange={onChangeTripCode}
                value={tripCode}
                placeholder="Type a tripcode (optional)..."
                disabled={isPosting}
                maxLength={8}
              />
              <div className={styles.explain}>
                <div className={styles.btn}>?</div>
                <div className={styles.popover}>
                  A tripcode is a unique code (like a password) that is encrypted and displayed next to your comments to
                  optionally identify them as yours across different files.
                </div>
              </div>
            </div>
          </div>
        )}
        <textarea
          className={cx(styles.textarea, (isFocused || comment) && styles.focused)}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChangeComment}
          onKeyDown={onKeyDownComment}
          value={comment}
          name="comment"
          placeholder="Type a comment here..."
          autoComplete="off"
          maxLength={500}
          ref={textareaRef}
        />
        {(isDirty || isFocused) && (
          <div className={styles.actions}>
            <button
              className={cx(styles.submit, submitDisabled && styles.disabled)}
              type="submit"
              disabled={submitDisabled}
            >
              {isPosting ? <LoadingDots size={6} padding={6} /> : "Post Comment"}
            </button>
          </div>
        )}
      </div>
    </form>
  );
}
