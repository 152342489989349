import { PropsWithChildren, useEffect, useRef } from "react";
import { ButtonSetProvider } from "./context";

type ButtonSetProps = PropsWithChildren<{
  size?: number;
}>;

export function ButtonSet(props: ButtonSetProps) {
  const { children, size = 12 } = props;
  const contextValue = useRef(null);

  if (!contextValue.current) {
    contextValue.current = {
      size,
      index: 0,
    };
  }

  useEffect(() => {
    // Reset after every render.
    contextValue.current = {
      size,
      index: 0,
    };
  });

  return <ButtonSetProvider value={contextValue.current}>{children}</ButtonSetProvider>;
}
