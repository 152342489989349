import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconDownload = forwardRef<SVGSVGElement, IconProps>(function IconDownload(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M16 11h5l-9 10-9-10h5v-11h8v11zm1 11h-10v2h10v-2z" />
    </svg>
  );
});
