import cx from "classnames";
import { ElementType, MouseEvent, memo, useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";
import { FileIcon } from "components/FileIcon";
import { IconComments } from "components/Icons";
import { usePopoverNavigation } from "hooks/usePopoverNavigation";
import { splitFilename } from "shared/leafSort";
import { File, FileType, Leaf, fileTypeNames, fileTypeNamesPlural } from "shared/types";
import { FileDuration } from "../FileDuration";
import styles from "./styles.css";

interface LeafsListRowProps {
  leaf: Leaf;
  disabled?: boolean;
  fileTypeWhitelist?: FileType[];
  onPress?: (leaf: Leaf) => void;
  showComments?: boolean;
  showDate?: boolean;
  showOCR?: boolean;
  showViewCounts?: boolean;
  to?: LinkProps["to"];
  toState?: LinkProps["state"];
}

export function BaseLeafsListRow(props: LeafsListRowProps) {
  const {
    disabled,
    fileTypeWhitelist,
    leaf,
    onPress,
    showComments = true,
    showDate = true,
    showViewCounts = true,
    showOCR = true,
    to,
    toState,
  } = props;

  const { getLinkProps } = usePopoverNavigation();
  const [fileName, extension] = splitFilename(leaf.name);
  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      onPress?.(leaf);
    },
    [onPress, leaf],
  );

  const date = new Date(leaf.updatedAt || leaf.createdAt);
  const dateStr = date.toLocaleString("en-us", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  }); // cant use `dateStyle` in safari

  const timeStr = date.toLocaleString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
  }); // cant use `timeStyle` in safari

  const isLink = !onPress && leaf.id;
  const Component: ElementType = isLink ? Link : "div";
  const componentProps = isLink && to ? getLinkProps(to, toState) : {};

  return (
    <Component
      {...componentProps}
      className={cx(styles.component, (disabled || !leaf.id) && styles.disabled)}
      onClick={onPress ? onClick : undefined}
    >
      <div className={styles.iconCell}>
        {leaf.type === "FILE" ? (
          <div className={styles.icon}>
            <FileIcon className={styles.fileIcon} file={leaf as File} size={19} />
          </div>
        ) : (
          <div className={styles.icon}>
            <span>↳</span>
          </div>
        )}
      </div>
      <div className={styles.name}>
        <span className={styles.fileName}>{fileName}</span>
        {extension && <span className={styles.suffix}>.{extension}</span>}
      </div>
      {showOCR && leaf.hasOCR && (
        <div className={styles.ocr}>{leaf.hasOCR && <span className={styles.enabled}>OCR</span>}</div>
      )}
      {showComments && leaf.commentsCount != null && leaf.commentsCount > 0 && (
        <div className={styles.comments}>
          <IconComments size={16} /> <span className={styles.commentsCount}>{leaf.commentsCount}</span>
        </div>
      )}
      {"duration" in leaf && leaf.duration != null && leaf.duration > 0 && <FileDuration file={leaf as File} />}
      {leaf.type === "COLLECTION" && leaf.childFileTypes && (
        <>
          {Object.entries(leaf.childFileTypes).map(
            ([type, count]: [FileType, number]) =>
              (!fileTypeWhitelist || fileTypeWhitelist.includes(type)) && (
                <div className={cx(styles.typeCount, styles[`typeCount${type}` as keyof typeof styles])} key={type}>
                  {count.toLocaleString()}
                  <span className={styles.type}>
                    {" "}
                    {count > 1 ? fileTypeNamesPlural.get(type) : fileTypeNames.get(type)}
                  </span>
                </div>
              ),
          )}
        </>
      )}
      {showViewCounts && (
        <div className={styles.views}>
          <span>{leaf.viewsCount.toLocaleString()}</span>
          <span>&nbsp;view{leaf.viewsCount === 0 || leaf.viewsCount > 1 ? "s" : ""}</span>
        </div>
      )}
      {showDate && (
        <div className={styles.date}>
          <span>{dateStr}</span>
          <span>,&nbsp;</span>
          <span>{timeStr}</span>
        </div>
      )}
    </Component>
  );
}

export const LeafsListRow = memo(BaseLeafsListRow);
