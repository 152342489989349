import { DragEvent, MouseEvent, useCallback } from "react";
import { createPortal } from "react-dom";
import appStyles from "components/App/styles.css";
import { LeafsBrowser, LeafsBrowserProps } from "../LeafsBrowser";
import styles from "./styles.css";

interface LeafsBrowserOverlayProps extends LeafsBrowserProps {
  onClose(fromX: number, fromY: number): void;
}

export function LeafsBrowserOverlay(props: LeafsBrowserOverlayProps) {
  const { onClose, ...otherProps } = props;
  const closeBrowserIfDirect = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (event.target === event.currentTarget) {
        onClose(event.clientX, event.clientY);
      }
    },
    [onClose],
  );

  const cancelEvent = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return createPortal(
    <div
      className={styles.component}
      onDragOver={cancelEvent}
      onDragEnter={cancelEvent}
      onDragLeave={cancelEvent}
      onDrop={cancelEvent}
      onClick={closeBrowserIfDirect}
    >
      <div className={styles.inner}>
        <LeafsBrowser {...otherProps} />
      </div>
    </div>,
    document.querySelector(`.${appStyles.portals}`),
  );
}
