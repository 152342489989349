import cx from "classnames";
import { HTMLAttributes } from "react";
import styles from "./styles.css";

export interface BoxedTextProps extends HTMLAttributes<HTMLDivElement> {
  borderClassName?: string;
  hasBorder?: boolean;
}

export function BoxedText(props: BoxedTextProps) {
  const { borderClassName, className, children, hasBorder, ...otherProps } = props;

  return (
    <div className={cx(styles.component, className)} {...otherProps}>
      {hasBorder !== false ? <div className={cx(styles.border, borderClassName)}>{children}</div> : children}
    </div>
  );
}
