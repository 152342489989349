import { LeafsListCard } from "components/LeafsList/LeafsListCard";
import { LeafsListRow } from "components/LeafsList/LeafsListRow";
import { Leaf } from "shared/types";

interface LeafResultProps {
  mode: "list" | "card";
  leaf: Leaf;
}

export function LeafResult(props: LeafResultProps) {
  const { leaf, mode } = props;
  const to = `/files/${leaf.id}`;

  if (mode === "card") {
    return <LeafsListCard showDate={false} leaf={leaf} to={to} />;
  }

  return <LeafsListRow showDate={false} leaf={leaf} to={to} />;
}
