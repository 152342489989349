import { MouseEvent, RefObject, memo, useCallback, useEffect, useMemo, useRef } from "react";
import styles from "./styles.css";

function setupCanvas(canvas: HTMLCanvasElement) {
  const dpr = window.devicePixelRatio || 1;
  const rect = canvas.getBoundingClientRect();
  canvas.width = rect.width * dpr;
  canvas.height = rect.height * dpr;
  const ctx = canvas.getContext("2d");
  ctx.scale(dpr, dpr);
  return ctx;
}

type FilePreviewImageHighlightsProps = {
  height: number;
  imageHeight: number;
  imageRef: RefObject<HTMLImageElement>;
  imageWidth: number;
  isCreating: boolean;
  width: number;
  onEndHighlighting: () => void;
};

type Point = [number, number];
type Points = Point[] & {
  closed?: boolean;
};

const isWithinCloseMargin = (currentPoint: Point, firstPoint: Point) =>
  firstPoint[0] - currentPoint[0] < 10 &&
  firstPoint[0] - currentPoint[0] > -10 &&
  firstPoint[1] - currentPoint[1] < 10 &&
  firstPoint[1] - currentPoint[1] > -10;

function BaseFilePreviewImageHighlights(props: FilePreviewImageHighlightsProps) {
  const {
    width,
    height,
    imageWidth,
    imageHeight,
    imageRef,
    isCreating,
    // onEndHighlighting,
  } = props;
  const points = useRef<Points>(null);
  const ref = useRef<HTMLCanvasElement>(null);
  const rect = useMemo(() => imageRef.current.getBoundingClientRect(), [imageRef, width, height]); // eslint-disable-line react-hooks/exhaustive-deps
  const ratio = useMemo<[number, number]>(() => {
    return [width / imageWidth, height / imageHeight];
  }, [width, height, imageWidth, imageHeight]);

  const draw = useCallback(
    (ctx: CanvasRenderingContext2D = ref.current.getContext("2d")) => {
      ctx.clearRect(0, 0, width, height);

      ctx.strokeStyle = "red";
      ctx.fillStyle = "rgba(255,0,0,0.41)";
      ctx.lineWidth = 3;

      if (isCreating) {
        // skip existing
        ctx.beginPath();

        for (let i = 0; i < points.current.length; i++) {
          const point = points.current[i];

          if (i === 0) {
            ctx.moveTo(point[0], point[1]);

            // Only 1 point, add a hint where we're drawing
            if (i === points.current.length - 1) {
              ctx.lineTo(point[0] + 1.5, point[1]);
            }
          } else {
            ctx.lineTo(point[0], point[1]);
          }
        }

        ctx.stroke();

        if (points.current.closed) {
          ctx.fill();
        }
      }
    },
    [isCreating, width, height],
  );

  const pointForXY = useCallback(
    (clientX: number, clientY: number): [number, number] => {
      const x = clientX - rect.left;
      const y = clientY - rect.top;

      return [
        x + 6, // * ratio[0],
        y + 3, // * ratio[1],
      ];
    },
    [rect],
  );

  const createCommentFromPoints = useCallback(() => {
    points.current.closed = true;
    draw();
  }, [draw]);

  const addPoint = useCallback(
    (x: number, y: number) => {
      let point = pointForXY(x, y);

      if (points.current.length) {
        // if within margin, close it
        if (points.current.length > 1 && isWithinCloseMargin(point, points.current[0])) {
          point = [...points.current[0]];
          points.current.push(point);
          createCommentFromPoints();
          return;
        }
      }

      points.current.push(point);

      draw();
    },
    [createCommentFromPoints, draw, pointForXY],
  );

  const onClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (!points.current.closed) {
        addPoint(event.clientX, event.clientY);
      }
    },
    [addPoint],
  );

  const withinCloseMargin = useRef(false);
  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (points.current.length) {
        const eventPoint = pointForXY(event.clientX, event.clientY);
        const firstPoint = points.current[0];
        const withinMargin = isWithinCloseMargin(eventPoint, firstPoint);

        if (withinMargin) {
          if (!withinCloseMargin.current) {
            withinCloseMargin.current = true;
            ref.current.classList.add(styles.close);
          }
        } else {
          if (withinCloseMargin.current) {
            withinCloseMargin.current = false;
            ref.current.classList.remove(styles.close);
          }
        }
      }
    },
    [pointForXY],
  );

  useEffect(() => {
    if (isCreating) {
      points.current = [];
    } else {
      points.current = null;
    }
  }, [isCreating]);

  useEffect(() => {
    const ctx = setupCanvas(ref.current);
    draw(ctx);
  }, [draw, ratio]);

  return (
    <canvas
      className={styles.component}
      onClick={onClick}
      // onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      // onMouseUp={onMouseUp}
      ref={ref}
      style={{ width, height }}
    />
  );
}

export const FilePreviewImageHighlights = memo(BaseFilePreviewImageHighlights);
