import ynaOptimized from "public/images/backgrounds/yna-optim.jpeg"; // cSpell:ignore optim
import { PropsWithChildren } from "react";
import { useUploaderState } from "components/Share";
import { useEnv } from "contexts/env";
import { formatBytes } from "shared/utils";
import { Card } from "../Card";
import styles from "./styles.css";

export type IndexPageHeader = PropsWithChildren;

export function IndexPageHeader(props: IndexPageHeader) {
  const { children } = props;
  const { isMobile } = useEnv();
  const uploader = useUploaderState();
  const quota = uploader?.getQuota() || null;

  return (
    <div className={styles.component}>
      <div className={styles.top}>
        <Card
          actions={[
            {
              url: "https://www.instagram.com/awkshare",
              label: "@awkshare on Instagram ↗",
            },
            {
              className: styles.shareButton,
              label: `${isMobile ? "Tap" : "Click"} here to share`,
              onClick: uploader?.openFileBrowser,
              suffix: <>{quota ? `${formatBytes(quota)} quota remaining` : "Fetching quota..."}</>,
            },
          ]}
          backgroundImageProps={{
            offset: "25%",
            url: ynaOptimized,
          }}
          className={styles.card}
          title="Welcome to AWKSHARE"
        >
          <em>AWKSHARE</em> is a free, non-profit platform dedicated to archiving the work and wider media surrounding
          the musician{" "}
          <a href="https://www.andrewwk.com" target="_blank" rel="noopener noreferrer">
            Andrew W.K.
          </a>
        </Card>
      </div>
      <div className={styles.tabs}>{children}</div>
    </div>
  );
}
