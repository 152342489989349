import cx from "classnames";
import { HTMLAttributes, ReactNode } from "react";
import styles from "./styles.css";

interface PillProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode;
  isActive?: boolean;
}

export function Pill(props: PillProps) {
  const { className, children, icon, isActive, ...otherProps } = props;

  return (
    <div className={cx(styles.component, className, isActive && styles.isActive)} {...otherProps}>
      {icon && (
        <>
          <div className={styles.icon}>{icon}</div>&nbsp;&nbsp;
        </>
      )}
      {children}
    </div>
  );
}
