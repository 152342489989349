import { useEffect, useState } from "react";
import { FileIcon } from "components/FileIcon";
import { Loader } from "components/Loader";
import { File } from "shared/types";
import styles from "./styles.css";

type FilePreviewTextProps = {
  file: File;
};

export function FilePreviewText(props: FilePreviewTextProps) {
  const { file } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [contents, setContents] = useState(null);

  useEffect(() => {
    let active = true;

    fetch(file.url)
      .then((res) => res.arrayBuffer())
      .then((res) => {
        if (active) {
          const dataView = new DataView(res);
          const utf8 = new TextDecoder("utf-8");

          let contents = utf8.decode(dataView);

          if (contents.includes("�")) {
            contents = new TextDecoder("iso-8859-2").decode(dataView);
          }

          setIsLoading(false);
          setContents(contents);
        }
      })
      .catch(() => {
        if (active) {
          setIsLoading(false);
          setContents(false);
        }
      });

    return () => {
      active = false;
    };
  }, [file?.url]);

  return (
    <div className={styles.component}>
      {isLoading ? <Loader /> : contents ? <div className={styles.contents}>{contents}</div> : <FileIcon file={file} />}
    </div>
  );
}
