/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestStore, RequestStoreKey } from "contexts/requestStore";
import { Cache, CacheEntityType, CacheStruct } from "./cache";
import env from "./env";
import {
  Archive,
  Collection,
  Comment,
  JSONObject,
  JSONValue,
  Leaf,
  LeafOCR,
  Party,
  SearchResult,
  TimelineItem,
  TimelineItemReference,
} from "./types";

const PRODUCTION_API_HOST = process.env.PRODUCTION_API_HOST || "https://api.awkshare.com";
const PRODUCTION_THEIA_HOST = process.env.PRODUCTION_API_HOST || "https://theia.awkshare.com";

export const API_HOST =
  env === "development"
    ? !window.IS_NODE && window.location.search.includes("env=prod")
      ? PRODUCTION_API_HOST
      : "http://localhost:8888"
    : PRODUCTION_API_HOST;

export const THEIA_HOST =
  env === "development"
    ? !window.IS_NODE && window.location.search.includes("env=prod")
      ? PRODUCTION_THEIA_HOST
      : "http://localhost:8888"
    : PRODUCTION_THEIA_HOST;

type Method = "get" | "post" | "put";

export type Api = ReturnType<typeof createAPI>;

export default function createAPI({ cache, requestStore }: { cache: Cache; requestStore: RequestStore }) {
  const _authState: {
    accessToken: string | null;
    refreshToken: string | null;
    expiresAt: number | null;
    user: {
      id: string;
      username: string;
      avatar: string;
    } | null;
  } = {
    accessToken: null,
    refreshToken: null,
    expiresAt: null,
    user: null,
  };

  function applyAuthState({ accessToken, refreshToken, expiresAt, user }: typeof _authState) {
    Object.assign(_authState, {
      accessToken,
      refreshToken,
      expiresAt,
      user,
    });

    requestStore.set(RequestStoreKey.Auth, _authState);
  }

  let tokenRefreshPromise: ReturnType<typeof refreshToken> | null = null;

  async function refreshToken() {
    await callApi(
      "post",
      "auth/refresh",
      {
        refreshToken: _authState.refreshToken,
      },
      {
        skipAuth: true,
      },
    ).then(applyAuthState);
  }

  async function callApi<Response extends JSONValue>(
    method: Method,
    route: string,
    data?: FormData | JSONObject,
    {
      skipAuth,
    }: {
      skipAuth?: boolean;
    } = {},
  ): Promise<Response | null> {
    const headers: HeadersInit = {};

    let body;
    let params;

    if (data) {
      if (data instanceof FormData) {
        body = data;
      } else if (method === "get") {
        params = `?${new URLSearchParams(
          Object.entries(data)
            .filter((kv) => kv[1] != null)
            .map(([key, value]) => [key, typeof value === "string" ? value : JSON.stringify(value)]),
        )}`;
      } else {
        headers["content-type"] = "application/json";
        body = JSON.stringify(data);
      }
    }

    if (!skipAuth && _authState.accessToken) {
      if (!_authState.expiresAt || _authState.expiresAt < Date.now()) {
        // Uh, what should we do here...
        if (!tokenRefreshPromise) {
          tokenRefreshPromise = refreshToken();
          try {
            await tokenRefreshPromise;
          } finally {
            tokenRefreshPromise = null;
          }
        } else {
          await tokenRefreshPromise;
        }
      }

      headers.authorization = `Bearer ${_authState.accessToken}`;
    }

    console.log("[api]", `[${method}]`, `[${route}${params || ""}]`);

    const res = await fetch(`${API_HOST}/${route}${params || ""}`, {
      method,
      headers,
      body,
    });

    if (res.status === 204) {
      return null;
    }

    const contentType = res.headers.get("content-type");

    if (contentType === "application/json" || contentType?.startsWith?.("application/json;")) {
      return res.json().then((res) => {
        if (res.status === 0) {
          throw new Error(res.response.message || res.response.type);
        }

        return res.response;
      });
    }

    throw new Error("invalid response");
  }

  // const createCall = <Data extends FormData | JSONObject>(method: Method, route: string, data?: Data) => () => callApi(method, route, data);
  // const createCallWithCustomData = <Data extends FormData | JSONObject>(method: Method, route: string) => (customData?: Data) => callApi(method, route, customData);

  const cacheEntity =
    <K extends CacheEntityType, RT extends CacheStruct[K][keyof CacheStruct[K]] = CacheStruct[K][keyof CacheStruct[K]]>(
      type: K,
    ) =>
    (entity: RT | null): RT | null => {
      cache.set(type, (entity as any).id, entity);
      return entity;
    };

  const cacheEntities =
    <K extends CacheEntityType>(type: K) =>
    (entities: any[]) => {
      entities.forEach((entity) => {
        cache.set(type, entity.id, entity);
      });

      return entities;
    };

  const quota = {
    get: () => callApi<{ quota: number }>("get", "quota").then((res) => res?.quota || null),
  };

  const archive = {
    get: (id: string) => callApi<Archive>("get", `archive/${id}`),
  };

  const leaf = {
    addView: (leafID: string) => callApi<Leaf>("post", `leafs/${leafID}/view`),
    get: (leafID: string) => callApi<Leaf>("get", `leafs/${leafID}`).then(cacheEntity("leafs")),
    getContents: (leafID: string) => callApi<Leaf[]>("get", `leafs/${leafID}/contents`),
    getOCR: (leafID: string) => callApi<LeafOCR>("get", `leafs/${leafID}/ocr`),
    listRecent: ({ ids = true } = {}) => callApi<string[]>("get", `leafs${ids ? "?ids=1" : ""}`),
    listHot: () => callApi<string[]>("get", "leafs/hot"),
    listMostViewed: () => callApi<string[]>("get", "leafs/most-viewed"),
    listComments: (leafID: string) => callApi<Comment[]>("get", `leafs/${leafID}/comments`),
    populate: (ids: string[]) =>
      callApi<Leaf[]>("post", "leafs/populate", { leafIDs: ids }).then(cacheEntities("leafs")),
    postComment: (leafID: string, name: string, body: string, tripCode?: string) =>
      callApi<Comment>("post", `leafs/${leafID}/comments`, {
        body,
        name,
        ...(tripCode ? { tripCode } : null),
      }),
  };

  const collection = {
    create: (name: string | null, description: string | null) =>
      callApi<Collection>("post", "collections", { name, description }),
    publish: (id: string) => callApi<Collection>("put", `leafs/${id}`, { publish: true }).then(cacheEntity("leafs")),
    get: (parentID: string) => callApi<Collection>("get", `leafs/${parentID}`).then(cacheEntity("leafs")),
    archive: (parentID: string) => callApi<Archive>("get", `leafs/${parentID}/archive`),
  };

  const party = {
    get: (partyID: string, token?: string) => callApi<Party>("get", `party/${partyID}${token ? `/${token}` : ""}`),
    create: (
      data: Required<Omit<Party, "coverFileID" | "id" | "token" | "mode">> &
        Partial<Pick<Party, "coverImageURL" | "coverFileID" | "id">>,
    ) => callApi<Party>("post", "party", data),
    addFile: (id: string, token: string, fileID: string) =>
      callApi<Party>("post", `party/${id}/${token}/add`, { fileID }),
    listComments: (partyID: string) => callApi<Comment[]>("get", `party/${partyID}/comments`),
    postComment: (partyID: string, name: string, body: string, tripCode?: string) =>
      callApi<Comment>("post", `party/${partyID}/comments`, {
        body,
        name,
        ...(tripCode ? { tripCode } : null),
      }),
  };

  const timeline = {
    get: (before?: number | null, after?: number | null) =>
      callApi<TimelineItem[]>("get", "timeline", {
        ...(before ? { before } : null),
        ...(after ? { after } : null),
      }),
    getItem: (id: string) => callApi<TimelineItem>("get", `timeline/${id}`).then(cacheEntity("timelineItems")),
    getReference: (id: string) => callApi<TimelineItemReference>("get", `timeline/ref/${id}`),
    createItem: (item: any) => callApi<TimelineItem>("post", "timeline", item).then(cacheEntity("timelineItems")),
    updateItem: (id: string, changes: any) =>
      callApi<TimelineItem>("put", `timeline/${id}`, changes).then(cacheEntity("timelineItems")),
    parseReference: (url: string) => callApi<TimelineItemReference>("post", "timeline/parse", { url }),
    createReference: (url: string) => callApi<TimelineItemReference>("post", "timeline/ref", { url }),
    addReferenceToItem: (referenceID: string, itemID: string) =>
      callApi<Record<never, never>>("post", `timeline/${itemID}/ref`, { referenceID }),
  };

  const auth = {
    getDiscordAuthRedirectURL: () => callApi<{ state: string; url: string }>("get", "auth/discord/init"),
    finalizeDiscordAuth: (code: string) => callApi<any>("post", "auth/discord/finalize", { code }),
  };

  const search = {
    query: (params: { query: string; type: "LEAFS" | "OCR" }) =>
      callApi<{
        results: SearchResult[];
      }>("post", "search", params),
  };

  return {
    applyAuthState,
    archive,
    auth,
    cache,
    collection,
    leaf,
    party,
    quota,
    search,
    timeline,
  };
}
