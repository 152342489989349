import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router";
import { Bar, BarActions, BarTitle } from "components/Bar";
import { Button, ButtonIcon, ButtonLabel, ViewportSize } from "components/Button";
import { FilePreview } from "components/FilePreview";
import { IconDownload, IconExternal } from "components/Icons";
import { LeafsList } from "components/LeafsList";
import { Loader } from "components/Loader";
import { useApi } from "contexts/api";
import { useEnv } from "contexts/env";
import { useRequestStore } from "contexts/requestStore";
import { useEvent } from "hooks/useEvent";
import { usePopoverNavigation } from "hooks/usePopoverNavigation";
import { THEIA_HOST } from "shared/api";
import { getAscending, getSort, sortLeafsInPlace } from "shared/leafSort";
import { Collection, File, Leaf, fileTypeNames } from "shared/types";
import { formatBytes } from "shared/utils";
import { Comments } from "../../Comments";
import { BulkDownload } from "./BulkDownload";
import styles from "./styles.css";
// import Pen from "components/icons/pen";

// type Props = RouteComponentProps<
//   {
//     fileID: string;
//   },
//   any,
//   {
//     from?: string;
//     fileTypeWhitelist?: FileType[];
//   }
// >;

const ocrLocalStorageKey = "ocrEnabled";

function isOCREnabled() {
  if (typeof window === "undefined") {
    return false;
  }

  try {
    const value = localStorage.getItem(ocrLocalStorageKey);

    if (value === null) {
      return true;
    }

    return value === "true";
  } catch (error) {
    return true;
  }
}

export function FilePage() {
  const params = useParams();
  const location = useLocation();
  const api = useApi();
  const env = useEnv();
  const navigate = useNavigate();
  const { currentPopover, getBackLinkProps } = usePopoverNavigation();
  const requestStore = useRequestStore();
  const [isHighlighting, setIsHighlighting] = useState(false);
  const [ocrEnabled, setOCREnabled] = useState(isOCREnabled);
  const [ocrReady, setOCRReady] = useState(false);
  const [leaf, setLeaf] = useState<Leaf | false | null>(api?.cache.get("leafs", params.fileID || null) || null);

  const onOCRReady = useEvent(() => setOCRReady(true));

  const onChangeOCRCheckbox = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    setOCREnabled(event.currentTarget.checked);

    try {
      localStorage.setItem(ocrLocalStorageKey, event.currentTarget.checked ? "true" : "false");
    } catch (error) {
      // Ignore errors.
    }
  });

  const onEndHighlighting = useEvent(() => setIsHighlighting(false));

  // const onToggleHighlighting = useCallback(() => {
  //   setIsHighlighting(!isHighlighting);
  // }, [isHighlighting]);

  useEffect(() => {
    setOCRReady(false);

    if (!params.fileID) {
      setLeaf(null);
      return;
    }

    if (!api) {
      return;
    }

    api.leaf.addView(params.fileID).catch(() => {
      // Ignore errors.
    });

    setLeaf(api.cache.get("leafs", params.fileID));

    api.leaf.get(params.fileID).then(
      (leaf) => {
        if (!leaf) {
          return;
        }

        /**
         * If we're landing on a file that's part of a collection, preload
         * the collection.
         */
        if ("parentID" in leaf && leaf.parentID != null && !api.cache.has("leafs", leaf.parentID)) {
          api.leaf.get(leaf.parentID);
        }

        setLeaf(leaf);
      },
      () => {
        setLeaf(false);
      },
    );
  }, [api, params.fileID]);

  useEffect(() => {
    if (!api || !leaf || leaf.type !== "FILE" || !(leaf as File).parentID) {
      return;
    }

    const onKeyUp = (event: KeyboardEvent) => {
      if (document.activeElement !== document.body) {
        return;
      }

      if (!leaf.parentID) {
        return;
      }

      const collection = api.cache.get("leafs", leaf.parentID) as Collection;

      if (!collection) {
        return;
      }

      const sortedChildren = sortLeafsInPlace(
        [...(collection.children || [])],
        getSort(requestStore),
        getAscending(requestStore),
      );

      switch (event.key) {
        case "ArrowLeft": {
          const index = sortedChildren.findIndex((child) => child.id === leaf.id);
          const newIndex = index === 0 ? sortedChildren.length - 1 : index - 1;

          const newID = sortedChildren[newIndex].id;

          navigate(`/files/${newID}`, {
            ...location.state,
            preventPlayerAutofocus: true,
          });

          break;
        }
        case "ArrowRight": {
          const index = sortedChildren.findIndex((child) => child.id === leaf.id);
          const newIndex = (index + 1) % (collection.children?.length || 0);
          const newID = sortedChildren[newIndex].id;

          navigate(`/files/${newID}`, {
            ...location.state,
            preventPlayerAutofocus: true,
          });

          break;
        }
      }
    };

    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [api, navigate, requestStore, leaf, location.state]);

  if (leaf == null || (leaf !== false && leaf.type === "COLLECTION" && !leaf.children)) {
    return (
      <div className={styles.file}>
        <Bar back={location.state?.from || "/files"} />
        <div className={styles.container}>
          <Loader />
        </div>
      </div>
    );
  }

  if (leaf === false) {
    return <div className={styles.error}>File not found!</div>;
  }

  if (leaf.type === "COLLECTION") {
    const collection = leaf as Collection;
    const canListen = (collection.children?.reduce?.((out, child) => out + (child.duration || 0), 0) || 0) > 0;
    const poster =
      leaf.posterURL || leaf.posterFileID || leaf.posterAutoID
        ? `${THEIA_HOST}/leafs/${leaf.posterFileID || leaf.posterAutoID}/theia/card/jpeg`
        : `${env.origin}/images/external.png`;

    return (
      <>
        <Helmet>
          <title>{leaf.name}</title>
          <meta property="og:url" content={`${env.origin}/files/${leaf.id}`} />
          <meta property="og:image" content={poster} />
          <meta property="og:title" content={leaf.name} />
          <meta
            property="og:description"
            content={
              leaf.description
                ? leaf.description
                : "AWKSHARE is an anonymous dropbox for sharing Andrew W.K. related media."
            }
          />
        </Helmet>
        <LeafsList
          HeaderComponent={({ children }) => (
            <>
              <div className={styles.collection}>
                <Bar
                  back={
                    currentPopover
                      ? getBackLinkProps()
                      : {
                          pathname: collection.parentID
                            ? `/files/${collection.parentID}`
                            : location.state?.from || "/files",
                          state: {
                            from: location.state?.from,
                            fileTypeWhitelist: location.state?.fileTypeWhitelist,
                          },
                        }
                  }
                >
                  <BarTitle>{collection.name}</BarTitle>
                  <BarActions>
                    {location.state?.fileTypeWhitelist && (
                      <Button
                        to={{
                          pathname: location.pathname,
                          state: {
                            from: null,
                            fileTypeWhitelist: null,
                          },
                        }}
                        hideAt={ViewportSize.Medium}
                      >
                        Remove {fileTypeNames.get(location.state?.fileTypeWhitelist)} Filter
                      </Button>
                    )}
                    {canListen && (
                      <Button to={`/party/create?from=${leaf.id}`} hideAt={ViewportSize.Small}>
                        Create a Party
                      </Button>
                    )}
                    <BulkDownload parentID={collection.id} />
                  </BarActions>
                </Bar>
                {collection.description && (
                  <div className={styles.collectionDescription}>
                    <div className={styles.inner}>{collection.description}</div>
                  </div>
                )}
              </div>
              {children}
            </>
          )}
          fileTypeWhitelist={location.state?.fileTypeWhitelist}
          leafs={collection.children || null}
        />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{leaf.name || leaf.fileName}</title>
        <meta property="og:url" content={`${env.origin}/files/${leaf.id}`} />
        <meta property="og:title" content={leaf.name || leaf.fileName} />
        <meta
          property="og:description"
          content={
            leaf.description
              ? leaf.description
              : "AWKSHARE is an anonymous dropbox for sharing Andrew W.K. related media."
          }
        />
      </Helmet>
      {leaf.fileType === "AUDIO" ? (
        <Helmet>
          <meta property="og:audio" content={leaf.streamURL || leaf.downloadURL} />
          <meta property="og:image" content={leaf.posterURL || `${THEIA_HOST}/leafs/${leaf.id}/theia/card/jpeg`} />
        </Helmet>
      ) : leaf.fileType === "VIDEO" ? (
        <Helmet>
          <meta property="og:video" content={leaf.streamURL || leaf.downloadURL} />
          <meta property="og:image" content={leaf.posterURL || `${THEIA_HOST}/leafs/${leaf.id}/theia/card/jpeg`} />
        </Helmet>
      ) : leaf.fileType === "IMAGE" ? (
        <Helmet>
          <meta property="og:image" content={leaf.posterURL || `${THEIA_HOST}/leafs/${leaf.id}/theia/card/jpeg`} />
        </Helmet>
      ) : (
        <Helmet>
          <meta property="og:image" content={`${env.origin}/images/external.png`} />
        </Helmet>
      )}
      <div className={styles.file}>
        <Bar
          className={styles.bar}
          back={
            currentPopover
              ? getBackLinkProps()
              : {
                  pathname: leaf.parentID ? `/files/${leaf.parentID}` : location.state?.from || "/files",
                  state: {
                    from: location.state?.from,
                    fileTypeWhitelist: location.state?.fileTypeWhitelist,
                  },
                }
          }
        >
          <BarTitle>{leaf.name}</BarTitle>
          <BarActions>
            {ocrReady && (
              <Button component="label">
                <ButtonLabel>OCR</ButtonLabel>&nbsp;&nbsp;
                <input type="checkbox" name="ocr" checked={ocrEnabled} onChange={onChangeOCRCheckbox} />
              </Button>
            )}
            {/* {file.filetype === FileType.Image && (
              <button className={styles.button} onClick={onToggleHighlighting}>
                Highlight <Pen size={17} />
              </button>
            )} */}
            {leaf.duration != null && leaf.duration > 0 && (
              <Button to={`/party/create?from=${leaf.id}`} hideAt={ViewportSize.Medium}>
                Create a Party
              </Button>
            )}
            <Button component="a" href={leaf.url} target="_blank">
              <ButtonLabel>Open &nbsp;</ButtonLabel>
              <ButtonIcon>
                <IconExternal size={17} />
              </ButtonIcon>
            </Button>
            <Button component="a" href={leaf.downloadURL} target="_blank">
              <ButtonLabel>Download &nbsp;</ButtonLabel>
              <ButtonIcon>
                <IconDownload size={17} />
              </ButtonIcon>
            </Button>
          </BarActions>
        </Bar>
        <div className={styles.container}>
          <div className={styles.preview}>
            <FilePreview
              file={leaf}
              isHighlighting={isHighlighting}
              ocrEnabled={ocrEnabled}
              onEndHighlighting={onEndHighlighting}
              onOCRReady={onOCRReady}
            />
          </div>
          <div className={styles.about}>
            <div className={styles.meta}>
              {leaf.description && <div className={styles.description}>{leaf.description}</div>}
              <div className={styles.date}>
                {leaf.size != null && <>{formatBytes(leaf.size)} &middot; </>}
                Shared on {new Date(leaf.createdAt).toLocaleString()}
              </div>
            </div>
            <div className={styles.comments}>
              <Comments entityType="LEAF" entityID={leaf.id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
