import { useEffect, useState } from "react";
import { Button, ButtonSet } from "components/Button";
import { FileIcon } from "components/FileIcon";
import { IconDownload } from "components/Icons";
import { LeafsList } from "components/LeafsList";
import { Loader } from "components/Loader";
import { useApi } from "contexts/api";
import { Sort } from "shared/leafSort";
import { File, Leaf } from "shared/types";
import styles from "./styles.css";

export interface FilePreviewArchiveProps {
  file: File;
}

export function FilePreviewArchive(props: FilePreviewArchiveProps) {
  const { file } = props;
  const api = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [contents, setContents] = useState<Leaf[] | null>(null);

  useEffect(() => {
    let active = true;

    api.leaf
      .getContents(file.id)
      .then((contents) => {
        if (active) {
          setIsLoading(false);
          setContents(contents);
        }
      })
      .catch(() => {
        if (active) {
          setIsLoading(false);
          setContents(null);
        }
      });

    return () => {
      active = false;
    };
  }, [api.leaf, file?.id]);

  return (
    <div className={styles.component}>
      {isLoading ? (
        <Loader />
      ) : contents ? (
        <>
          <div className={styles.downloadHint}>
            <div className={styles.hint}>This file is an archive, you must download it to access its contents.</div>
            <ButtonSet>
              <div className={styles.actions}>
                <Button component="a" href={file.downloadURL} target="_blank">
                  Download <IconDownload size={17} />
                </Button>
              </div>
            </ButtonSet>
          </div>
          <div className={styles.contents}>
            <LeafsList leafs={contents} showDates={false} saveSort={false} sort={Sort.Name} />
          </div>
        </>
      ) : (
        <FileIcon file={file} />
      )}
    </div>
  );
}
