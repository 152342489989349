import { createContext, useContext } from "react";
import { isMobile } from "shared/utils";

export type Env = {
  isMobile: boolean;
  secure: boolean;
  origin: string;
};

export const EnvContext = createContext<Env>(null);

export function createEnv() {
  return {
    isMobile: isMobile(),
    secure: window.location.protocol === "https:",
    origin: window.location.origin,
  };
}

export function useEnv() {
  return useContext(EnvContext);
}
