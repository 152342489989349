import { PropsWithChildren } from "react";
import styles from "./styles.css";

type ButtonLabelProps = PropsWithChildren;

export function ButtonLabel(props: ButtonLabelProps) {
  const { children } = props;

  return <span className={styles.label}>{children}</span>;
}
