import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { IconPause, IconPlay } from "components/Icons";
import { File } from "shared/types";
import { formatTimeCode, normalizeMime } from "shared/utils";
import { Pill } from "../Pill";

type FileDurationProps = {
  file: File;
};

let currentPlay: HTMLAudioElement | null = null;

export function FileDuration(props: FileDurationProps) {
  const { file } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const isPlayingRef = useRef(false);
  const audio = useRef<HTMLAudioElement | null>(null);
  const time = useRef<HTMLElement | null>(null);
  const timeCode = useRef<string | null>(null);

  const togglePlayback = useCallback(
    (event?: MouseEvent<HTMLDivElement>) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (!isPlayingRef.current) {
        let { current: audioElement } = audio;

        if (!audioElement) {
          if (currentPlay) {
            currentPlay.pause();
            currentPlay = null;
          }

          timeCode.current =
            "duration" in file && file.duration != null
              ? `${formatTimeCode(0)} / ${formatTimeCode(file.duration)}`
              : null;

          audioElement = document.createElement("audio");
          const source = document.createElement("source");

          if (file.mime) {
            source.type = normalizeMime(file.mime);
          }

          const sourceURL = file.streamURL || file.url;

          if (sourceURL) {
            source.src = sourceURL;
          }

          audioElement.appendChild(source);

          audio.current = audioElement;
        }

        audioElement.onpause = () => togglePlayback();
        audioElement.onended = () => togglePlayback();
        audioElement.ontimeupdate = () => {
          if (time.current && audioElement && isPlayingRef.current) {
            timeCode.current = `${formatTimeCode(audioElement.currentTime)} / ${formatTimeCode(
              file.duration || audioElement.duration,
            )}`;

            time.current.innerText = timeCode.current;
          }
        };

        audioElement.play().catch(() => {
          // Ignore
        });

        currentPlay = audioElement;
      } else {
        if (currentPlay === audio.current) {
          currentPlay = null;
        }

        const { current: audioElement } = audio;

        if (audioElement) {
          audioElement.onpause = null;
          audioElement.onended = null;
          audioElement.ontimeupdate = null;
          audioElement.pause();
        }

        audio.current = null;

        if (time.current) {
          time.current.innerText = file.duration != null ? formatTimeCode(file.duration) : "";
        }
      }

      isPlayingRef.current = !isPlayingRef.current;
      setIsPlaying(isPlayingRef.current);
    },
    [file, audio],
  );

  useEffect(() => {
    return () => {
      const { current: audioElement } = audio;

      if (audioElement) {
        if (currentPlay === audioElement) {
          currentPlay = null;
        }

        audioElement.onpause = null;
        audioElement.onended = null;
        audioElement.ontimeupdate = null;
        audioElement.pause();

        audio.current = null;
      }
    };
  }, []);

  return (
    <Pill
      icon={isPlaying ? <IconPause size={5} /> : <IconPlay size={5} />}
      isActive={isPlaying}
      onClick={togglePlayback}
    >
      <span ref={time}>{isPlaying ? timeCode.current : file.duration ? formatTimeCode(file.duration) : null}</span>
    </Pill>
  );
}
