import styles from "./styles.css";

export interface ShareDroppingProps {
  dropping: number;
}

export function ShareDropping(props: ShareDroppingProps) {
  const { dropping } = props;

  if (!dropping) {
    return null;
  }

  return (
    <div className={styles.droppingOverlay}>
      <div className={styles.inner}>
        <div className={styles.title}>{`Dropping ${dropping} file${dropping > 1 ? "s" : ""}...`}</div>
      </div>
    </div>
  );
}
