import { Leaf, TimelineItem } from "./types";

/**
 * This is a bit gross but we're only going to render the app once on a page
 * and we're not server rendering, so eh, it makes for nice and simple code.
 */
export enum View {
  RecentLeafsDeprecated = "recent-leafs-dep",
  RecentLeafs = "recent-leafs",
  HotLeafs = "hot-leafs",
  MostViewedLeafs = "most-viewed-leafs",
  OldestLeafs = "oldest-leafs",
}

export type CacheStruct = {
  views: {
    [View.RecentLeafsDeprecated]?: Leaf[];
    [View.RecentLeafs]?: string[];
    [View.HotLeafs]?: string[];
    [View.MostViewedLeafs]?: string[];
    [View.OldestLeafs]?: string[];
  };
  leafs: {
    [key: string]: Leaf;
  };
  timelineItems: {
    [key: string]: TimelineItem;
  };
};

export type Cache = ReturnType<typeof createCache>;
export type CacheEntityType = keyof CacheStruct;
export type CacheEntityGroup<T extends CacheEntityType> = CacheStruct[T];

export function createCache(init: Partial<CacheStruct> = {}) {
  const cache: CacheStruct = {
    leafs: {
      ...init.leafs,
    },
    timelineItems: {
      ...init.timelineItems,
    },
    views: {
      ...init.views,
    },
  };

  const has = <T extends keyof CacheStruct>(type: T, id: keyof CacheStruct[T]) => !!cache[type][id];
  const get = <T extends keyof CacheStruct, Id extends keyof CacheStruct[T]>(
    type: T,
    id: Id | null,
  ): CacheStruct[T][Id] | null => (id ? cache[type][id] : null);
  const set = <T extends CacheEntityType, Id extends keyof CacheEntityGroup<T>, E extends CacheStruct[T][Id]>(
    type: T,
    id: Id,
    entity: E,
  ) => {
    cache[type][id] = entity;
  };

  return {
    cache,
    has,
    get,
    set,
  };
}
