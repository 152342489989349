import cx from "classnames";
import yna from "public/images/background.png";
import { ChangeEvent, FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router";
import {
  SortStart,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { IconCrossCircle } from "components/Icons";
import { LeafsBrowserOverlay } from "components/LeafsBrowser";
import { Loader } from "components/Loader";
import { SmoothImage } from "components/Smooth";
import { useApi } from "contexts/api";
import { useEvent } from "hooks/useEvent";
import { Sort, sortLeafsInPlace } from "shared/leafSort";
import { File, FileType } from "shared/types";
import { unit } from "ui/theme";
import styles from "./styles.css";

const FILE_URL_REGEX = /^https?:\/\/(?:www\.)?(?:awkshare\.com|localhost:\d+)\/files\/([^/]+)/;
const IMGUR_URL_REGEX = /^https?:\/\/i\.imgur.com\/([^/]+).(?:jpe?g|gif|png)$/;
const BROWSER_COVER_WHITELIST = ["IMAGE"] satisfies FileType[];
const BROWSER_FILE_WHITELIST = ["AUDIO", "VIDEO"] satisfies FileType[];

const SortableFileHandle = SortableHandle(() => (
  <div className={styles.handle}>
    <div className={styles.grip}>
      <div className={styles.l} />
      <div className={styles.l} />
      <div className={styles.l} />
    </div>
  </div>
));

const SortableFileRow = SortableElement(
  ({ onRemove, value: file }: { onRemove: (id: string) => void; value: File }) => (
    <div className={styles.file}>
      <SortableFileHandle />
      <div className={styles.column}>{file.name}</div>
      <div className={styles.remove} onClick={() => onRemove(file.id)}>
        <IconCrossCircle />
      </div>
    </div>
  ),
) as unknown as FC<{
  index: number;
  onRemove: (id: string) => void;
  value: File;
}>;

const SortableList = SortableContainer(({ children }: PropsWithChildren<Record<never, never>>) => {
  return <div className={styles.list}>{children}</div>;
}) as unknown as FC<PropsWithChildren<SortableContainerProps>>;

enum BrowserMode {
  Cover = 1,
  File = 2,
}

export function PartyCreatorPage() {
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [browserMode, setBrowserMode] = useState<BrowserMode | null>(null);
  const fromID = new URLSearchParams(location.search).get("from");
  const [error, setError] = useState<string | null>(null);
  const [cover, setCover] = useState("");
  const [coverFile, setCoverFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const currentFiles = useRef<File[]>([]);
  const [files, setFiles] = useState(currentFiles.current);
  const addFile = (file: File) => {
    if (file.fileType && file.fileType !== "AUDIO" && file.fileType !== "VIDEO") {
      return;
    }

    currentFiles.current = [...currentFiles.current, file];

    setFiles(currentFiles.current);
  };

  const onChangeCover = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value) {
      setCover("");
      return;
    }

    const match = event.currentTarget.value.match(FILE_URL_REGEX);

    if (match) {
      setCover(match[0]);
    } else if (event.currentTarget.value.match(IMGUR_URL_REGEX)) {
      setCover(event.currentTarget.value);
    }
  }, []);

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  }, []);

  const onChangeDate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.currentTarget.value);
  }, []);

  const onChangeTime = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTime(event.currentTarget.value);
  }, []);

  const onSortEnd = useCallback(({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const [item] = currentFiles.current.splice(oldIndex, 1);
    currentFiles.current.splice(newIndex, 0, item);
    currentFiles.current = [...currentFiles.current];
    setFiles(currentFiles.current);
  }, []);

  const onRemove = useCallback((id: string) => {
    currentFiles.current = currentFiles.current.filter((file) => file.id !== id);
    setFiles(currentFiles.current);
  }, []);

  const onClickCreate = useCallback(() => {
    if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      setError("Start date is not valid (must be formatted as yyyy-mm-dd)");
      return;
    }

    if (!time.match(/^\d{2}:\d{2}$/)) {
      setError("Start time is not valid (must be formatted as hh:mm AM|PM)");
      return;
    }

    const dateTs = Date.parse(`${date} ${time}`);

    if (dateTs < Date.now()) {
      setError("The start date/time must be set in the future.");
      return;
    }

    setError(null);

    const state: Parameters<(typeof api)["party"]["create"]>[0] = {
      name,
      date: dateTs,
      coverFileID: coverFile?.id,
      fileIDs: currentFiles.current.map((file) => file.id),
      coverImageURL: null,
      comments: null,
    };

    if (!state.coverFileID && cover) {
      state.coverImageURL = cover;
    }

    setLoading(true);

    api.party
      .create(state)
      .then((res) => {
        res?.id &&
          navigate(`/party/${res.id}`, {
            state: {
              token: res.token,
            },
          });
      })
      .catch(() => {
        setError("Something went wrong, please try again in a few moments.");
        setLoading(false);
      });
  }, [api.party, navigate, name, date, time, cover, coverFile]);

  useEffect(() => {
    if (!cover) {
      setCoverFile(null);
      return;
    }

    const match = cover.match(FILE_URL_REGEX);

    if (match) {
      const [, id] = match;
      const existing = api.cache.get("leafs", id);

      if (existing) {
        setCoverFile(existing as File);
      }

      setLoading(true);
      api.leaf.get(id).then(
        (leaf) => {
          setLoading(false);

          if (leaf?.type === "FILE") {
            setCoverFile(leaf);
          }
        },
        () => {
          setLoading(false);
        },
      );
    } else {
      setCoverFile(null);
    }
  }, [api.cache, api.leaf, cover]);

  useEffect(() => {
    if (fromID) {
      setLoading(true);

      api.leaf.get(fromID).then(
        (leaf) => {
          if (!leaf) {
            setLoading(false);
            return;
          }

          if (leaf.type === "FILE") {
            addFile(leaf as File);
            setLoading(false);
            return;
          }

          setName(`${leaf.name} Party`);

          const files =
            leaf.children?.filter((file) => file.type === "FILE" && file.duration != null && file.duration > 0) || [];

          sortLeafsInPlace(files, Sort.Name, true);

          files.forEach(addFile);

          setLoading(false);
        },
        () => {
          // TODO: Handle err
          setLoading(false);
        },
      );
    }
  }, [api.leaf, fromID]);

  const openAddBrowser = useCallback(() => {
    setBrowserMode(BrowserMode.File);
  }, []);

  const openCoverBrowser = useCallback(() => {
    setBrowserMode(BrowserMode.Cover);
  }, []);

  const onClose = useCallback(() => {
    setBrowserMode(null);
  }, []);

  const onSelectFile = useCallback(
    (file: File) => {
      setBrowserMode(null);

      if (browserMode === BrowserMode.Cover) {
        setCover(`https://www.awkshare.com/files/${file.id}`);
        setCoverFile(file);
      } else {
        addFile(file);
      }
    },
    [browserMode],
  );

  const getHelperDimensions = useEvent((props: SortStart) => {
    const { node } = props;

    if (!node || !(node instanceof HTMLElement)) {
      return {
        height: 0,
        width: 0,
      };
    }

    return {
      height: node.offsetHeight + unit * 2,
      width: node.offsetWidth + unit * 2,
    };
  });

  const disabled = loading || !date || !time || !files.length;
  const coverImageUrl = coverFile?.downloadURL || cover;
  const now = new Date();
  const minDate = `${now.getFullYear()}-${`${now.getMonth() + 1}`.padStart(2, "0")}-${`${now.getDate()}`.padStart(
    2,
    "0",
  )}`;

  return (
    <>
      <Helmet>
        <title>Create a Party</title>
      </Helmet>
      {browserMode != null && (
        <LeafsBrowserOverlay
          fileTypeWhitelist={browserMode === BrowserMode.Cover ? BROWSER_COVER_WHITELIST : BROWSER_FILE_WHITELIST}
          hideDisabled={true}
          onClose={onClose}
          onSelect={onSelectFile}
        />
      )}
      <div className={styles.component}>
        <div className={styles.overflow}>
          <div className={styles.heading}>
            Create an AWKSHARE watch-a-long. Select a start time and files from our archive to get started.
          </div>
          <div className={styles.inner}>
            <div className={styles.fieldSet}>
              <div className={cx(styles.row, styles.configure)}>
                {coverImageUrl && (
                  <div className={cx(styles.field, styles.cover)}>
                    <div
                      className={styles.preview}
                      style={{
                        backgroundImage: `url('${coverImageUrl}')`,
                      }}
                    >
                      <img className={styles.ratio} src="/images/16x9.png" />
                    </div>
                  </div>
                )}
                <div className={cx(styles.field, styles.about)}>
                  <label className={styles.label} htmlFor="name">
                    Name <em>(optional)</em>
                  </label>
                  <input
                    className={styles.input}
                    type="text"
                    name="name"
                    onChange={onChangeName}
                    placeholder="Type a name for the party here..."
                    value={name}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldSet}>
              <div className={styles.row}>
                <div className={cx(styles.date, styles.field)}>
                  <label className={styles.label} htmlFor="date">
                    Start date &amp; time <em>(your timezone)</em>
                  </label>
                  <div className={styles.row}>
                    <input
                      className={styles.input}
                      type="date"
                      name="date"
                      onChange={onChangeDate}
                      pattern="\d{4}-\d{2}-\d{2}"
                      placeholder="yyyy-mm-dd"
                      value={date}
                      min={minDate}
                    />
                    <input
                      className={styles.input}
                      type="time"
                      name="time"
                      onChange={onChangeTime}
                      pattern="\d{2}:\d{2}"
                      placeholder="--:--"
                      value={time}
                    />
                  </div>
                </div>
                <div className={cx(styles.cover, styles.field)}>
                  <label className={styles.label} htmlFor="date">
                    Cover Image <em>(optional)</em>
                  </label>
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.input}
                      type="text"
                      onChange={onChangeCover}
                      placeholder="Paste an AWKSHARE or imgur url here..."
                      value={cover}
                    />
                    <div className={styles.browserTrigger} onClick={openCoverBrowser}>
                      Open AWKSHARE browser
                    </div>
                  </div>
                </div>
              </div>
              {error && <div className={styles.error}>{error}</div>}
            </div>
            <div className={cx(styles.tracks)}>
              <div className={styles.table}>
                <div className={styles.header}>
                  <div className={styles.handle} />
                  <div className={styles.column}>Name</div>
                </div>
                {files.length > 0 ? (
                  <SortableList
                    axis="y"
                    lockAxis="y"
                    helperClass={styles.helper}
                    getHelperDimensions={getHelperDimensions}
                    // onRemove={onRemove}
                    onSortEnd={onSortEnd}
                    lockOffset="0%"
                    lockToContainerEdges={true}
                    useDragHandle={true}
                  >
                    {files.map((value, index) => (
                      <SortableFileRow key={value.id} index={index} onRemove={onRemove} value={value} />
                    ))}
                  </SortableList>
                ) : fromID && !files.length && loading ? (
                  <Loader />
                ) : (
                  <div className={styles.empty}>No files selected.</div>
                )}
                <div className={styles.footer}>
                  <button className={styles.button} onClick={openAddBrowser}>
                    + Add File
                  </button>
                </div>
              </div>
            </div>
            <div className={cx(styles.row, styles.actions)}>
              <button
                className={cx(styles.create, disabled && styles.disabled)}
                disabled={disabled}
                onClick={onClickCreate}
              >
                Create Party
              </button>
            </div>
          </div>
        </div>
        <div className={styles.art}>
          <SmoothImage url={yna} offset="0" />
        </div>
      </div>
    </>
  );
}
