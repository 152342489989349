import { PropsWithChildren } from "react";
import styles from "./styles.css";

export type BarTitleProps = PropsWithChildren;

export function BarTitle(props: BarTitleProps) {
  const { children } = props;

  return <div className={styles.title}>{children}</div>;
}
