import cx from "classnames";
import yna from "public/images/backgrounds/yna.jpg";
import { Button, ButtonSet } from "components/Button";
import { SmoothImage } from "components/Smooth";
import { useUploaderCreator } from "./hooks";
import { ShareFileItem } from "./ShareFileItem";
import styles from "./styles.css";

export function ShareCreator() {
  const {
    description,
    error,
    files,
    isUploading,
    openFileBrowser,
    progress,
    props,
    quotaExceeded,
    removeFile,
    resetState,
    start,
    title,
  } = useUploaderCreator();

  const isInCreator = files.length > 0;

  // useEffect(() => {
  //   const rootElement = document.querySelector(`#${appStyles.component}`);

  //   if (!rootElement) {
  //     return;
  //   }

  //   if (isInCreator) {
  //     rootElement.classList.add(appStyles.isInCreator);
  //   } else {
  //     rootElement.classList.remove(appStyles.isInCreator);
  //   }
  // }, [isInCreator]);

  if (!isInCreator) {
    return null;
  }

  return (
    <div className={styles.creator}>
      <div className={styles.upload}>
        <div className={styles.uploadInner}>
          {files.length > 1 && (
            <div className={styles.row}>
              <label className={styles.label} htmlFor="title">
                Title
              </label>
              <input
                className={styles.input}
                disabled={isUploading}
                name="title"
                type="text"
                onChange={props.onChangeTitle}
                placeholder={`Type a name for this ${
                  files.length === 1 ? "file" : "collection of files"
                } here (optional)...`}
                value={title}
                autoFocus={true}
              />
            </div>
          )}
          <div className={styles.filesRow} onClick={files.length === 0 ? openFileBrowser : undefined}>
            <div className={cx(styles.row, styles.files)}>
              <div className={styles.title}>
                1 File{files.length > 1 ? "s" : ""} Selected
                {!isUploading && (
                  <button className={styles.action} onClick={openFileBrowser}>
                    + Add more files
                  </button>
                )}
              </div>
              <div className={styles.items}>
                {files.map((file) => (
                  <ShareFileItem key={file.id} file={file} removeFile={removeFile} disabled={isUploading} />
                ))}
              </div>
            </div>
          </div>
          {files.length > 0 && (
            <div className={styles.row}>
              <label className={styles.label} htmlFor="desc">
                Description
              </label>
              <textarea
                className={styles.input}
                disabled={isUploading}
                name="desc"
                rows={3}
                onChange={props.onChangeDescription}
                placeholder={`Type a description for this ${
                  files.length === 1 ? "file" : "collection of files"
                } here (optional)...`}
                value={description}
              />
            </div>
          )}
          {error && <div className={styles.error}>Oops! An error occurred during upload. Please try again later.</div>}
        </div>
        {files.length > 0 && (
          <div className={cx(styles.row, styles.submit)}>
            {isUploading ? (
              <div className={styles.progress}>
                <div className={styles.text}>{progress === 100 ? "Processing..." : `${progress}% uploaded`}</div>
                <div className={styles.progressInner} style={{ width: `${progress}%` }} />
              </div>
            ) : (
              <div className={styles.warn}>Remember — all uploads are public!</div>
            )}
            <ButtonSet>
              <Button disabled={isUploading} size="medium" onClick={resetState}>
                Cancel
              </Button>
              <Button
                color="green"
                disabled={isUploading || quotaExceeded || false}
                isWorking={isUploading}
                onClick={start}
                size="medium"
              >
                {isUploading ? "Uploading..." : quotaExceeded ? "Upload exceeds quota" : "Upload"}
              </Button>
            </ButtonSet>
          </div>
        )}
      </div>
      <SmoothImage className={styles.bg} url={yna} offset="25%" opacity={0.25} />
    </div>
  );
}
