// cSpell:ignore bandcamp deezer babalon
import cx from "classnames";
import sixteenByNine from "public/images/god-is-partying/16x9.png";
import alice from "public/images/god-is-partying/alice@2x.png";
import cover from "public/images/god-is-partying/cover.jpg";
import cover2X from "public/images/god-is-partying/cover@2x.jpg";
import creation from "public/images/god-is-partying/creation-full@2x.jpg";
import discordBlackAndWhite from "public/images/god-is-partying/discord-bw.png";
import discord from "public/images/god-is-partying/discord.webm";
import eyesWideShut from "public/images/god-is-partying/ews@2x.png";
import heh from "public/images/god-is-partying/heh.png";
import iWantToSeeYouGoWild from "public/images/god-is-partying/iwtsygw-trim.mp4";
import logo from "public/images/god-is-partying/logo@2x.png";
import mask from "public/images/god-is-partying/mask@2x.png";
import rco from "public/images/god-is-partying/rco@2x.png";
import sinner from "public/images/god-is-partying/sinner.mp4";
import throne from "public/images/god-is-partying/throne.png";
import wolf from "public/images/god-is-partying/wolf@2x.png";
import { MouseEvent, useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Countdown } from "./Countdown";
import { Cube } from "./Cube";
import { OTTO } from "./OTTO";
import styles from "./styles.css";

const supportsWebM = false; /*(() => {
  if (window.document?.createElement) {
    return false;
  }

  const video = document.createElement('video');
  return video.canPlayType('video/webm; codecs="vp8, vorbis"') && (!window.navigator.userAgent.includes('Safari') || window.navigator.userAgent.includes('Chrome'));
})();*/

const Outlets = [
  {
    id: "napalm",
    href: "https://www.napalmrecordsamerica.com/andrewwk",
    label: "Napalm Records",
    className: styles.napalm,
  },
  {
    id: "bandcamp",
    href: "https://andrewwk.bandcamp.com/",
    label: "Bandcamp",
    className: styles.bandcamp,
  },
  {
    id: "apple",
    href: "https://geo.music.apple.com/us/album/god-is-partying/1564771291?itsct=music_box_link&itscg=30200&ct=albums_god_is_partying&ls=1&app=music&at=11lEW&ct=0n793t",
    label: "Apple Music",
    className: styles.apple,
  },
  {
    id: "itunes",
    href: "https://geo.music.apple.com/us/album/god-is-partying/1564771291?itsct=music_box_link&itscg=30200&ct=albums_god_is_partying&ls=1&app=itunes&at=11lEW&ct=0n793t",
    label: "iTunes",
    className: styles.itunes,
  },
  {
    id: "spotify",
    href: "https://open.spotify.com/album/61aVLxae72Zy3bYODxdUwk",
    label: "Spotify",
    className: styles.spotify,
  },
  {
    id: "amazon",
    href: "https://www.amazon.com/God-Partying-White-Vinyl-Poster/dp/B094KF3YRH/ref=tmm_vnl_swatch_0?_encoding=UTF8&qid=&sr=&tag=smarturl-pivot-20",
    label: "Amazon",
    className: styles.amazon,
  },
  {
    id: "deezer",
    href: "https://www.deezer.com/en/album/242518052",
    label: "Deezer",
    className: styles.deezer,
  },
  {
    id: "youtube",
    href: "https://music.youtube.com/playlist?list=OLAK5uy_nAwUXOfWxmx6h8gFPnef1Vih-IH23wy3A",
    label: "YouTube",
    className: styles.youtube,
  },
  {
    id: "awk",
    href: "https://andrewwk.com/",
    label: "Official Store",
    className: styles.awk,
  },
] as {
  id: string;
  href: string;
  label: string;
  className: string;
}[];

export function GodIsPartying() {
  const sinnerRef = useRef<HTMLVideoElement>(null);
  const rewindRef = useRef(null);
  const [hoveringDiscord, setHoveringDiscord] = useState(false);
  const onEnterDiscord = useCallback(() => setHoveringDiscord(true), []);
  const onLeaveDiscord = useCallback(() => setHoveringDiscord(false), []);
  const onClickDiscord = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    if (window.screen.width < 1080) {
      event.preventDefault();
      setHoveringDiscord(true);
    }
  }, []);

  // const onSinnerCanPlayThrough = useCallback(() => {
  //   sinnerRef.current.currentTime = sinnerRef.current.duration;
  // }, [])

  function rewind() {
    if (rewindRef.current || sinnerRef.current.currentTime === 0) {
      return;
    }

    const start = Date.now();
    const duration = sinnerRef.current.duration / 3;
    const onSeeked = () => {
      if (sinnerRef.current.currentTime > 0) {
        sinnerRef.current.currentTime = duration - (Date.now() - start) / 1000;
      } else {
        rewindRef.current();
      }
    };

    sinnerRef.current.addEventListener("seeked", onSeeked);
    sinnerRef.current.currentTime = duration - (Date.now() - start) / 1000;

    rewindRef.current = () => {
      sinnerRef.current.removeEventListener("seeked", onSeeked);
      rewindRef.current = null;
    };
  }

  // const onSinnerEnded = useCallback(rewind, []);

  const onMouseEnter = useCallback(() => {
    if (rewindRef.current) {
      rewindRef.current();
    }

    sinnerRef.current.play().catch(() => {
      // ignore
    });
  }, []);

  const onMouseLeave = useCallback(() => {
    sinnerRef.current.pause();
    rewind();
  }, []);

  return (
    <>
      <Helmet defaultTitle="GOD IS PARTYING" titleTemplate="%s | GOD IS PARTYING">
        <meta name="description" content="GOD IS PARTYING - The new album from Andrew W.K., coming September 10th" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@awkshare" />
        <meta name="twitter:creator" content="@awkshare" />
      </Helmet>
      <div className={styles.component}>
        <div className={styles.scroll}>
          <div className={styles.gradient} />
          <a className={styles.credit} href="https://www.awkshare.com">
            ← AWKSHARE
          </a>
          <div className={styles.creation}>
            <div className={styles.inner}>
              <img className={styles.logo} alt="ANDREW W.K." width={600} src={logo} />
              <div className={styles.gip}>God Is Partying</div>
              <Countdown />
              <Cube />
            </div>
            <div className={styles.overlay} />
            <img className={styles.cover} src={creation} />
          </div>
          <div className={styles.babalon}>
            <img className={styles.heh} src={heh} />
            <iframe
              className={styles.embed}
              width="700"
              height="370"
              src="https://www.youtube.com/embed/oI8y36733ag?rel=0&modestbranding=1&playsinline=1"
              title="Babalon Music Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            />
            <div className={cx(styles.discord, hoveringDiscord && styles.hovering)}>
              <a
                className={styles.invite}
                href="https://discord.gg/KCfUtc78Hr"
                onMouseEnter={onEnterDiscord}
                onMouseLeave={onLeaveDiscord}
                onClick={onClickDiscord}
                title="Discord"
                target="_blank"
                rel="noreferrer"
              />
              {supportsWebM ? (
                <>
                  <video
                    className={styles.color}
                    src={discord}
                    width={200}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    loop={true}
                  />
                  <video
                    src={discordBlackAndWhite}
                    width={200}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    loop={true}
                  />
                </>
              ) : (
                <>
                  <img className={styles.color} src={discord} width={200} />
                  <img src={discordBlackAndWhite} width={200} />
                </>
              )}
            </div>
            <img className={styles.alice} src={alice} />
          </div>
          <div className={styles.iwtsygwC}>
            <video
              className={styles.iwtsygw}
              src={iWantToSeeYouGoWild}
              autoPlay={true}
              muted={true}
              playsInline={true}
              loop={true}
            />
          </div>
          <div className={styles.heaven}>
            <Cube size={75 * 2} dir={-1} />
            <div className={styles.ews}>
              <img className={styles.mirror} width={396} height={554} src={eyesWideShut} />
              <div className={styles.mask}>
                <a
                  className={styles.sinner}
                  href="https://www.andrewwk.com?ref=https://www.godispartying.com"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <video
                    className={styles.sinnerVideo}
                    src={sinner}
                    muted={true}
                    playsInline={true}
                    // onEnded={onSinnerEnded}
                    ref={sinnerRef}
                    // onCanPlayThrough={onSinnerCanPlayThrough}
                  />
                </a>
              </div>
            </div>
            {/* <img
              className={styles.frame}
              src={require('public/images/god-is-partying/frame-rb.png').default}
            /> */}
            <iframe
              className={styles.embed}
              width="700"
              height="370"
              src="https://www.youtube.com/embed/mj8C7hKpBNA?rel=0&modestbranding=1&playsinline=1"
              title="I'm In Heaven Music Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            />
            <img className={styles.mask} src={mask} />
            <img className={styles.wolf} src={wolf} />
          </div>
          <div className={styles.sins}>
            <img className={styles.rco} src={rco} />
            <img className={styles.throne} src={throne} />
            <div className={styles.embed}>
              <iframe
                className={styles.video}
                src="https://www.youtube.com/embed/Yk8wi4XcQgk?rel=0&modestbranding=1&playsinline=1"
                title="Everybody Sins Music Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              />
              <img className={styles.ratio} src={sixteenByNine} />
            </div>
          </div>
          <div className={styles.footer}>
            <OTTO />
            <div className={styles.inner}>
              <label>
                {"   "}
                <em>GOD IS PARTYING</em>
                {"   "}
              </label>
              <div className={styles.links}>
                {Outlets.map((outlet) => (
                  <a
                    key={outlet.id}
                    className={cx(styles.link, outlet.className)}
                    href={outlet.href}
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    {outlet.label}
                  </a>
                ))}
              </div>
            </div>
            <div className={styles.notice}>
              <a href="/">GODISPARTYING.COM</a> is an <strong>unofficial</strong> website with{" "}
              <strong>no affiliation</strong> to the Louise Harland Corporation
            </div>
            <div className={styles.footerCover}>
              <div className={styles.overlay} />
              <img className={styles.cover} srcSet={[`${cover2X} 2880vw`, `${cover} 1440vw`].join(", ")} src={cover} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
