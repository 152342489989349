import { ElementType, MouseEvent, memo } from "react";
import { Link, LinkProps } from "react-router-dom";
import { FileIcon } from "components/FileIcon";
import { IconComments, IconPlay } from "components/Icons";
import { SmoothImage } from "components/Smooth";
import { useEvent } from "hooks/useEvent";
import { usePopoverNavigation } from "hooks/usePopoverNavigation";
import { THEIA_HOST } from "shared/api";
import { splitFilename } from "shared/leafSort";
import { File, FileType, Leaf } from "shared/types";
import { formatBytes } from "shared/utils";
import { CollectionPreview } from "../CollectionPreview";
import { CollectionStats } from "../CollectionStats";
import { FileDuration } from "../FileDuration";
import styles from "./styles.css";

// cSpell:ignore mpegurl
const fileTypePreviewMimeBlockedList = new Set(["audio/mpegurl"]);

interface LeafsListCardProps {
  leaf: Leaf;
  disabled?: boolean;
  fileTypeWhitelist?: FileType[] | null;
  onPress?: (leaf: Leaf) => void;
  showComments?: boolean;
  showDate?: boolean;
  showOCR?: boolean;
  showViewCounts?: boolean;
  to?: LinkProps["to"];
  toState?: LinkProps["state"];
}

export function BaseLeafsListCard(props: LeafsListCardProps) {
  const {
    disabled,
    fileTypeWhitelist,
    leaf,
    onPress,
    showComments = true,
    showDate = true,
    showViewCounts = true,
    to,
    toState,
  } = props;
  const { getLinkProps } = usePopoverNavigation();

  const onClick = useEvent((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onPress?.(leaf);
  });

  if (disabled) {
    return null;
  }

  const [fileName, extension] = splitFilename(leaf.name);
  const date = new Date(leaf.updatedAt || leaf.createdAt);
  const dateAndTimeStr =
    showDate !== false &&
    date.toLocaleString("en-us", {
      dateStyle: "short",
      timeStyle: "short",
    });

  const isLink = !onPress && leaf.id;
  const Component: ElementType = isLink ? Link : "div";
  const componentProps = isLink && to ? getLinkProps(to, toState) : {};

  return (
    <Component
      {...componentProps}
      className={styles.component}
      onClick={onPress ? onClick : undefined}
      title={leaf.name || (leaf as File).fileName}
    >
      <div className={styles.border}>
        <div className={styles.preview}>
          <div className={styles.stats}>
            {showComments && leaf.commentsCount != null && leaf.commentsCount > 0 && (
              <div className={styles.comments}>
                <IconComments size={16} /> <span className={styles.commentsCount}>{leaf.commentsCount}</span>
              </div>
            )}
            {"duration" in leaf && leaf.duration != null && leaf.duration > 0 && <FileDuration file={leaf as File} />}
            {leaf.type === "COLLECTION" && (
              <CollectionStats fileTypeWhitelist={fileTypeWhitelist} childFiletypes={leaf.childFileTypes} />
            )}
          </div>
          {leaf.type === "FILE" ? (
            ["AUDIO", "IMAGE", "VIDEO"].includes((leaf as File).fileType) &&
            (!leaf.mime || !fileTypePreviewMimeBlockedList.has(leaf.mime)) ? (
              <>
                {leaf.fileType === "VIDEO" && (
                  <div className={styles.play}>
                    <IconPlay size={48} />
                  </div>
                )}
                <SmoothImage
                  className={styles.imagePreview}
                  opacity={0.5}
                  url={[
                    {
                      type: "image/webp",
                      url: `${THEIA_HOST}/leafs/${leaf.id}/theia/card/webp`,
                    },
                    {
                      type: "image/jpeg",
                      url: `${THEIA_HOST}/leafs/${leaf.id}/theia/card/jpeg`,
                    },
                  ]}
                >
                  <FileIcon file={leaf as File} />
                </SmoothImage>
              </>
            ) : (
              <FileIcon className={styles.fileIcon} file={leaf as File} />
            )
          ) : leaf.posterURL || leaf.posterFileID || leaf.posterAutoID ? (
            <>
              {/* <CollectionPreview
                id={leaf.name}
                // fileTypeWhitelist={fileTypeWhitelist}
                // childFiletypes={leaf.child_filetypes}
                hasGradient={false}
              /> */}
              <SmoothImage
                className={styles.imagePreview}
                opacity={0.5}
                url={
                  leaf.posterURL
                    ? leaf.posterURL
                    : [
                        {
                          type: "image/webp",
                          url: `${THEIA_HOST}/leafs/${leaf.posterFileID || leaf.posterAutoID}/theia/card/webp`,
                        },
                        {
                          type: "image/jpeg",
                          url: `${THEIA_HOST}/leafs/${leaf.posterFileID || leaf.posterAutoID}/theia/card/jpeg`,
                        },
                      ]
                }
              />
            </>
          ) : (
            <CollectionPreview
              className={styles.collectionPreview}
              id={leaf.name}
              // fileTypeWhitelist={fileTypeWhitelist}
              // childFiletypes={leaf.child_filetypes}
            />
          )}
        </div>
        {leaf.type === "COLLECTION" && (
          <div className={styles.stacks}>
            <div className={styles.stack}></div>
            <div className={styles.stack}></div>
          </div>
        )}
        <div className={styles.info}>
          {leaf.type === "FILE" && <FileIcon className={styles.type} file={leaf} size={24} />}
          <div className={styles.vStack}>
            <div className={styles.name}>
              <span className={styles.fileName}>{fileName}</span>
              {extension && <span className={styles.suffix}>.{extension}</span>}
            </div>
            <div className={styles.meta}>
              {leaf.type === "FILE" && leaf.size != null && leaf.size > 0 && (
                <>
                  {formatBytes(leaf.size)}
                  {dateAndTimeStr && <> &middot; </>}
                </>
              )}
              {dateAndTimeStr}
              {showViewCounts && leaf.viewsCount != null && (
                <>
                  {dateAndTimeStr && <> &middot; </>}
                  {leaf.viewsCount.toLocaleString()} view{leaf.viewsCount === 0 || leaf.viewsCount > 1 ? "s" : ""}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Component>
  );
}

export const LeafsListCard = memo(BaseLeafsListCard);
