import { useLayoutEffect, useRef } from "react";

export function useValueAsRef<T>(value: T) {
  const valueRef = useRef(value);

  if (typeof window !== "undefined") {
    // This looks unsafe as hooks shouldn't be called in conditionals,
    // but it's OK in this case as it's always consistent between renders.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      valueRef.current = value;
    });
  }

  return valueRef;
}
