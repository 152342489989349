import { PropsWithChildren, createContext, useContext } from "react";
import { Scrubber } from "./createScrubber";

export const ScrubberContext = createContext<Scrubber>(null);

type ScrubberProviderProps = PropsWithChildren<{
  scrubber: Scrubber;
}>;

export function ScrubberProvider(props: ScrubberProviderProps) {
  const { scrubber, ...otherProps } = props;
  return <ScrubberContext.Provider value={scrubber} {...otherProps} />;
}

export function useScrubberContext() {
  return useContext(ScrubberContext);
}
