import { API_HOST, type Api } from "shared/api";
import type { Collection, File as FileLeaf, Leaf } from "shared/types";

export type OnUploadProgress = (progress: number) => void;

type PromiseWithProgress = Promise<FileLeaf | FileLeaf[]> & {
  progress: number;
  onProgress: (fn: OnUploadProgress) => () => void;
};

export type UploadOp = ReturnType<typeof uploadFiles>;

export function uploadFiles(
  api: Api,
  files: File[],
  name: string | null,
  description: string | null,
  parentID?: string,
  persistUploads?: boolean,
) {
  const listeners: OnUploadProgress[] = [];
  const uploadPromise = new Promise<Leaf | Leaf[] | null>((resolve, reject) =>
    (async () => {
      const setProgress: OnUploadProgress = (progress) => {
        uploadPromise.progress = progress;
        listeners.forEach((fn) => fn(progress));
      };

      const onBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", onBeforeUnload);

      try {
        let collection: Collection | null = null;

        if (files.length > 1 && !parentID) {
          collection = await api.collection.create(name, description);
          parentID = collection?.id;
        }

        const progress = new Array(files.length).fill(0);
        const setUploadProgress = (index: number, percent: number) => {
          progress[index] = percent;
          setProgress(
            Number(((progress.reduce((out, total) => out + total, 0) / (progress.length * 100)) * 100).toFixed(2)),
          );
        };

        const fileLeafs: FileLeaf[] = [];

        await files.reduce(
          (promise, file, index) =>
            promise.then(
              () =>
                new Promise((resolve, reject) => {
                  const form = new FormData();

                  form.append("file", file);

                  if (!collection && name) {
                    form.append("name", name);
                  }

                  if (description) {
                    form.append("description", description);
                  }

                  const xhr = new XMLHttpRequest();
                  const url = `${API_HOST}/upload?size=${file.size}${parentID ? `&parentID=${parentID}` : ""}${
                    persistUploads === false ? "&persist=0" : ""
                  }`;

                  xhr.open("POST", url);
                  xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                      setUploadProgress(index, (event.loaded / event.total) * 100);
                    }
                  };

                  xhr.onload = () => {
                    try {
                      const res = JSON.parse(xhr.responseText);

                      if (res.status === 0) {
                        reject(new Error("failed"));
                        return;
                      }

                      fileLeafs.push(res.response);
                      resolve(null);
                    } catch (err) {
                      reject(err);
                    }
                  };

                  xhr.onerror = () => {
                    reject(new Error("failed"));
                  };

                  xhr.send(form);
                }),
            ),
          Promise.resolve(null),
        );

        if (!collection) {
          resolve(fileLeafs.length === 1 ? fileLeafs[0] : fileLeafs);
          return;
        }

        const result = api.collection.publish(collection.id);

        resolve(result);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        reject(err);
      } finally {
        window.removeEventListener("beforeunload", onBeforeUnload);
      }
    })(),
  ) as PromiseWithProgress;

  uploadPromise.progress = 0;
  uploadPromise.onProgress = (fn) => {
    let active = true;

    listeners.push(fn);

    return () => {
      if (active) {
        active = false;
        listeners.splice(listeners.indexOf(fn), 1);
      }
    };
  };

  return uploadPromise;
}
