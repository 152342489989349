import cx from "classnames";
import { Children, PropsWithChildren, ReactElement } from "react";
import { LinkProps } from "react-router-dom";
import { Button, ButtonIcon, ButtonLabel } from "components/Button";
import { BarActions } from "./BarActions";
import { BarTitle } from "./BarTitle";
import styles from "./styles.css";

type BarProps = PropsWithChildren<{
  back?: ((LinkProps["to"] & Pick<LinkProps, "state">) | (() => void)) | null;
  className?: string;
}>;

export function Bar(props: BarProps) {
  const { back, className, children } = props;
  const childArray = Children.toArray(children) as ReactElement[];
  const title = childArray.find(({ type }) => type === BarTitle);
  const actions = childArray.find(({ type }) => type === BarActions);
  const hasChildrenTypes = title != null || actions != null;

  return (
    <div className={cx(styles.component, className)}>
      {back &&
        (typeof back === "function" ? (
          <Button onClick={back}>
            <ButtonIcon>←</ButtonIcon>
            <ButtonLabel>&nbsp;Back</ButtonLabel>
          </Button>
        ) : (
          <Button to={back}>
            <ButtonIcon>←</ButtonIcon>
            <ButtonLabel>&nbsp;Back</ButtonLabel>
          </Button>
        ))}
      {hasChildrenTypes ? (
        <>
          {title}
          {actions}
        </>
      ) : (
        children
      )}
    </div>
  );
}
