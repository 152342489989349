import cx from "classnames";
import { HTMLAttributes } from "react";
import styles from "./styles.css";

type GodIsPartyingBannerProps = Pick<HTMLAttributes<HTMLDivElement>, "children" | "className">;

export function GodIsPartyingBanner(props: GodIsPartyingBannerProps) {
  const { children, className } = props;

  return (
    <a
      className={cx(className, styles.godIsPartying)}
      href="https://www.godispartying.com"
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.copy}>
        <div className={styles.inner}>GOD IS PARTYING</div>
        <div className={cx(styles.inner, styles.date)}>SEPTEMBER 10TH</div>
      </div>
      {children}
    </a>
  );
}
