import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconExpandLine = forwardRef<SVGSVGElement, IconProps>(function IconExpandLine(props, ref) {
  const { size = 16, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M6.87,9.97l-4.89,4.9C2.17,14.96,2.38,15,2.61,15h3.91c0.28,0,0.5,0.22,0.5,0.5S6.79,16,6.51,16H2.61C1.17,16,0,14.82,0,13.37V9.51c0-0.28,0.22-0.5,0.5-0.5S1,9.23,1,9.51v3.86c0,0.23,0.05,0.45,0.13,0.65l4.89-4.89c0.23-0.23,0.61-0.23,0.85,0C7.1,9.36,7.1,9.74,6.87,9.97z M13.4,0H9.5C9.23,0,9,0.22,9,0.5S9.23,1,9.5,1h3.89c0.22,0,0.43,0.05,0.63,0.13L9.09,6.06c-0.23,0.23-0.23,0.61,0,0.85c0.12,0.12,0.27,0.18,0.42,0.18s0.31-0.06,0.42-0.18l4.93-4.93C14.95,2.18,15,2.4,15,2.63V6.5C15,6.78,15.23,7,15.5,7S16,6.78,16,6.5V2.63C16,1.18,14.83,0,13.4,0z" />
    </svg>
  );
});
