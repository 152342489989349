import { useCallback } from "react";
import { useApi } from "contexts/api";
import { openWindowInCenter } from "shared/utils";
import { SetTokensAndUserAction } from "./types";
import { useAuthState } from "./useAuthState";
import { useAuthStateDispatcher } from "./useAuthStateDispatcher";

export const DiscordAuthComplete = "DiscordAuthComplete";
export const DiscordAuthFailed = "DiscordAuthFailed";

export function useAuthFlow() {
  const api = useApi();
  const state = useAuthState();
  const dispatch = useAuthStateDispatcher();
  const startAuthFlow = useCallback(async () => {
    if (state.accessToken || state.isAuthenticating) {
      return;
    }

    dispatch({
      type: "StartAuthFlow",
    });

    let isActive = true;
    let closeCheckInterval: NodeJS.Timeout | null = null;
    let newWindow: Window;

    const cleanup = () => {
      clearInterval(closeCheckInterval);
      isActive = false;
      window.removeEventListener("message", onMessage, false);
      window.removeEventListener("beforeunload", onBeforeUnload);
    };

    const completeFlow = (auth: SetTokensAndUserAction["data"]) => {
      cleanup();
      newWindow?.close();
      newWindow = null;
      dispatch({
        type: "SetTokensAndUser",
        data: auth,
      });
    };

    const cancelFlow = () => {
      cleanup();
      newWindow?.close();
      newWindow = null;
      dispatch({
        type: "EndAuthFlow",
      });
    };

    const { state: redirectState, url } = await api.auth.getDiscordAuthRedirectURL();
    const onMessage = (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        if (event.data?.state === redirectState) {
          if (event.data?.type === DiscordAuthComplete) {
            completeFlow(event.data.data);
          } else if (event.data.type === DiscordAuthFailed) {
            cancelFlow();
          }
        }
      }
    };

    window.addEventListener("message", onMessage, false);

    newWindow = openWindowInCenter(url, "Sign in to AWKSHARE with Discord", 400, 800);
    closeCheckInterval = setInterval(() => {
      if (newWindow.closed) {
        if (isActive) {
          cancelFlow();
        }
      }
    }, 500);

    const onBeforeUnload = () => {
      if (isActive) {
        newWindow.close();
      }
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    if (window.focus) {
      newWindow.focus();
    }
  }, [api.auth, dispatch, state]);

  return {
    state,
    startAuthFlow,
  };
}
