import cx from "classnames";
import { useCallback } from "react";
import { FileIcon } from "components/FileIcon";
import { formatBytes } from "shared/utils";
import { FileWithID } from "./hooks";
import styles from "./styles.css";

export interface ShareFileItemProps {
  disabled: boolean;
  file: FileWithID;
  removeFile?(id: number): void;
}

export function ShareFileItem(props: ShareFileItemProps) {
  const { disabled, file, removeFile } = props;
  const onClickRemove = useCallback(() => file.id && removeFile?.(file.id), [file, removeFile]);

  return (
    <div className={cx(styles.item, !disabled && styles.removable)}>
      {!disabled && (
        <div className={styles.remove} onClick={onClickRemove}>
          Remove
        </div>
      )}
      <div className={styles.inner}>
        <FileIcon
          file={{
            name: file.name,
            fileType: null,
          }}
        />
        <div className={styles.name}>{file.name}</div>
        <div className={styles.size}>{formatBytes(file.size)}</div>
      </div>
    </div>
  );
}
