import { createContext, useContext } from "react";
import { JSONValue } from "../shared/types";
import { Env } from "./env";

export const RequestStoreContext = createContext<RequestStore | null>(null);

export enum RequestStoreKey {
  Auth = "auth",
  LeafListSortType = "leaf-list-sort-type",
  LeafListSortAscending = "leaf-list-sort-ascending",
  LeafListView = "leaf-list-view",
  Banners = "banners",
  Volume = "volume",
}

export type RequestStore = ReturnType<typeof createRequestStore>;

export type RequestStoreState = {
  [key in RequestStoreKey]?: JSONValue;
};

const CookieName = "_awkstore"; // cSpell:ignore awkstore

function b64EncodeUnicode(str: string) {
  return btoa(encodeURIComponent(str));
}

export function useRequestStore() {
  return useContext(RequestStoreContext);
}

export function createRequestStore(env: Env, baseState: RequestStoreState = {}) {
  const state: RequestStoreState = {
    ...baseState,
  };

  const serialize = () => {
    const json = JSON.stringify(state);
    return b64EncodeUnicode(json);
  };

  function write() {
    const domain = new URL(env.origin).hostname.split(".").slice(-2).join(".");

    // Kill old (root domain) cookie
    document.cookie = `${CookieName}=;path=/;max-age=-99999999`;

    const parts = [
      [CookieName, encodeURIComponent(serialize())].join("="),
      ["path", "/"].join("="),
      ["domain", `.${domain}`].join("="),
      ["max-age", 60 * 60 * 24 * 365].join("="),
      env.secure ? "secure" : undefined,
      "samesite", // cSpell:ignore samesite
    ].filter(Boolean);

    document.cookie = parts.join(";");
  }

  function get<T extends JSONValue = JSONValue>(key: RequestStoreKey, defaultValue: T): T {
    return (state[key] ?? defaultValue) as T;
  }

  function set(key: RequestStoreKey, value: JSONValue) {
    state[key] = value;
    write();
  }

  return {
    get,
    set,
  };
}
