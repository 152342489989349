import { Helmet } from "react-helmet";
import styles from "./styles.css";

export function AboutPage() {
  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <div className={styles.component}>
        <div className={styles.inner}>
          <div className={styles.border}>
            <p className={styles.body}>
              <em>AWKSHARE</em> is a free, non-profit platform dedicated to archiving the work and wider media
              surrounding the musician{" "}
              <a href="https://www.andrewwk.com" target="_blank" rel="noopener noreferrer">
                Andrew W.K.
              </a>
            </p>
            <p className={styles.body}>
              Initially created by an ardent fan in 2004, <em>AWKSHARE</em> unfortunately closed down some years later.
              However, a new website, with new ownership, was launched in its name in early 2021 to honor the archival
              work committed in the past and help continue it in to the future.
            </p>
            <p className={styles.body}>
              <i>LONG LIVE THE PARTY. THE PARTY NEVER DIES.</i>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
