import cx from "classnames";
import { HTMLAttributes } from "react";
import styles from "./styles.css";

export interface TimelineDateProps extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  date: Date;
}

export function TimelineDate(props: TimelineDateProps) {
  const { className, date } = props;

  return (
    <div className={cx(styles.component, className)} {...props}>
      {date.toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })}
    </div>
  );
}
