import { Reducer, useEffect, useReducer, useRef } from "react";
import { LeafsMode } from "components/LeafsList";
import { useApi } from "contexts/api";
import * as cache from "shared/cache";
import { File, FileType, Leaf } from "shared/types";

export interface State {
  filteredLeafEntities: Leaf[] | null;
  leafEntities: Leaf[] | null;
  leafIDs: string[] | null;
}

interface SetStateAction {
  type: "SetState";
  payload: State;
}

interface SetLeafIDsAction {
  type: "SetLeafIDs";
  payload: State["leafIDs"];
}

interface SetLeafEntitiesAction {
  type: "SetLeafEntities";
  payload: State["leafEntities"];
}

interface SetFilteredLeafEntitiesAction {
  type: "SetFilteredLeafEntities";
  payload: State["filteredLeafEntities"];
}

type Action = SetStateAction | SetLeafIDsAction | SetLeafEntitiesAction | SetFilteredLeafEntitiesAction;

function getEntitiesForIDs(
  cache: cache.Cache,
  leafIDs: string[] | null,
): [Leaf[] | null, string[] | null, string[] | null] {
  if (!leafIDs?.length) {
    return [null, null, null];
  }

  const entities: Leaf[] = [];
  const ids: string[] = [];
  const missingIDs: string[] = [];

  leafIDs.forEach((leafID) => {
    const entity = cache.get("leafs", leafID);

    if (!entity) {
      missingIDs.push(leafID);
    } else if (!missingIDs.length) {
      ids.push(leafID);
      entities.push(entity);
    }
  });

  if (missingIDs.length) {
    return [null, missingIDs, ids];
  }

  return [entities, null, ids];
}

function filterLeafEntities(typeFilter: FileType | null, leafs: Leaf[] | null) {
  if (!typeFilter || !leafs) {
    return leafs;
  }

  return leafs.filter(
    (leaf) =>
      (leaf.type === "FILE" && (leaf as File).fileType === typeFilter) ||
      (leaf.type === "COLLECTION" &&
        leaf.childFileTypes != null &&
        Object.keys(leaf.childFileTypes)?.includes?.(`${typeFilter}`)),
  );
}

const leafsModeToCacheView = new Map<
  LeafsMode,
  cache.View.RecentLeafs | cache.View.HotLeafs | cache.View.MostViewedLeafs
>([
  [LeafsMode.Manual, cache.View.RecentLeafs],
  [LeafsMode.Recent, cache.View.RecentLeafs],
  [LeafsMode.Hot, cache.View.HotLeafs],
  [LeafsMode.MostViewed, cache.View.MostViewedLeafs],
  [LeafsMode.Oldest, cache.View.RecentLeafs],
]);

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "SetState": {
      return {
        ...state,
        leafIDs: action.payload.leafIDs,
        leafEntities: action.payload.leafEntities,
        filteredLeafEntities: action.payload.filteredLeafEntities,
      };
    }
    case "SetLeafIDs": {
      return {
        ...state,
        leafIDs: action.payload,
        leafEntities: null,
        filteredLeafEntities: null,
      };
    }
    case "SetLeafEntities": {
      return {
        ...state,
        leafEntities: action.payload,
      };
    }
    case "SetFilteredLeafEntities": {
      return {
        ...state,
        filteredLeafEntities: action.payload,
      };
    }
    default:
      return state;
  }
};

interface UseLeafsProps {
  leafsMode: LeafsMode;
  typeFilter?: FileType | null;
}

export function useLeafs(props: UseLeafsProps) {
  const { leafsMode, typeFilter = null } = props;
  const api = useApi();

  function getInitialState(): State {
    const viewKey = leafsModeToCacheView.get(leafsMode);
    const leafIDs = (viewKey && api?.cache.get("views", viewKey)) || [];
    const [leafEntities] = api ? getEntitiesForIDs(api.cache, leafIDs) : [null];
    const filteredLeafEntities = leafEntities && filterLeafEntities(typeFilter, leafEntities);
    const result = {
      leafIDs,
      leafEntities,
      filteredLeafEntities,
    };

    return result;
  }

  const [state, dispatch] = useReducer(reducer, null, getInitialState);
  const typeFilterRef = useRef(typeFilter);

  useEffect(() => {
    typeFilterRef.current = typeFilter;
  }, [typeFilter]);

  const { cache, leaf } = api || {};

  useEffect(() => {
    if (!cache || !leaf) {
      return;
    }

    let active = true;
    let promise;

    const cacheView = leafsModeToCacheView.get(leafsMode);
    const leafIDs = (cacheView && cache.get("views", cacheView)) || null;
    const [leafEntities] = getEntitiesForIDs(cache, leafIDs);
    const filteredLeafEntities = filterLeafEntities(typeFilterRef.current, leafEntities);

    dispatch({
      type: "SetState",
      payload: {
        leafIDs,
        leafEntities,
        filteredLeafEntities,
      },
    });

    switch (leafsMode) {
      case LeafsMode.Recent:
      case LeafsMode.Oldest:
      default: {
        promise = leaf.listRecent();
        break;
      }
      case LeafsMode.Hot: {
        promise = leaf.listHot();
        break;
      }
      case LeafsMode.MostViewed: {
        promise = leaf.listMostViewed();
        break;
      }
    }

    const updateState = () => {
      if (!active) {
        return;
      }

      const cacheViewSet = (cacheView && cache.get("views", cacheView)) || null;
      const [leafEntities] = getEntitiesForIDs(cache, cacheViewSet);
      const filteredLeafEntities = filterLeafEntities(typeFilterRef.current, leafEntities);

      dispatch({
        type: "SetState",
        payload: {
          leafIDs,
          leafEntities,
          filteredLeafEntities,
        },
      });
    };

    promise.then((leafIDs) => {
      if (active) {
        if (cacheView) {
          cache.set("views", cacheView, leafIDs || []);
        }

        const [leafEntities, missingIDs, cachedIDs] = getEntitiesForIDs(cache, leafIDs);
        const filteredLeafEntities = filterLeafEntities(typeFilterRef.current, leafEntities);

        dispatch({
          type: "SetState",
          payload: {
            leafIDs,
            leafEntities,
            filteredLeafEntities,
          },
        });

        if (missingIDs?.length) {
          leaf.populate(missingIDs).then(() => {
            if (active) {
              updateState();

              if (cachedIDs?.length) {
                leaf.populate(cachedIDs).then(updateState);
              }
            }
          });
        } else if (cachedIDs?.length) {
          leaf.populate(cachedIDs).then(updateState);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [cache, leaf, leafsMode, typeFilter]);

  return state.filteredLeafEntities;
}
