import { forwardRef } from "react";
import type { IconProps } from "./types";

export const IconExternal = forwardRef<SVGSVGElement, IconProps>(function IconExternal(props, ref) {
  const { size = 24, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" ref={ref} {...otherProps}>
      <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
    </svg>
  );
});
