import cx from "classnames";
import awkshareFaviconIcon from "public/favicon.png";
import vimeo from "public/images/icons/vimeo.png";
import youtube from "public/images/icons/youtube.png";
import { MouseEvent, useCallback } from "react";
import { IconCrossCircle } from "components/Icons";
import { TimelineItemReference, TimelineItemReferenceDataForType } from "shared/types";
import styles from "./styles.css";

interface TimelineEventReferenceProps {
  className?: string;
  remove?: (reference: TimelineItemReference) => void;
  reference: TimelineItemReference;
}

export function TimelineEventReference(props: TimelineEventReferenceProps) {
  const { className, remove, reference } = props;
  const backups: [string, string][] = (reference.leafs || []).map((leaf) => {
    if (leaf.role) {
      switch (leaf.role) {
        case "PDF_CAPTURE": {
          return ["PDF Capture", `/files/${leaf.leaf.id}`];
        }
        case "VIDEO_BACKUP": {
          return ["Video Backup", `/files/${leaf.leaf.id}`];
        }
        case "THUMBNAIL_BACKUP": {
          return ["Thumbnail Backup", `/files/${leaf.leaf.id}`];
        }
        case "FAVICON_BACKUP": {
          return ["Favicon Backup", `/files/${leaf.leaf.id}`];
        }
        default: {
          return ["Backup", `/files/${leaf.leaf.id}`];
        }
      }
    }

    return ["Backup", `/files/${leaf.leaf.id}`];
  });

  if (reference.archiveURL) {
    backups.push(["Archive", reference.archiveURL]);
  }

  let title;
  let icon;
  let url;

  switch (reference.type) {
    case "LEAF": {
      const refData = reference.data as TimelineItemReferenceDataForType<"LEAF">;
      title = refData.title;
      icon = <img className={styles.icon} src={awkshareFaviconIcon} width={18} height={18} />;
      url = `/files/${refData.leafID}`;
      break;
    }
    case "YOUTUBE": {
      const refData = reference.data as TimelineItemReferenceDataForType<"YOUTUBE">;
      title = refData.title;
      icon = <img className={styles.icon} src={youtube} width={18} height={12.66} />;
      url = `https://www.youtube.com/watch?v=${refData.videoID}`;
      break;
    }
    case "VIMEO": {
      const refData = reference.data as TimelineItemReferenceDataForType<"VIMEO">;
      title = refData.title;
      icon = <img className={styles.icon} src={vimeo} width={18} height={15.6} />;
      url = `https://www.vimeo.com/${refData.videoID}`;
      break;
    }
    case "URL": {
      const refData = reference.data as TimelineItemReferenceDataForType<"URL">;
      const { providerIconURL } = refData;
      ({ title, url } = refData);
      icon = providerIconURL && <img className={styles.icon} src={providerIconURL} width={18} height={18} />;
      break;
    }
  }

  const onClickRemove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      remove?.(reference);
    },
    [reference, remove],
  );

  return (
    <div className={cx(styles.component, className)}>
      <a className={styles.link} href={url} target="_blank" rel="noopener nofollow noreferrer">
        {icon}
        <span className={styles.title}>{title}</span>
      </a>
      {backups?.map?.(
        (backup) =>
          backup && (
            <a
              className={cx(styles.link, styles.backup)}
              key={backup[1]}
              href={backup[1]}
              target="_blank"
              rel="noreferrer"
            >
              <span className={styles.title}>{backup[0]}</span>
            </a>
          ),
      )}
      {remove && (
        <div className={styles.remove} onClick={onClickRemove}>
          <IconCrossCircle size={18} />
        </div>
      )}
    </div>
  );
}
