import cx from "classnames";
import { FC, FocusEvent, MouseEvent, ReactElement, useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router";
import { Comments } from "components/Comments";
import { LeafsBrowserOverlay } from "components/LeafsBrowser";
import { Loader } from "components/Loader";
import { SmoothImage } from "components/Smooth";
import { useApi } from "contexts/api";
import { useEvents } from "contexts/events";
import { useEvent } from "hooks/useEvent";
import { File, FileType, Party } from "shared/types";
import { PartyViewerPlayer } from "./PartyViewerPlayer";
import { PartyViewerTrack } from "./PartyViewerTrack";
import styles from "./styles.css";

type State = Omit<Party, "date"> & {
  date: Date;
};

const SECRET_WORKING = Symbol("working");
const BROWSER_FILE_WHITELIST = ["AUDIO", "VIDEO"] satisfies FileType[];

const Soon: FC<{
  date: Date;
}> = ({ date }) => {
  const renderer = useEvent(
    ({
      completed,
      formatted,
      total,
    }: {
      completed: boolean;
      formatted: {
        days: string;
        hours: string;
        minutes: string;
        seconds: string;
      };
      total: number;
    }) => {
      if (completed) {
        return <span>The party starts now! 🎉</span>;
      }

      const { days, hours, minutes, seconds } = formatted;

      if (Number(days) > 1) {
        return (
          <span>
            The party begins on {date.toLocaleDateString()} at {date.toLocaleTimeString()}
          </span>
        );
      }

      return (
        <span>
          {"The party begins in "}
          {total < 0 ? "-" : ""}
          {days !== "00" && days}
          {days !== "00" ? ":" : ""}
          {hours !== "00" ? `${hours}:` : ""}
          {minutes !== "00" ? `${minutes}:` : ""}
          {total < 10 ? Number(seconds) : seconds}
        </span>
      );
    },
  );

  return (
    <div className={styles.soon}>
      <Countdown date={date} renderer={renderer} />
    </div>
  );
};

export function PartyViewerPage() {
  const api = useApi();
  const location = useLocation();
  const params = useParams();
  const [, forceUpdate] = useState(0);
  // const [muted, setMuted] = useState(false);
  const [state, setState] = useState<false | State | null>(null);
  const [coverFile, setCoverFile] = useState<File | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [secret, setSecret] = useState<string | null>(location.state?.token || null);
  const [secretValid, setSecretValid] = useState<boolean | typeof SECRET_WORKING | null>(null);
  const [browserOpen, setBrowserOpen] = useState<boolean>(false);
  const totalDuration = useMemo(
    () => (files ? files.reduce((out, file) => out + (file.duration || 0), 0) : 0),
    [files],
  );

  const canRender = state && files != null && (!state.coverFileID || coverFile);
  const stateID = typeof state === "object" && state != null && state.id;
  const fileIDs = typeof state === "object" && state != null && state.fileIDs;
  const coverFileID = typeof state === "object" && state != null && state.coverFileID;

  const selectOnFocus = useEvent((ev: FocusEvent<HTMLInputElement>) => ev.target.select());

  // const toggleMute = useCallback(() => {
  //   setMuted(!muted);
  // }, [muted]);

  const requestSecret = useEvent((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const secret = window.prompt("Enter the secret code provided by the party creator below.");

    if (secret) {
      setSecret(secret);
    } else {
      setSecret(null);
      setSecretValid(null);
    }
  });

  const openAddBrowser = useEvent(() => {
    setBrowserOpen(true);
  });

  const onCloseBrowser = useEvent(() => {
    setBrowserOpen(false);
  });

  const onSelectFile = useEvent((file: File) => {
    if (!state || !state.id || !secret) {
      return;
    }

    return api.party.addFile(state.id, secret, file.id).then((data) => {
      const { files, ...party } = data as Party & {
        files: File[];
      };

      setFiles(files);
      setState({
        ...party,
        date: new Date(party.date),
      });

      setBrowserOpen(false);
    });
  });

  useEffect(() => {
    if (secret && stateID) {
      setSecretValid(SECRET_WORKING);

      api.party.get(stateID, secret as string).then(
        (party) => {
          if (party?.token === secret) {
            setSecretValid(true);
          } else {
            setSecret(null);
            setSecretValid(false);
          }
        },
        () => {
          setSecret(null);
          setSecretValid(false);
        },
      );
    }
  }, [api.party, stateID, secret]);

  useEffect(() => {
    if (!params.id) {
      return;
    }

    let active = true;

    api.party
      .get(params.id)
      .then((party) => {
        if (!active || !party) {
          return;
        }

        setFiles(null);
        setCoverFile(null);
        setState({
          ...party,
          date: new Date(party.date),
        });
      })
      .catch((err) => {
        if (!active) {
          return;
        }

        console.log(err);

        setState(false);
      });

    return () => {
      active = false;
    };
  }, [api.party, params.id]);

  useEffect(() => {
    if (!fileIDs) {
      setFiles(null);
      return;
    }

    let active = true;

    Promise.all(fileIDs.map(api.leaf.get)).then(
      (res) => active && setFiles(res as File[]),
      () => active && setState(false),
    );

    return () => {
      active = false;
    };
  }, [api.leaf.get, fileIDs]);

  useEffect(() => {
    if (!coverFileID) {
      setCoverFile(null);
      return;
    }

    let active = true;

    api.leaf.get(coverFileID).then(
      (leaf) => {
        active && leaf?.type === "FILE" && setCoverFile(leaf);
      },
      () => {
        active && setState(false);
      },
    );

    return () => {
      active = false;
    };
  }, [api.leaf, coverFileID]);

  useEffect(() => {
    if (canRender) {
      const interval = setInterval(() => {
        forceUpdate((x) => x + 1);
      }, 500);

      return () => clearInterval(interval);
    }
  }, [canRender]);

  useEvents("PARTY", (state as State)?.id, (type, data) => {
    if (type === "PARTY_UPDATE") {
      const { files, ...party } = data as Party & {
        files: File[];
      };

      setFiles(files);
      setState({
        ...party,
        date: new Date(party.date),
      });
    }
  });

  if (state === false) {
    return (
      <div className={cx(styles.component, styles.error)}>
        <div className={styles.error}>Could not join party.</div>
      </div>
    );
  }

  if (!canRender) {
    return (
      <div className={styles.component}>
        <Loader />
      </div>
    );
  }

  const now = Date.now();

  let isActive = false;
  let currentStartTime: Date | null = null;
  let nextPlayTime: Date | null = null;
  let currentTime: number | null = null;
  let loopIndex: number | null = null;

  if (state.mode === "QUEUE") {
    currentTime = now - state.date.getTime();
    currentTime /= 1000;
    loopIndex = null;
    nextPlayTime = null;

    if (state.date.getTime() > now) {
      isActive = false;
      currentStartTime = state.date;
    } else {
      isActive = true;
      currentStartTime = null;
    }
  } else {
    if (state.date.getTime() > now) {
      // The first play is in the future
      currentStartTime = new Date(state.date);
      nextPlayTime = new Date(currentStartTime);
      nextPlayTime.setSeconds(nextPlayTime.getSeconds() + totalDuration);
      loopIndex = 0;
    } else {
      const msSinceFirstStart = now - state.date.getTime();
      const loopsSinceFirstStart = msSinceFirstStart / (totalDuration * 1000);

      isActive = true;

      if (loopsSinceFirstStart < 1) {
        // We're still on the first
        currentStartTime = new Date(state.date);
        nextPlayTime = new Date(currentStartTime);
        nextPlayTime.setSeconds(nextPlayTime.getSeconds() + totalDuration);
      } else {
        currentStartTime = new Date(state.date);
        currentStartTime.setSeconds(currentStartTime.getSeconds() + totalDuration * Math.floor(loopsSinceFirstStart));
        nextPlayTime = new Date(currentStartTime);
        nextPlayTime.setSeconds(nextPlayTime.getSeconds() + totalDuration);
      }

      loopIndex = Math.floor(loopsSinceFirstStart);
    }

    currentTime = now - currentStartTime.getTime();
    currentTime /= 1000;
  }

  if (!currentTime) {
    return null;
  }

  let offset = 0;
  // let videoActive = false;

  const _currentTime = currentTime;

  const [fileElements, playerElements] = files.reduce<[ReactElement[], ReactElement[]]>(
    (out, file, index) => {
      const startOffset = offset;
      const key = `${state.date.toISOString()}:${loopIndex}:${file.id}`;

      offset += file.duration || 0;

      const trackState = _currentTime > startOffset && _currentTime < offset ? "PLAYING" : "QUEUED";

      if (trackState === "PLAYING" && file.fileType === "VIDEO") {
        // videoActive = true;
      }

      let start;

      if (state.mode === "QUEUE") {
        start = state.date.getTime() + startOffset * 1000;
      } else {
        if (trackState === "PLAYING" || startOffset > _currentTime) {
          start = (currentStartTime || state.date).getTime();
        } else if (nextPlayTime) {
          start = nextPlayTime.getTime();
        } else {
          start = 0;
        }

        start += startOffset * 1000;
      }

      if (state.mode === "QUEUE" && _currentTime > startOffset && trackState !== "PLAYING") {
        return out;
      }

      out[0].push(<PartyViewerTrack file={file} key={key} start={start} state={trackState} />);

      if (
        trackState === "PLAYING" || // We're playing
        (index === 0 && now < start) || // The party hasn't started yet and we're the first file
        (now - start) / 1000 > -10 // We're starting in < 10s
      ) {
        out[1].push(
          <PartyViewerPlayer
            className={cx(styles.player, trackState === "PLAYING" && styles.activePlayer)}
            cover={coverFile?.downloadURL}
            file={file}
            key={key}
            loopIndex={loopIndex}
            start={start}
            state={trackState}
          />,
        );
      }

      return out;
    },
    [[], []],
  );

  const coverImageUrl = coverFile?.downloadURL || state?.coverImageURL;

  return (
    <>
      <Helmet>
        <title>{state.name || "Party"}</title>
      </Helmet>
      {browserOpen && (
        <LeafsBrowserOverlay
          confirm={state.mode !== "QUEUE" ? "Adding a new file will stop this party from looping" : null}
          fileTypeWhitelist={BROWSER_FILE_WHITELIST}
          hideDisabled={true}
          onClose={onCloseBrowser}
          onSelect={onSelectFile}
        />
      )}
      <div className={styles.component}>
        <div className={styles.inner}>
          <div className={cx(styles.left, isActive && styles.collapsed)}>
            <div className={styles.leftTrigger} />
            <div className={styles.inner}>
              <div className={styles.info}>
                {state.name && <div className={styles.name}>{state.name}</div>}
                <div className={cx(styles.status, isActive && styles.isActive)}>
                  {currentStartTime && (
                    <div className={styles.kv}>
                      <label>Party {isActive ? "Began" : "Begins"} At</label>{" "}
                      <em className={styles.value}>{currentStartTime.toLocaleString()}</em>
                    </div>
                  )}
                  {isActive && state.mode !== "QUEUE" && nextPlayTime && (
                    <div className={styles.kv}>
                      <label>Party Loops At</label> <em className={styles.value}>{nextPlayTime.toLocaleString()}</em>
                    </div>
                  )}
                  <div className={styles.kv}>
                    <label>Party Secret</label>
                    {secret ? (
                      secretValid === SECRET_WORKING ? (
                        <em className={styles.value}>Checking...</em>
                      ) : (
                        <div className={styles.value}>
                          <span className={styles.secret}>{secret}</span>
                          <div className={styles.kvExplainBody}>
                            Sharing the secret lets other people add files to the playlist!
                          </div>
                        </div>
                      )
                    ) : (
                      <div className={cx(styles.value, styles.padded)}>
                        <button className={styles.button} onClick={requestSecret}>
                          {secretValid === false ? "Invalid secret - try again" : "Enter secret"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.actions}>
                  {/* {isActive && (
                  <button className={cx(styles.button, styles.volume)} onClick={toggleMute}>
                    {muted ? 'Unmute' : 'Mute'}
                  </button>
                )} */}
                  <div className={styles.share}>
                    <div className={styles.label}>Share</div>
                    <input
                      className={styles.link}
                      type="text"
                      onFocus={selectOnFocus}
                      value={`https://www.awkshare.com/party/${params.id}`}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tracksHeader}>Party Playlist</div>
              <div className={styles.tracks}>
                {fileElements.length > 0 ? fileElements : <div className={styles.empty}>No files queued.</div>}
              </div>
              {secretValid && (
                <button className={styles.add} onClick={openAddBrowser}>
                  + Add File
                </button>
              )}
            </div>
          </div>
          <div className={cx(styles.cover, styles.expand)}>
            {coverImageUrl && <SmoothImage className={styles.preview} url={coverImageUrl} />}
            {!isActive && currentStartTime && <Soon date={currentStartTime} />}
            {playerElements}
          </div>
          {state.comments !== false && (
            <div className={styles.comments}>
              <Comments entityType="PARTY" entityID={state.id} hideDates={true} grow={true} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
