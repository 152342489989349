import cx from "classnames";
import { CSSProperties, RefObject, WheelEvent, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { SmoothImage } from "components/Smooth";
import { useApi } from "contexts/api";
import { EventType, useEvents } from "contexts/events";
import { THEIA_HOST } from "shared/api";
import { TimelineItem, TimelineItemReference } from "shared/types";
import { TimelineEvent as TimelineEventType, timelineTagToGradient } from "../shared";
import { TimelineDate } from "../TimelineDate";
import styles from "./styles.css";
import { TimelineEventInfo } from "./TimelineEventInfo";
import { TimelineEventReferences } from "./TimelineEventReferences";

const LoadingReferences = Symbol("LoadingReferences");

interface TimelineEventProps {
  event: TimelineEventType;
  isEditable: boolean;
  onChange(event: TimelineItem): void;
  onChangeIsOpen(id: string, isOpen: boolean): void;
  scrollElementRef: RefObject<HTMLDivElement | null>;
  x: number | string;
  className?: string;
  isActive?: boolean;
  renderDate?: boolean;
}

export const TimelineEvent = forwardRef<HTMLDivElement, TimelineEventProps>(function Event(props, ref) {
  const { className, event, isActive, isEditable, onChangeIsOpen, onChange, renderDate, scrollElementRef, x } = props;
  const api = useApi();
  const navigate = useNavigate();
  const { date, id } = event;
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [browserOpen, setBrowserOpen] = useState(false);
  const [references, setReferences] = useState<TimelineItemReference[] | typeof LoadingReferences | null>(null);
  const hideTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const popoverElementRef = useRef<HTMLDivElement | null>(null);
  const isLoadingRef = useRef(false);
  const [isReferencesDirty, setIsReferencesDirty] = useState(false);
  const isPopoverVisible = isActive || isOpen || isEditing || isReferencesDirty;

  const closeBrowser = useCallback((x: number, y: number) => {
    setBrowserOpen(false);

    const { current: popoverElement } = popoverElementRef;

    if (!popoverElement) {
      return;
    }

    const rect = popoverElement.getBoundingClientRect();

    if (x < rect.left || x > rect.right || y < rect.top || y > rect.bottom) {
      setIsOpen(false);
    }
  }, []);

  const onMouseEnter = useCallback(() => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
      hideTimeout.current = null;
    }

    setIsOpen(true);

    if (references == null) {
      setReferences(LoadingReferences);
    }
  }, [references]);

  const onMouseLeave = useCallback(() => {
    if (!browserOpen && !isEditing) {
      hideTimeout.current = setTimeout(() => {
        setIsOpen(false);
      }, 50);
    }
  }, [browserOpen, isEditing]);

  const onWheel = useCallback(
    (scrollEvent: WheelEvent<HTMLElement>) => {
      const { current: scrollElement } = scrollElementRef;

      if (scrollElement && scrollEvent.deltaMode === 0) {
        if (scrollEvent.deltaY === 0) {
          scrollElement.scrollLeft += scrollEvent.deltaX;
        }
      }
    },
    [scrollElementRef],
  );

  const onClick = useCallback(() => {
    navigate(`/timeline/${event.id}`, {
      replace: true,
    });
  }, [event.id, navigate]);

  const onAddReference = useCallback(
    async (_: unknown, url: string) => {
      const reference = await api.timeline.createReference(url);

      if (!reference?.id) {
        return;
      }

      await api.timeline.addReferenceToItem(reference.id, event.id);

      setReferences((references) => {
        if (!Array.isArray(references) || references.some((ref) => ref.id === reference.id)) {
          return references;
        }

        return [...references, reference];
      });
    },
    [api.timeline, event],
  );

  useEffect(() => {
    return () => {
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
        hideTimeout.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (references === LoadingReferences) {
      if (!isLoadingRef.current) {
        isLoadingRef.current = true;
        api.timeline.getItem(id).then((res) => {
          isLoadingRef.current = false;

          if (res?.references) {
            setReferences(res.references);
          }
        });
      }
    }
  }, [api.timeline, references, id]);

  useEffect(() => {
    onChangeIsOpen(id, isOpen);

    if (isOpen && !isLoadingRef.current) {
      isLoadingRef.current = true;
      api.timeline.getItem(id).then((res) => {
        isLoadingRef.current = false;

        if (res?.references) {
          setReferences(res.references);
        }
      });
    }
  }, [api.timeline, id, onChangeIsOpen, isOpen]);

  const onEvent = useCallback((type: EventType, _data: unknown) => {
    switch (type) {
      case "TIMELINE_ADD_REFERENCE_TO_ITEM": {
        const data = _data as TimelineItemReference;

        if (data == null || typeof data !== "object" || !("id" in data)) {
          return;
        }

        setReferences((references) => {
          if (!Array.isArray(references) || references.some((ref) => ref.id === data.id)) {
            return references;
          }

          return [...references, data];
        });
        break;
      }
    }
  }, []);

  useEvents("TIMELINE_ITEM", event.id, onEvent, isPopoverVisible);

  const style = {
    "--x": `${x}px`,
  } as CSSProperties & {
    "--x": string;
    "--tag-color"?: string;
  };

  if (event.tags?.[0]) {
    style["--tag-color"] = timelineTagToGradient.get(event.tags[0]);
  }

  return (
    <div
      className={cx(styles.timelineEvent, className, isEditing && styles.isEditing)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onWheel={onWheel}
      ref={ref}
      style={style}
    >
      {isPopoverVisible && (
        <>
          <div className={styles.eventPopover}>
            <div className={styles.inner} ref={popoverElementRef}>
              <div className={styles.border}>
                <TimelineEventInfo
                  browserOpen={browserOpen}
                  className={styles.eventInfo}
                  closeBrowser={closeBrowser}
                  isEditing={isEditing}
                  onChangeEvent={onChange}
                  setBrowserOpen={setBrowserOpen}
                  setIsEditing={isEditable ? setIsEditing : undefined}
                  timelineEvent={event}
                />
                {!isEditing && (
                  <TimelineEventReferences
                    isEditable={isEditable}
                    isLoading={references === LoadingReferences}
                    onAdd={onAddReference}
                    references={references !== LoadingReferences ? references : null}
                    setIsDirty={setIsReferencesDirty}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.hoverHelper} />
        </>
      )}
      <div className={cx(styles.dot, event.imageLeafID && styles.hasImage)} onClick={onClick}>
        <div className={styles.dotInner}>
          {event.imageLeafID && (
            <SmoothImage
              opacity={1}
              url={[
                {
                  type: "image/webp",
                  url: `${THEIA_HOST}/leafs/${event.imageLeafID}/theia/card/webp`,
                },
                {
                  type: "image/jpeg",
                  url: `${THEIA_HOST}/leafs/${event.imageLeafID}/theia/card/jpeg`,
                },
              ]}
            />
          )}
        </div>
      </div>
      {renderDate !== false && <TimelineDate className={styles.timelineDate} date={date} onClick={onClick} />}
    </div>
  );
});
