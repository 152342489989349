import cx from "classnames";
import { CSSProperties, Children, ElementType, Fragment, PropsWithChildren, ReactNode, isValidElement } from "react";
import { Link, LinkProps, Path } from "react-router-dom";
import { ButtonIcon } from "./ButtonIcon";
import { ButtonLabel } from "./ButtonLabel";
import { useButtonSetContext } from "./context";
import styles from "./styles.css";

export enum ViewportSize {
  Small = "small",
  Medium = "medium",
}

type ComponentProp = ElementType | keyof JSX.IntrinsicElements;

type PropsForType<T extends ElementType | keyof JSX.IntrinsicElements> = T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T]
  : T extends ElementType<infer P>
  ? P
  : T;

export const colors = {
  green: styles.green,
  etched: styles.etched,
} as const;

export type Color = keyof typeof colors;
export type Size = keyof typeof sizes;

type To =
  | Partial<
      Path &
        Pick<LinkProps, "state"> & {
          to?: string;
        }
    >
  | string;

type Props<
  T extends To | undefined = undefined,
  C extends ComponentProp = T extends undefined ? "button" : typeof Link,
> = PropsWithChildren<
  Omit<PropsForType<C>, "color"> & {
    hideAt?: ViewportSize;
    className?: string;
    color?: Color;
    component?: C;
    disabled?: boolean;
    isWorking?: boolean;
    size?: Size;
    to?: T;
  }
>;

export const sizes = {
  medium: styles.sizeMedium,
};

function hasIconAndLabel(children: ReactNode, has: [boolean, boolean] = [false, false]) {
  if (!isValidElement(children)) {
    return;
  }

  Children.forEach(children, (element) => {
    if (has[0] && has[1]) {
      return;
    }

    if (element.type === ButtonIcon) {
      has[0] = true;
    } else if (element.type === ButtonLabel) {
      has[1] = true;
    } else if (element.type === Fragment) {
      hasIconAndLabel(element.props.children, has);
    }
  });

  return has[0] && has[1];
}

export function Button<T extends To | undefined, C extends ComponentProp>(props: Props<T, C>) {
  const {
    className: classNameProp,
    component: RequestedComponent,
    children,
    color,
    disabled,
    hideAt,
    isWorking,
    size,
    to: toProp,
    ...otherProps
  } = props;

  const setContext = useButtonSetContext();
  const Component: ElementType = RequestedComponent || (toProp ? Link : "button");

  let style: CSSProperties;

  /**
   * I think this is probably really unsafe to do,
   * esp. with concurrent mode on, but it works for now!
   */
  if (setContext) {
    const i = ++setContext.index;

    style = {
      marginLeft: i === 1 ? undefined : setContext.size,
    };
  }

  const className = cx(
    styles.component,
    {
      [styles.hasIconAndLabel]: hasIconAndLabel(children),
      [styles.disabled]: disabled,
      [styles.isWorking]: isWorking,
      [styles.hideAtMedium]: hideAt === ViewportSize.Medium,
      [styles.hideAtSmall]: hideAt === ViewportSize.Small,
    },
    classNameProp,
    "awk-btn",
    colors[color],
    sizes[size],
  );

  if (typeof toProp === "object" && toProp != null) {
    const { state, to, ...otherTo } = toProp;

    Object.assign(otherProps, {
      to: to || otherTo,
      state,
    });
  } else if (toProp) {
    Object.assign(otherProps, {
      to: toProp,
    });
  }

  return (
    <Component className={className} disabled={disabled} style={style} {...otherProps}>
      {children}
    </Component>
  );
}
