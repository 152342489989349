import { CSSProperties, RefObject, WheelEvent, forwardRef, memo, useCallback } from "react";
import { TimelineItem } from "shared/types";
import { TimelineEvent as TimelineEventType } from "../shared";
import { TimelineDate } from "../TimelineDate";
import { TimelineEvent } from "../TimelineEvent";
import styles from "./styles.css";

export interface TimelineGroupEventProps {
  events: TimelineEventType[];
  isEditable: boolean;
  onChange: (event: TimelineItem) => void;
  onChangeIsOpen: (id: string, isOpen: boolean) => void;
  scrollElementRef: RefObject<HTMLDivElement>;
  x: number | string;
}

export const TimelineGroupEvent = memo(
  forwardRef<HTMLDivElement, TimelineGroupEventProps>(function TimelineGroupEvent(props, ref) {
    const { events, isEditable, onChange, onChangeIsOpen, scrollElementRef, x } = props;
    const [{ date }] = events;
    const onWheel = useCallback(
      (scrollEvent: WheelEvent) => {
        const { current: scrollElement } = scrollElementRef;

        if (scrollElement && scrollEvent.deltaMode === 0) {
          if (scrollEvent.deltaY === 0) {
            scrollElement.scrollLeft += scrollEvent.deltaX;
          }
        }
      },
      [scrollElementRef],
    );

    return (
      <div className={styles.groupEvent} onWheel={onWheel} ref={ref} style={{ "--x": `${x}px` } as CSSProperties}>
        <div className={styles.groupEvents}>
          {events.map((event) => (
            <div className={styles.groupEventWrapper} key={event.id}>
              <TimelineEvent
                className={styles.timelineEvent}
                event={event}
                isEditable={isEditable}
                onChange={onChange}
                onChangeIsOpen={onChangeIsOpen}
                renderDate={false}
                scrollElementRef={scrollElementRef}
                x={0}
              />
            </div>
          ))}
        </div>
        <div className={styles.groupLine} />
        <TimelineDate className={styles.timelineDate} date={date} />
      </div>
    );
  }),
);
