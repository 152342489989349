import { createContext, useContext, useEffect, useRef, useState } from "react";
import { API_HOST } from "shared/api";
import { EntityType, JSONValue } from "shared/types";

export type Events = ReturnType<typeof createEvents>;
export const EventsContext = createContext<Events | null>(null);
export const PRESENCE_CHANGE = Symbol("PRESENCE_CHANGE");

type Listener = (type: EventType | typeof PRESENCE_CHANGE, data: JSONValue) => void;

export type EventType =
  | "TOPIC_STATE"
  | "PRESENCE_CHANGE"
  | "COMMENT"
  | "PARTY_UPDATE"
  | "TIMELINE_ADD_EVENT"
  | "TIMELINE_UPDATE_EVENT"
  | "TIMELINE_UPDATE_REFERENCE"
  | "TIMELINE_ADD_REFERENCE_TO_ITEM"
  | "COLLECTION_PUBLISHED"
  | "FILE_ADDED"
  | "FILE_ADDED_TO_COLLECTION";

export const entityKey = (entityType: EntityType, entityID: string) => `${entityType}:${entityID}`;

export function createEvents() {
  const streams = new Map<string, EventSource>();
  const presence = new Map<string, Set<string>>();
  const listeners = new Map<string, Listener[]>();

  const getPresenceCount = (entityType: EntityType, entityID: string) =>
    presence.get(entityKey(entityType, entityID))?.size || 0;

  function setPresence(entityType: EntityType, entityID: string, keys: string[] | null) {
    const key = entityKey(entityType, entityID);

    if (keys == null) {
      presence.delete(key);
      emit(entityType, entityID, PRESENCE_CHANGE, null);
      return;
    }

    const set = new Set(keys);

    presence.set(key, set);

    emit(entityType, entityID, PRESENCE_CHANGE, set.size);
  }

  function emit(entityType: EntityType, entityID: string, type: EventType | typeof PRESENCE_CHANGE, data: JSONValue) {
    const key = entityKey(entityType, entityID);
    const bucket = listeners.get(key);

    if (bucket) {
      bucket.forEach((fn) => fn(type, data));
    }
  }

  function openEventStream(entityType: EntityType, entityID: string) {
    let url: string;

    switch (entityType) {
      case "LEAF":
        url = `${API_HOST}/leafs/${entityID}/events`;
        break;
      case "PARTY":
        url = `${API_HOST}/party/${entityID}/events`;
        break;
      case "TIMELINE":
        url = `${API_HOST}/timeline/events`;
        break;
      case "TIMELINE_ITEM":
        url = `${API_HOST}/timeline/${entityID}/events`;
        break;
    }

    const stream = new EventSource(url);
    const key = entityKey(entityType, entityID);

    stream.onerror = () => {
      setPresence(entityType, entityID, null);
    };

    stream.addEventListener("message", (event: MessageEvent) => {
      const { type, data } = JSON.parse(event.data);

      if (type === "TOPIC_STATE") {
        setPresence(entityType, entityID, data.presenceKeys);
        return;
      }

      if (type === "PRESENCE_CHANGE") {
        const existing = presence.get(key);

        if (existing) {
          if (data.count === 1) {
            existing.add(data.key);
          } else {
            existing.delete(data.key);
          }

          setPresence(entityType, entityID, [...existing]);
        }

        return;
      }

      emit(entityType, entityID, type, data);
    });

    streams.set(key, stream);
  }

  function closeEventStream(entityType: EntityType, entityID: string) {
    const key = entityKey(entityType, entityID);
    const stream = streams.get(key);

    if (stream) {
      stream.close();
      streams.delete(key);
      presence.delete(key);
      emit(entityType, entityID, PRESENCE_CHANGE, null);
    }
  }

  function subscribe(entityType: EntityType, entityID: string, listener: Listener) {
    const key = entityKey(entityType, entityID);

    let active = true;
    let bucket: Listener[] | null = listeners.get(key) ?? null;

    if (!bucket) {
      bucket = [];
      openEventStream(entityType, entityID);
      listeners.set(key, bucket);
    }

    bucket.push(listener);
    bucket = null;

    return () => {
      if (active) {
        active = false;

        const bucket = listeners.get(key);

        if (bucket) {
          bucket.splice(bucket.indexOf(listener), 1);

          if (bucket.length === 0) {
            listeners.delete(key);
            closeEventStream(entityType, entityID);
          }
        }
      }
    };
  }

  return {
    getPresenceCount,
    subscribe,
  };
}

export function useEvents(entityType: EntityType, entityID: string, listener?: Listener, isActive?: boolean) {
  const context = useContext(EventsContext);
  const [presence, setPresence] = useState(context?.getPresenceCount(entityType, entityID) ?? null);
  const currentListener = useRef<Listener | null>(null);

  useEffect(() => {
    currentListener.current = listener ?? null;
  }, [listener]);

  useEffect(() => {
    if (!entityType || !entityID || isActive === false) {
      return;
    }

    return context?.subscribe(entityType, entityID, (type, data) => {
      if (type === PRESENCE_CHANGE) {
        setPresence(data as number);
      } else if (currentListener.current) {
        currentListener.current(type, data);
      }
    });
  }, [context, entityType, entityID, isActive]);

  return presence;
}
