import cx from "classnames";
import { RefObject, useRef, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import { FileIcon } from "components/FileIcon";
import { Loader } from "components/Loader";
import styles from "./styles.css";

// cSpell:ignore wavesurfer
const getLibrary = () => import("wavesurfer.js").then(({ default: WaveSurfer }) => WaveSurfer);

type FilePreviewVisualizerProps = {
  videoElementRef: RefObject<HTMLVideoElement>;
};

const error = Symbol("error");

export function FilePreviewVisualizer(props: FilePreviewVisualizerProps) {
  const { videoElementRef } = props;
  const waveformContainerRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | typeof error>(true);

  useIsomorphicLayoutEffect(() => {
    let active = true;
    let wavesurfer: import("wavesurfer.js").default | null = null;

    getLibrary().then((WaveSurfer) => {
      if (!active || !waveformContainerRef.current) {
        return;
      }

      // wavesurfer.js only looks for node.src and doesn't read <source />
      // children, so we need to fake the src property.
      //
      // We can't just set it normally because that breaks playback in Safari,
      // due to us not providing content-type headers when streaming.
      const { current: videoElement } = videoElementRef;

      if (!videoElement) {
        return;
      }

      if (!videoElement?.src) {
        const src = videoElement.querySelector("source")?.src;

        if (src) {
          // This is gross... but works.
          Object.defineProperty(videoElementRef.current, "src", {
            get() {
              return src;
            },
          });
        }
      }

      wavesurfer = WaveSurfer.create({
        media: videoElement,
        container: waveformContainerRef.current,
        waveColor: "rgb(29, 29, 29)",
        progressColor: "#23ff86",
        cursorColor: "transparent",
        cursorWidth: 0,
      });

      wavesurfer.on("ready", () => {
        setIsLoading(false);
      });
    });

    return () => {
      active = false;
      wavesurfer?.destroy?.();
      wavesurfer = null;
    };
  }, []);

  return (
    <div className={styles.component}>
      {isLoading ? (
        isLoading !== error ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <FileIcon type="AUDIO" />
        )
      ) : null}
      {isLoading !== error && (
        <div className={cx(styles.waveform, !isLoading && styles.loaded)} ref={waveformContainerRef} />
      )}
    </div>
  );
}
