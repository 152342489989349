import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import bg from "public/images/backgrounds/_creation.png";
import { PropsWithChildren, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Banners } from "components/Banners";
import {
  AboutPage,
  DMCAPage,
  FilePage,
  IndexPage,
  PartyCreatorPage,
  PartyViewerPage,
  TimelinePage,
} from "components/pages";
import { ShareCreator, ShareDropping, useUploaderDND } from "components/Share";
import { useEvent } from "hooks/useEvent";
import { usePopoverNavigation } from "hooks/usePopoverNavigation";
import { Header } from "../Header";
import { AppLayout } from "./AppLayout";
import {
  audioPageElement,
  filesPageElement,
  imagesPageElement,
  miscPageElement,
  videosPageElement,
} from "./filePageElements";
import styles from "./styles.css";

const indexPageElement = (
  <AppLayout>
    <IndexPage />
  </AppLayout>
);

const routes = [
  <Route key="/" path="/" element={indexPageElement} />,
  <Route key="/hot" path="/hot" element={indexPageElement} />,
  <Route key="/most-viewed" path="/most-viewed" element={indexPageElement} />,
  <Route key="/oldest" path="/oldest" element={indexPageElement} />,
  <Route
    key="/files/:fileID"
    path="/files/:fileID"
    element={
      <AppLayout>
        <FilePage />
      </AppLayout>
    }
  />,
  <Route key="/files" path="/files" element={filesPageElement} />,
  <Route key="/videos" path="/videos" element={videosPageElement} />,
  <Route key="/images" path="/images" element={imagesPageElement} />,
  <Route key="/audio" path="/audio" element={audioPageElement} />,
  <Route key="/docs" path="/docs" element={<Navigate to="/misc" />} />,
  <Route key="/misc" path="/misc" element={miscPageElement} />,
  <Route
    key="/party/create"
    path="/party/create"
    element={
      <AppLayout>
        <PartyCreatorPage />
      </AppLayout>
    }
  />,
  <Route
    key="/party/:id"
    path="/party/:id"
    element={
      <AppLayout>
        <PartyViewerPage />
      </AppLayout>
    }
  />,
  <Route key="/about" path="/about" element={<AboutPage />} />,
  <Route
    key="/dmca"
    path="/dmca"
    element={
      <AppLayout>
        <DMCAPage />
      </AppLayout>
    }
  />,
  <Route
    key="/timeline/:id?"
    path="/timeline/:id?"
    element={
      <AppLayout>
        <TimelinePage />
      </AppLayout>
    }
  />,
];

export function AppInner() {
  const location = useLocation();
  const { state } = location;
  const [dropping, dndProps] = useUploaderDND();
  const { currentPopover, getBackgroundLocation, exitPopover } = usePopoverNavigation();
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const backgroundLocation = currentPopover && getBackgroundLocation();
  const handleClickPopover = useEvent((event) => {
    if (event.target === event.currentTarget) {
      exitPopover();
    }
  });

  // const lastPopover = useRef<string | undefined>(undefined);

  // if (state?.popover && state.popover !== lastPopover.current) {
  //   lastPopover.current = location;
  // }

  useEffect(() => {
    if (!state?.popover) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        popoverRef.current &&
        (document.activeElement === popoverRef.current || !popoverRef.current.contains(document.activeElement)) &&
        event.key === "Escape"
      ) {
        exitPopover();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [state, popoverRef, exitPopover]);

  return (
    <>
      <Helmet defaultTitle="AWKSHARE" titleTemplate="%s | AWKSHARE">
        <meta name="description" content="AWKSHARE - An anonymous dropbox for sharing ANDREW W.K. related media" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@awkshare" />
        <meta name="twitter:creator" content="@awkshare" />
      </Helmet>
      <div className={styles.component} {...dndProps}>
        <ShareDropping dropping={dropping} />
        <Banners />
        <div className={styles.content}>
          <ShareCreator />
          <Header />
          <div className={styles.router}>
            <Routes location={backgroundLocation || location}>{routes}</Routes>
          </div>
        </div>
        <AnimatePresence>
          {currentPopover && (
            <motion.div
              className={styles.popover}
              onClick={handleClickPopover}
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              exit={{
                opacity: 0,
              }}
              ref={popoverRef}
            >
              <div className={styles.popoverInner}>
                <Routes location={currentPopover}>{routes}</Routes>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className={styles.footer}>
          <div className={styles.longLiveTheParty}>
            <span>Long Live The Party. </span>
            <span>The Party Never Dies.</span>
          </div>
          <div className={styles.links}>
            <NavLink className={({ isActive }) => cx(styles.contact, isActive && styles.active)} to="/about">
              About
            </NavLink>
            {" / "}
            <NavLink className={({ isActive }) => cx(styles.contact, isActive && styles.active)} to="/dmca">
              DMCA
            </NavLink>
            <span className={styles.hideAtSmall}>
              {" / "}
              <a
                className={styles.contact}
                href="https://discord.gg/KCfUtc78Hr"
                rel="noopener noreferrer"
                target="_blank"
              >
                Discord
              </a>
            </span>
            <span className={styles.hideAtMedium}>
              {" / "}
              <a
                className={styles.contact}
                href="http://www.thundervvolt.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                THUNDERVVOLT
              </a>
            </span>
            <span className={styles.hideAtSmall}>
              {" / "}
              <a className={styles.contact} href="mailto:contact@awkshare.com" target="_blank" rel="noreferrer">
                Contact
              </a>
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          transform: "translate3d(0,0,0)",
          zIndex: 0,
          backgroundImage: `url('${bg}')`,
          backgroundSize: "100% auto", // "contain",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          pointerEvents: "none",
          opacity: 0.25,
        }}
      />
    </>
  );
}
