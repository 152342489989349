import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FilePreviewPlayer, FilePreviewPlayerRef } from "components/FilePreview";
import { useEvent } from "hooks/useEvent";
import { File } from "shared/types";
import { PartyViewerTrackState } from "./PartyViewerTrack";
import styles from "./styles.css";

export interface PartyViewerPlayerProps {
  className?: string;
  currentTime?: number;
  cover?: string;
  file: File;
  loopIndex?: number | null;
  // muted: boolean;
  start?: number;
  state: PartyViewerTrackState;
}

export function BasePartyViewerPlayer(props: PartyViewerPlayerProps) {
  const { className, cover, file, loopIndex, start, state } = props;
  const canPlayRef = useRef(false);
  // const [canPlay, setCanPlay] = useState(false);
  const [warning, showWarning] = useState(false);
  const allowedSeek = useRef(false);
  const allowedPlay = useRef(false);
  const playerRef = useRef<FilePreviewPlayerRef>(null);

  const requestPlay = useEvent(() => {
    showWarning(false);

    allowedPlay.current = true;

    const { current: player } = playerRef;

    if (!player || start == null) {
      return;
    }

    player.play();
    player.seek((Date.now() - start) / 1000);
  });

  const onPlayDenied = useEvent(() => {
    showWarning(true);
    window.addEventListener("click", requestPlay, { once: true });
  });

  const resetState = useEvent(() => {
    if (state === "PLAYING") {
      if (canPlayRef.current) {
        requestPlay();
      }

      const event = new Event("resize");
      window.dispatchEvent(event);
      return;
    }

    showWarning(false);
    playerRef.current?.pause();
  });

  const onPlay = useEvent(() => {
    if (allowedPlay.current) {
      allowedPlay.current = false;
      return;
    }

    if (start == null || !playerRef.current) {
      return;
    }

    allowedSeek.current = true;
    playerRef.current.seek((Date.now() - start) / 1000);
  });

  const onSeeked = useEvent(() => {
    if (allowedSeek.current) {
      allowedSeek.current = false;
      return;
    }

    if (start == null || !playerRef.current) {
      return;
    }

    allowedSeek.current = true;
    playerRef.current.seek((Date.now() - start) / 1000);
  });

  useEffect(resetState, [loopIndex, resetState, state]);

  const onCanPlayThrough = useCallback(() => {
    if (!canPlayRef.current) {
      canPlayRef.current = true;
      // setCanPlay(true);
      resetState();
    }
  }, [resetState]);

  return (
    <>
      {warning && (
        <div className={styles.warning}>
          <div className={styles.title}>Your browser denied autoplay</div>
          <div className={styles.button}>Click here to allow playback</div>
        </div>
      )}
      <FilePreviewPlayer
        allowSeek={false}
        className={className}
        duration={file.duration}
        mime={file.mime}
        onCanPlayThrough={onCanPlayThrough}
        onPlay={onPlay}
        onPlayDenied={onPlayDenied}
        onSeeked={onSeeked}
        poster={cover}
        ref={playerRef}
        type={file.fileType}
        url={file.streamURL || file.url || file.downloadURL || null}
      />
      {/* {file.filetype === FileType.Audio ? (
        <>
          <audio
            className={styles.player}
            src={file.stream_url || file.downloadURL}
            ref={ref}
            preload="auto"
            onCanPlayThrough={onCanPlayThrough}
            muted={muted}
            controls
          />
        </>
      ) : (
        <video
          className={cx(styles.player, state === TrackState.Playing && styles.playing)}
          src={file.stream_url || file.downloadURL}
          ref={ref as any}
          onPlay={onPlay}
          preload="auto"
          poster={cover}
          onSeeked={onSeeked}
          onCanPlayThrough={onCanPlayThrough}
          muted={muted}
          controls
        />
      )} */}
    </>
  );
}

export const PartyViewerPlayer = memo(BasePartyViewerPlayer);
