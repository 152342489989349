import cx from "classnames";
import yna from "public/images/backgrounds/yna.jpg";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import { AuthTrigger, useAuthUser } from "components/Auth";
import { Button } from "components/Button";
import { Loader } from "components/Loader";
import { SmoothImage } from "components/Smooth";
import { useApi } from "contexts/api";
import { EventType, useEvents } from "contexts/events";
import { useEvent } from "hooks/useEvent";
import { TimelineItem } from "shared/types";
import { BoxedText } from "./BoxedText";
import { CurrentTime } from "./CurrentTime";
import {
  DefaultZoom,
  TimelineEvent as TimelineEventType,
  TimelineState,
  calculateEventX,
  getEventsWindow,
  getMarkersForDate,
  getTimeLineStyle,
  getTimelineWidth,
  parseEvent,
  sortEvents,
} from "./shared";
import styles from "./styles.css";
import { TimelineCreator } from "./TimelineCreator";
import { TimelineDate } from "./TimelineDate";
import { TimelineEvent } from "./TimelineEvent";
import { TimelineGroupEvent } from "./TimelineGroupEvent";
import { TimelineMarker } from "./TimelineMarker";

export function TimelinePage() {
  const api = useApi();
  const navigate = useNavigate();
  const params = useParams();
  const user = useAuthUser();
  const [isReady, setIsReady] = useState<boolean | null>(null);
  const [, setForceUpdate] = useState(0);
  const timelineStateRef = useRef<TimelineState | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const triggerUpdate = () => setForceUpdate((x) => x + 1);
  const [activeEvent, setActiveEvent] = useState<[TimelineEventType, HTMLDivElement, CSSProperties] | null>(null);
  const eventsElementRef = useRef<HTMLDivElement | null>(null);
  const eventElementRefs = useRef<Map<string, HTMLDivElement> | null>(null);
  const lineElementRef = useRef<HTMLDivElement | null>(null);
  const currentDateElementRef = useRef<HTMLDivElement | null>(null);
  const updateRAF = useRef<number | null>(null);

  const updateEventsWindow = useEvent(() => {
    const { current: timelineState } = timelineStateRef;

    if (!timelineState) {
      return;
    }

    const markers = getMarkersForDate(
      timelineState.date,
      timelineState.minDate,
      timelineState.maxDate,
      timelineState.zoom,
    );

    const eventsWindow = getEventsWindow(timelineState.events, timelineState.zoom, timelineState.date);

    if (
      timelineState.eventsWindow[0] !== eventsWindow[0] ||
      timelineState.eventsWindow[1] !== eventsWindow[1] ||
      timelineState.eventsWindow[2] !== eventsWindow[2] ||
      !markers.every((marker, i) => timelineState.markers[i]?.[0] === marker[0])
    ) {
      timelineState.eventsWindow = eventsWindow;
      timelineState.markers = markers;
      triggerUpdate();
    } else {
      if (!updateRAF.current) {
        updateRAF.current = requestAnimationFrame(updateUIInPlace);
      }
    }
  });

  useEffect(() => {
    if (activeEvent) {
      navigate(`/timeline/${activeEvent[0].id}`, {
        replace: true,
      });
    }
  }, [activeEvent, navigate]);

  const updateUIInPlace = () => {
    updateRAF.current = null;

    const { current: timelineState } = timelineStateRef;

    if (!timelineState) {
      return;
    }

    const { current: currentDateElement } = currentDateElementRef;
    const { current: eventElements } = eventElementRefs;

    if (currentDateElement) {
      currentDateElement.innerText = new Date(timelineState.date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }

    if (!eventElements) {
      return;
    }

    for (let i = 0; i < timelineState.markers.length; i++) {
      const eventElement = eventElements.get(`marker-${timelineState.markers[i][0]}`);

      if (eventElement) {
        const x = calculateEventX(timelineState, timelineState.markers[i][0]);
        eventElement.style.setProperty("--x", `${x}px`);
      }
    }

    let currentDate: number | null = null;

    if (timelineState.eventsWindow[0] != null && timelineState.eventsWindow[1] != null) {
      for (let i = timelineState.eventsWindow[0]; i <= timelineState.eventsWindow[1]; i++) {
        const event = timelineState.events[i];
        const eventElement = eventElements.get(event.id);

        if (!eventElement) {
          continue;
        }

        const newCurrentDate = event.date.getTime();

        if (currentDate !== newCurrentDate) {
          currentDate = newCurrentDate;
          const x = calculateEventX(timelineState, event.date.getTime());
          eventElement.style.setProperty("--x", `${x}px`);
        }
      }
    }

    const { current: lineElement } = lineElementRef;

    if (lineElement) {
      Object.assign(lineElement.style, getTimeLineStyle(timelineState));
    }
  };

  const onScroll = useCallback(() => {
    setActiveEvent(null);

    const { current: scrollElement } = scrollElementRef;
    const { current: timelineState } = timelineStateRef;

    if (!scrollElement || !timelineState) {
      return;
    }

    const percentScrolled = 1 - scrollElement.scrollLeft / (scrollElement.scrollWidth - scrollElement.offsetWidth);
    const diff = timelineState.maxDate - timelineState.minDate;

    timelineState.date = timelineState.maxDate - diff * percentScrolled;

    updateEventsWindow();
  }, [scrollElementRef, updateEventsWindow]);

  const jumpToItem = (id: string) => {
    const { current: scrollElement } = scrollElementRef;
    const { current: timelineState } = timelineStateRef;

    if (!timelineState || !scrollElement) {
      return false;
    }

    const item = timelineState.events.find((item) => item.id === id);

    if (item) {
      const totalDiff = timelineState.maxDate - timelineState.minDate;
      const diff = item.date.getTime() - timelineState.minDate;
      const p = diff / totalDiff;
      const scrollLeft = (scrollElement.scrollWidth - scrollElement.offsetWidth) * p;

      if (scrollElement.scrollLeft === scrollLeft) {
        const ev = new Event("scroll");
        scrollElement.dispatchEvent(ev);
      } else {
        scrollElement.scrollLeft = scrollLeft;
      }

      return true;
    }
  };

  useEffect(() => {
    api.timeline.get(null, null).then((baseEvents) => {
      if (!baseEvents?.length) {
        return;
      }

      const events = baseEvents.map(parseEvent);
      const containerWidth = document.body.offsetWidth;
      const date = events[events.length - 1].date.getTime() + DefaultZoom / 2;
      const pxPerMs = containerWidth / DefaultZoom;
      const minDateObj = new Date(events[0].date);

      minDateObj.setHours(0);
      minDateObj.setMinutes(0);
      minDateObj.setSeconds(0);
      minDateObj.setMilliseconds(0);
      minDateObj.setMonth(0);
      minDateObj.setDate(1);

      const minDate = minDateObj.getTime();
      const maxDate = events[events.length - 1].date.getTime();
      const eventsWindow = getEventsWindow(events, DefaultZoom, date);

      timelineStateRef.current = {
        width: getTimelineWidth(events, pxPerMs, DefaultZoom, minDate),
        markers: getMarkersForDate(date, minDate, maxDate, DefaultZoom),
        zoom: DefaultZoom,
        viewingEvent: new Set(),
        containerWidth,
        date,
        events,
        eventsWindow,
        maxDate,
        minDate,
        pxPerMs,
      };

      setIsReady(true);
    });
  }, [api.timeline]);

  useEffect(() => {
    if (params.id && timelineStateRef.current) {
      jumpToItem(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    const onResize = () => {
      const { current: timelineState } = timelineStateRef;

      if (!timelineState) {
        return;
      }

      timelineState.containerWidth = document.body.offsetWidth;
      timelineState.pxPerMs = timelineState.containerWidth / timelineState.zoom;
      timelineState.width = getTimelineWidth(
        timelineState.events,
        timelineState.pxPerMs,
        timelineState.zoom,
        timelineState.minDate,
      );

      onScroll();
    };

    window.addEventListener("resize", onResize);

    const { current: scrollElement } = scrollElementRef;

    if (!scrollElement) {
      return;
    }

    if (params.id) {
      if (!jumpToItem(params.id)) {
        scrollElement.scrollLeft = scrollElement.scrollWidth;
      }
    } else {
      scrollElement.scrollLeft = scrollElement.scrollWidth;
    }

    return () => {
      if (updateRAF.current) {
        cancelAnimationFrame(updateRAF.current);
        updateRAF.current = null;
      }

      window.removeEventListener("resize", onResize);
    };
  }, [isReady, onScroll, params.id]);

  const toggleCreator = useCallback(() => {
    setIsCreating((creating) => !creating);
  }, []);

  const onCancel = useCallback(() => {
    setIsCreating(false);
  }, []);

  const onCreatedEvent = useCallback(
    (item: TimelineItem) => {
      const { current: timelineState } = timelineStateRef;

      if (!timelineState) {
        return;
      }

      const existing = timelineState.events.some((event) => event.id === item.id);

      if (!existing) {
        const parsed = parseEvent(item);

        timelineState.events.push(parsed);
        timelineState.events.sort(sortEvents);
        timelineState.maxDate = timelineState.events[timelineState.events.length - 1].date.getTime();
        timelineState.minDate = timelineState.events[0].date.getTime();
        timelineState.width = getTimelineWidth(
          timelineState.events,
          timelineState.pxPerMs,
          timelineState.zoom,
          timelineState.minDate,
        );

        updateEventsWindow();
      }

      setIsCreating(false);
    },
    [timelineStateRef, updateEventsWindow],
  );

  const onChangeEvent = useCallback(
    (event: TimelineItem) => {
      const { current: timelineState } = timelineStateRef;

      if (!timelineState) {
        return;
      }

      const idx = timelineState.events.findIndex((item) => item.id === event.id);

      if (idx !== -1) {
        timelineState.events[idx] = parseEvent(event);
        timelineState.events.sort(sortEvents);
        timelineState.maxDate = timelineState.events[timelineState.events.length - 1].date.getTime();
        timelineState.minDate = timelineState.events[0].date.getTime();
        timelineState.width = getTimelineWidth(
          timelineState.events,
          timelineState.pxPerMs,
          timelineState.zoom,
          timelineState.minDate,
        );

        updateEventsWindow();
      }
    },
    [updateEventsWindow],
  );

  const onEvent = useCallback(
    (type: EventType, _data: TimelineItem) => {
      if (!timelineStateRef.current) {
        return;
      }

      switch (type) {
        case "TIMELINE_ADD_EVENT": {
          const data = _data as TimelineItem;
          const existing = timelineStateRef.current.events.some((event) => event.id === data.id);

          if (!existing) {
            const parsed = parseEvent(data);
            timelineStateRef.current.events.push(parsed);
            timelineStateRef.current.events.sort(sortEvents);
            timelineStateRef.current.maxDate =
              timelineStateRef.current.events[timelineStateRef.current.events.length - 1].date.getTime();
            timelineStateRef.current.minDate = timelineStateRef.current.events[0].date.getTime();
            timelineStateRef.current.width = getTimelineWidth(
              timelineStateRef.current.events,
              timelineStateRef.current.pxPerMs,
              timelineStateRef.current.zoom,
              timelineStateRef.current.minDate,
            );
            updateEventsWindow();
          }
          break;
        }
        case "TIMELINE_UPDATE_EVENT": {
          const data = _data as TimelineItem;
          onChangeEvent(data);
          break;
        }
      }
    },
    [onChangeEvent, updateEventsWindow],
  );

  useEvents("TIMELINE", "state", onEvent);

  // const increaseZoom = useEvent((delta: number) => {
  //   timelineStateRef.current.zoom += delta;
  //   timelineStateRef.current.pxPerMs = timelineStateRef.current.containerWidth / timelineStateRef.current.zoom;
  //   timelineStateRef.current.width = getTimelineWidth(
  //     timelineStateRef.current.events,
  //     timelineStateRef.current.pxPerMs,
  //     timelineStateRef.current.zoom,
  //     timelineStateRef.current.minDate,
  //   );
  //   updateEventsWindow();
  // });

  // const onClickZoomIn = useCallback(() => {
  //   increaseZoom(31536000 * 1000 * -1);
  // }, [increaseZoom]);

  // const onClickZoomOut = useCallback(() => {
  //   increaseZoom(31536000 * 1000);
  // }, [increaseZoom]);

  const onChangeEventIsOpen = useCallback((id: string, isOpen: boolean) => {
    const { current: timelineState } = timelineStateRef;

    if (!timelineState) {
      return;
    }

    if (isOpen) {
      timelineState.viewingEvent.add(id);
    } else {
      timelineState.viewingEvent.delete(id);
    }

    const { current: eventsElement } = eventsElementRef;

    if (eventsElement) {
      if (timelineState.viewingEvent.size) {
        eventsElement.classList.add(styles.isViewingEvent);
      } else {
        eventsElement.classList.remove(styles.isViewingEvent);
      }
    }
  }, []);

  const { current: timelineState } = timelineStateRef;

  if (!isReady || !timelineState) {
    return (
      <>
        <Helmet>
          <title>Timeline</title>
        </Helmet>
        <Loader />
      </>
    );
  }

  const events = [];

  const refForEvent = (id: string) => (ref: HTMLDivElement) => {
    if (ref) {
      if (!eventElementRefs.current) {
        eventElementRefs.current = new Map();
      }

      eventElementRefs.current.set(id, ref);
    } else if (eventElementRefs.current) {
      eventElementRefs.current.delete(id);
    }
  };

  timelineState.markers.forEach(([date, label]) => {
    const key = `marker-${date}`;

    events.push(
      <TimelineMarker ref={refForEvent(key)} key={key} x={calculateEventX(timelineState, date)}>
        {label}
      </TimelineMarker>,
    );
  });

  let currentDate: number | null = null;
  let groupEvents: TimelineEventType[] | null = null;

  const { eventsWindow } = timelineState;

  if (eventsWindow[0] != null && eventsWindow[1] != null) {
    for (let i = eventsWindow[0]; i <= eventsWindow[1]; i++) {
      const event = timelineState.events[i];
      const newCurrentDate = event.date.getTime();

      if (currentDate === newCurrentDate) {
        if (events[events.length - 1].type === TimelineEvent) {
          groupEvents = [events[events.length - 1].props.event];

          events[events.length - 1] = (
            <TimelineGroupEvent
              events={groupEvents}
              key={events[events.length - 1].props.event.id}
              isEditable={!!user}
              ref={refForEvent(events[events.length - 1].props.event.id)}
              onChange={onChangeEvent}
              onChangeIsOpen={onChangeEventIsOpen}
              x={calculateEventX(timelineState, currentDate)}
              scrollElementRef={scrollElementRef}
            />
          );
        }

        groupEvents!.push(event);
      } else {
        groupEvents = null;
        currentDate = newCurrentDate;

        events.push(
          <TimelineEvent
            className={styles.event}
            ref={refForEvent(event.id)}
            event={event}
            key={event.id}
            isEditable={!!user}
            // isActive={timelineStateRef.current.eventsWindow[2] === i}
            onChange={onChangeEvent}
            onChangeIsOpen={onChangeEventIsOpen}
            scrollElementRef={scrollElementRef}
            x={calculateEventX(timelineState, currentDate)}
          />,
        );
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Timeline</title>
      </Helmet>
      <div className={styles.component}>
        {isCreating && (
          <TimelineCreator timelineStateRef={timelineStateRef} onCancel={onCancel} onCreated={onCreatedEvent} />
        )}
        <div className={styles.header}>
          <div className={styles.left}>{user ? null : <AuthTrigger>🪐</AuthTrigger>}</div>
          <div className={styles.center}>
            <BoxedText>THE ANDREW W.K. TIMELINE</BoxedText>
          </div>
          <div className={styles.actions}>
            {user && <Button onClick={toggleCreator}>+ Add Event to Timeline</Button>}
          </div>
        </div>
        <div
          className={cx(styles.events, timelineState.viewingEvent.size && styles.isViewingEvent)}
          ref={eventsElementRef}
        >
          {events}
        </div>
        <div className={styles.line} ref={lineElementRef} style={getTimeLineStyle(timelineState)} />
        <div className={styles.indicator} />
        <BoxedText className={styles.currentDate}>
          <CurrentTime ref={currentDateElementRef} scrollElementRef={scrollElementRef}>
            <TimelineDate date={new Date(timelineState.date)} />
          </CurrentTime>
        </BoxedText>
        <div className={styles.scrollOuter} ref={scrollElementRef} onScroll={onScroll}>
          <div className={styles.scroll} style={{ width: timelineState.width }} />
        </div>
        <div className={styles.hero}>
          <SmoothImage url={yna} offset="25%" />
        </div>
      </div>
    </>
  );
}
