import { createContext, useContext, useEffect, useState } from "react";
import { Api } from "shared/api";
import { Comment, EntityType, JSONValue } from "shared/types";
import { EventType, Events, useEvents } from "./events";

export type Comments = ReturnType<typeof createComments>;
export const CommentsContext = createContext<Comments | null>(null);

type Listener = (comments: Comment[]) => void;
type Listeners = Listener[] & {
  unsubscribeEvents?: (() => void) | null;
};

export const commentKey = (entityType: EntityType, entityID: string) => `${entityType}:${entityID}`;

export function createComments({ api, events }: { api: Api; events: Events }) {
  const inflight = new Map();
  const cache = new Map<string, Comment[]>();
  const listeners = new Map<string, Listeners>();

  const listFromCache = (entityType: EntityType, entityID: string) => cache.get(commentKey(entityType, entityID));

  function triggerUpdate(entityType: EntityType, entityID: string) {
    const key = commentKey(entityType, entityID);
    const fileListeners = listeners.get(key);
    const comments = cache.get(key);

    if (fileListeners?.length && comments) {
      fileListeners.forEach((listener) => listener(comments));
    }
  }

  function setCache(entityType: EntityType, entityID: string, comments: Comment[]) {
    const key = commentKey(entityType, entityID);
    cache.set(key, comments);
    triggerUpdate(entityType, entityID);
  }

  function list(entityType: EntityType, entityID: string): Promise<Comment[]> {
    const key = commentKey(entityType, entityID);

    let promise = inflight.get(key);

    if (!promise) {
      promise = entityType === "LEAF" ? api.leaf.listComments(entityID) : api.party.listComments(entityID);

      inflight.set(key, promise);

      promise.then(
        (res: Comment[]) => {
          setCache(entityType, entityID, res);
          inflight.delete(key);
          return res;
        },
        () => inflight.delete(key),
      );
    }

    return inflight.get(key);
  }

  async function create(
    entityType: EntityType,
    entityID: string,
    name: string,
    comment: string,
    tripCode: string,
  ): Promise<Comment | null> {
    const createdComment = await (entityType === "LEAF" ? api.leaf.postComment : api.party.postComment)(
      entityID,
      name,
      comment,
      tripCode,
    );

    if (!createdComment) {
      return null;
    }

    const comments = listFromCache(entityType, entityID);

    if (comments) {
      const existing = comments.find(({ id }) => id === createdComment.id);

      if (!existing) {
        setCache(entityType, entityID, [createdComment, ...comments]);
      }
    }

    return createdComment;
  }

  const createEventsListener = (entityType: EntityType, entityID: string) => (type: EventType, data: JSONValue) => {
    if (type === "COMMENT") {
      const comments = listFromCache(entityType, entityID);

      if (comments) {
        const newComment = data as Comment;
        const existing = comments.find(({ id }) => id === newComment.id);

        if (!existing) {
          setCache(entityType, entityID, [newComment, ...comments]);
        }
      }
    }
  };

  function subscribe(entityType: EntityType, entityID: string, listener: Listener) {
    const key = commentKey(entityType, entityID);

    let active = true;
    let fileListeners: Listeners | null = listeners.get(key) || null;

    if (!fileListeners) {
      fileListeners = [] as Listeners;
      fileListeners.unsubscribeEvents = events.subscribe(
        entityType,
        entityID,
        createEventsListener(entityType, entityID),
      );
      listeners.set(key, fileListeners);
    }

    fileListeners.push(listener);
    fileListeners = null;

    return () => {
      if (active) {
        active = false;
        const fileListeners = listeners.get(key);

        if (!fileListeners) {
          return;
        }

        fileListeners.splice(fileListeners.indexOf(listener), 1);

        if (fileListeners.length === 0) {
          fileListeners.unsubscribeEvents?.();
          fileListeners.unsubscribeEvents = null;
          listeners.delete(key);
        }
      }
    };
  }

  return {
    create,
    list,
    listFromCache,
    subscribe,
  };
}

export function useComments() {
  return useContext(CommentsContext);
}

export function useCommentList(entityType: EntityType, entityID: string): [null | Comment[], null | number] {
  const context = useComments();
  const [comments, setComments] = useState<Comment[] | null>(null);
  const viewers = useEvents(entityType, entityID);

  useEffect(() => {
    setComments(context?.listFromCache(entityType, entityID) || null);

    const listener = (comments: Comment[]) => {
      setComments(comments);
    };

    const unsubscribe = context?.subscribe(entityType, entityID, listener);

    context?.list(entityType, entityID);

    return unsubscribe;
  }, [context, entityType, entityID]);

  return [comments, viewers];
}
