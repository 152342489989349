import cx from "classnames";
import audioIcon from "public/images/files/audio.png";
import fileIcon from "public/images/files/file.png";
import imageIcon from "public/images/files/image.png";
import pdfIcon from "public/images/files/pdf.png";
import rarIcon from "public/images/files/rar.png";
import videoIcon from "public/images/files/video.png";
import zipIcon from "public/images/files/zip.png";
import { FileType } from "shared/types";
import styles from "./styles.css";

function getFileTypeImg(props: Pick<FileIconProps, "file" | "type">) {
  const { file, type = file?.fileType || null } = props;

  if (type === "VIDEO") {
    return videoIcon;
  }

  if (type === "AUDIO") {
    return audioIcon;
  }

  if (type === "IMAGE") {
    return imageIcon;
  }

  if (!file) {
    return fileIcon;
  }

  const fileNameType = file.name.split(".").pop();

  switch (true) {
    case fileNameType && /mp3|wav|flac|aac|aiff/.test(fileNameType):
      return audioIcon;
    case fileNameType === "rar":
      return rarIcon;
    case fileNameType === "zip":
      return zipIcon;
    case fileNameType === "pdf":
      return pdfIcon;
    default:
      return fileIcon;
  }
}

interface FileIconProps {
  className?: string;
  file?: {
    name: string;
    fileType: string | null;
  };
  type?: FileType | null;
  size?: number;
}

export function FileIcon(props: FileIconProps) {
  const { className, file, type, size = 74 } = props;

  return (
    <div className={cx(styles.component, className)}>
      <img className={styles.icon} src={file ? getFileTypeImg({ file }) : getFileTypeImg({ type })} height={size} />
    </div>
  );
}
