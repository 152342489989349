import { RequestStore, RequestStoreKey } from "contexts/requestStore";
import { Leaf } from "./types";

export enum Sort {
  Name = 1,
  Date = 2,
  Views = 3,
}

export const getSort = (store?: RequestStore | null) =>
  store?.get(RequestStoreKey.LeafListSortType, Sort.Date) ?? Sort.Date;

export const getAscending = (store?: RequestStore | null) =>
  store?.get<boolean>(RequestStoreKey.LeafListSortAscending, false) ?? false;

export const splitFilename = (fileName: string): [string, string | null] => {
  const split = fileName.split(".");

  if (split.length === 1) {
    return [fileName, null];
  }

  const extension = split.pop();

  if (extension?.includes(" ")) {
    return [fileName, null];
  }

  return [split.join("."), extension || null];
};

export function sortLeafsInPlace(leafs: Leaf[], sort: Sort, ascending: boolean) {
  leafs.sort((a, b) => {
    switch (sort) {
      case Sort.Name: {
        const [aPrefix] = splitFilename(a.name);
        const [bPrefix] = splitFilename(b.name);
        const aNum = Number(aPrefix);
        const bNum = Number(bPrefix);

        if (!isNaN(aNum) && !isNaN(bNum)) {
          if (ascending) {
            return aNum - bNum;
          }

          return bNum - aNum;
        }

        if (ascending) {
          return a.name.split(".")[0].localeCompare(b.name.split(".")[0]);
        }

        return b.name.split(".")[0].localeCompare(a.name.split(".")[0]);
      }
      case Sort.Date: {
        if (ascending) {
          return Date.parse(a.createdAt) - Date.parse(b.createdAt);
        }

        return Date.parse(b.createdAt) - Date.parse(a.createdAt);
      }
      case Sort.Views: {
        if (ascending) {
          return a.viewsCount - b.viewsCount;
        }

        return b.viewsCount - a.viewsCount;
      }
      default: {
        return 0;
      }
    }
  });

  return leafs;
}
