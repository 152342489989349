import {
  PropsWithChildren,
  MouseEvent as ReactMouseEvent,
  RefObject,
  WheelEvent,
  forwardRef,
  useCallback,
} from "react";

export type CurrentTimeProps = PropsWithChildren<{
  scrollElementRef: RefObject<HTMLDivElement | null>;
}>;

export const CurrentTime = forwardRef<HTMLDivElement, CurrentTimeProps>(function CurrentTime(props, ref) {
  const { children, scrollElementRef } = props;
  const onMouseDown = useCallback(
    (event: ReactMouseEvent<HTMLDivElement>) => {
      let lastX = event.clientX;

      function handleMouseUp() {
        document.body.removeEventListener("mouseup", handleMouseUp);
        document.body.removeEventListener("mousemove", handleMouseMove);
      }

      function handleMouseMove(event: MouseEvent) {
        const { current: scrollElement } = scrollElementRef;

        if (!scrollElement) {
          return;
        }

        const delta = event.clientX - lastX;
        scrollElement.scrollLeft += delta * 50;
        lastX = event.clientX;
      }

      document.body.addEventListener("mouseup", handleMouseUp);
      document.body.addEventListener("mousemove", handleMouseMove);
    },
    [scrollElementRef],
  );

  const onWheel = useCallback(
    (scrollEvent: WheelEvent) => {
      const { current: scrollElement } = scrollElementRef;

      if (scrollElement && scrollEvent.deltaMode === 0) {
        if (scrollEvent.deltaY === 0) {
          scrollElement.scrollLeft += scrollEvent.deltaX;
        }
      }
    },
    [scrollElementRef],
  );

  return (
    <div ref={ref} onMouseDown={onMouseDown} onWheel={onWheel}>
      {children}
    </div>
  );
});
