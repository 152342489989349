import useSize from "@react-hook/size";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { IconSearch } from "components/Icons/IconSearch";
import { Loader } from "components/Loader";
import { useApi } from "contexts/api";
import { useEvent } from "hooks/useEvent";
import { SearchResult } from "shared/types";
import { LeafResult } from "./LeafResult";
import styles from "./styles.css";

function isLeafResult(result: SearchResult): result is SearchResult<"LEAF"> {
  return result.type === "LEAF";
}

export function Search() {
  const api = useApi();
  const [results, setResults] = useState<SearchResult[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchType, setSearchType] = useState<"LEAFS" | "OCR">("LEAFS");
  const activeOpRef = useRef<number>(0);
  const [query, setQuery] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const setDebouncedValueRef = useRef<((value: string) => void) | null>(null);
  const innerElementRef = useRef<HTMLDivElement | null>(null);
  const floatingElementRef = useRef<HTMLDivElement | null>(null);
  const inputElementRef = useRef<HTMLInputElement | null>(null);
  const [width] = useSize(innerElementRef);
  const { search } = api || {};

  function getDebouncedSetter() {
    if (!setDebouncedValueRef.current) {
      setDebouncedValueRef.current = debounce(setDebouncedValue, 500, {
        leading: true,
        trailing: true,
      });
    }

    return setDebouncedValueRef.current;
  }

  const handleChange = useEvent((event) => {
    setQuery(event.target.value);
    getDebouncedSetter()(event.target.value.trim());
  });

  const blurRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleFocus = useEvent(() => {
    if (blurRef.current) {
      clearTimeout(blurRef.current);
      blurRef.current = null;
    }

    setIsFocused(true);
  });

  const handleBlur = useEvent(() => {
    blurRef.current = setTimeout(() => {
      setIsFocused(false);
    }, 150);
  });

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    function handleClick(event: MouseEvent) {
      if (
        event.target === innerElementRef.current ||
        event.target === floatingElementRef.current ||
        innerElementRef.current?.contains(event.target as Node) ||
        floatingElementRef.current?.contains(event.target as Node)
      ) {
        if (event.target !== inputElementRef.current) {
          if (blurRef.current) {
            clearTimeout(blurRef.current);
            blurRef.current = null;
          }
        }

        return;
      }

      setIsFocused(false);
    }

    inputElementRef.current?.focus();

    document.body.addEventListener("click", handleClick, {
      capture: true,
    });

    return () => {
      document.body.removeEventListener("click", handleClick, {
        capture: true,
      });
    };
  }, [isFocused, setIsFocused]);

  useEffect(() => {
    setResults(null);
  }, [searchType]);

  useEffect(() => {
    if (!debouncedValue) {
      setResults(null);
      return;
    }

    if (!search) {
      return;
    }

    const opRef = ++activeOpRef.current;

    setIsLoading(true);

    search
      .query({ query: debouncedValue, type: searchType })
      .then((res) => {
        if (!res) {
          throw new Error("No results");
        }

        const { results } = res;

        if (opRef !== activeOpRef.current) {
          return;
        }

        setResults(results);
        setIsLoading(false);
      })
      .catch((err) => {
        if (opRef !== activeOpRef.current) {
          return;
        }

        console.error(err);
        setIsLoading(false);
      });

    return () => {
      // abort eventually
    };
  }, [searchType, debouncedValue, search]);

  const isActive = isFocused; // || results != null || isLoading;

  return (
    <div className={styles.component}>
      <div className={styles.inner} ref={innerElementRef} onClick={handleFocus}>
        <div className={styles.label}>{query || "Type here to search..."}</div>
        <div className={styles.prefix}>
          <IconSearch />
        </div>
      </div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            key="floating"
            className={styles.floating}
            ref={floatingElementRef}
            style={{ width: Math.max(420, width) }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <input
              className={styles.input}
              placeholder="Type here to search..."
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={inputElementRef}
              type="text"
              value={query}
              onChange={handleChange}
            />
            <div className={styles.searchType}>
              <div className={styles.searchTypeLabel}>Searching:</div>
              <div className={styles.searchTypeButtons}>
                <button
                  className={cx(styles.searchTypeButton, searchType === "LEAFS" && styles.isSelected)}
                  onClick={() => setSearchType("LEAFS")}
                >
                  Files
                </button>
                <button
                  className={cx(styles.searchTypeButton, searchType === "OCR" && styles.isSelected)}
                  onClick={() => setSearchType("OCR")}
                >
                  OCR
                </button>
              </div>
            </div>
            {results?.length ? (
              <div
                className={cx(styles.results, {
                  [styles.hasCards]: searchType === "OCR",
                  [styles.hasList]: searchType === "LEAFS",
                })}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                {results.map((result) => (
                  <div key={result.id} className={styles.result}>
                    {isLeafResult(result) && (
                      <LeafResult key={result.id} mode={searchType === "OCR" ? "card" : "list"} leaf={result.entity} />
                    )}
                  </div>
                ))}
              </div>
            ) : isLoading ? (
              <div className={styles.loading}>
                <Loader size={36} />
              </div>
            ) : (
              isFocused &&
              results != null && (
                <div className={styles.results}>
                  <div className={styles.empty}>No results found.</div>
                </div>
              )
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
